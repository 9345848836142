const COLORS = {
  PRIMARY_BLUE: '#2482BE',
  SECONDARY_GREEN: '#7EA356',
  MAIN_DARK: '#111827',
  DARK_GREY: '#374151',
  CYAN: '#66C2FE',
  ACCENT_CYAN: '#69C9DD',
  X_LIGHT_CYAN: '#F4FBFC',
  DARK_BLUE: '#003E78',
  X_DARK_BLUE: '#0061A7',
  LIGHT_GREEN: '#A9CB85',
  LIGHT_GRAY: '#9CA3AF',
  X_LIGHT_GRAY: '#D1D5DB',
  X_LIGHT_GREEN: '#DBE9CC',
  XX_LIGHT_GREEN: '#E5E7EB',
  LINEAR_GRADIENT: 'linear-gradient(270deg, #2482BE 0, #69C9DD 108.75%)',
  LINEAR_GRADIENT_DARK: 'linear-gradient(270deg, #0061A7 0%, #22A2FF 108.75%);',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  TRANSPARENT: 'transparent',
  XLIGHT_GRAY: '#D1D5DB',
  ORANGE: '#EA9515',
  RED: '#EF4444',
  PLACEHOLDER: '#000000DE',
  GOLD: '#FFD700',
  GREEN: '#85DE22',
};

export default COLORS;
