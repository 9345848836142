import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { authSelector } from '../redux/features/auth/auth.selectors';
import { locations } from './locations';

type PublicRouteProps = {
  children: JSX.Element;
};

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { accessToken, isLoggedIn } = useSelector(authSelector);
  const location = useLocation();
  const isAllow = accessToken !== '' && isLoggedIn;

  return !isAllow ? children : <Navigate to={locations.home()} state={location.state} />;
};

export default PublicRoute;
