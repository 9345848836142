import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import LogoutIcon from '@mui/icons-material/Logout';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';
import { useTypedDispatch } from '../../../redux/store';
import {
  PortfolioFileType,
  RecommendedPortfolio,
  SubscribedPortfolio,
} from '../../../store_deprecated/types/portfolioTypes';
import { fetchUploadedDocumentURL } from '../../../store_deprecated/actions/docsActions';
import { singleDownload } from '../../../helpers/request';

interface DisclaimerDialogProps {
  open: boolean;
  onClose: () => void;
  portfolio: SubscribedPortfolio | RecommendedPortfolio;
}

const mockItems: Record<string, string[]> = {
  en: [
    'This Index reflects Sharia investment principles. Constituents of the index fund may be subject to changes and revision by the index administrator at any time.',
    'Please remember that past performance may not be indicative of future results. Different types of investments involve varying degrees of risk. There can be no assurance that the future performance of any specific investment, investment strategy, or product made directly or indirectly in this factsheet will be profitable or equal any corresponding indicated historical performance level(s).',
    'All rights reserved to Madkhol Capital ©2022, Commercial Registration No.: 1010741887, Capital Market Authority License No.: 7001-465314.',
  ],
  ar: [
    'هذا المؤشر الاسترشادي يتبع ضوابط الشريعة الاسلامية. قد تخضع الأوراق المالية المكونة للمؤشر للتغييرات والمراجعة من قبل مسؤول عن ادارة المؤشر الاسترشادي.',
    'يرجى تذكر أن الأداء السابق قد لا يكون مؤشرا على النتائج المستقبلية. تتضمن الأنواع المختلفة من الاستثمارات درجات متفاوتة من المخاطر ، ولا يمكن أن يكون هناك ضمان بأن الأداء المستقبلي لأي استثمار محدد أو استراتيجية استثمار أو منتج تمت الإشارة إليه بشكل مباشر أو غير مباشر في ورقة الحقائق هذه أن يكون مربحًا ، أو يساوي أي مستوى أداء تاريخي مشار إليه.',
    'جميع الحقوق محفوظة لشركة مدخول المالية ©2022، سجل تجاري رقم: 1010741887، ترخيص هيئة السوق المالية رقم:465314-7001',
  ],
};

export default function DisclaimerDialog(props: DisclaimerDialogProps) {
  const { portfolio, open, onClose } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const matchMdAndAboveView = useMediaQuery('(min-width:600px)');

  const downloadDisclaimer = async () => {
    setDownloadLoading(true);
    try {
      const disclosureFile = portfolio?.files?.find(
        (file) => file.portfolioFileType?.name === PortfolioFileType.DISCLOSURE,
      );

      if (!disclosureFile) {
        throw new Error('Disclosure file is not found');
      }

      const result: string = (await dispatch(
        fetchUploadedDocumentURL({
          fileId: disclosureFile.fileId,
          access: 'private',
        }),
      )) as any;

      if (result) {
        await singleDownload(result, {
          rename: () => 'shariah-compliant-certificate',
        });
      }
    } catch (e: any) {
      console.error('Download Attachments error', e.message, e.stack);
    }
    setDownloadLoading(false);
  };

  const verifyCertificate = () => {
    window.open('https://shariyah.net/verify-your-certificate', '_blank');
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="lg">
      <Box
        sx={{
          padding: '24px',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '5px !important',
            backgroundColor: 'transparent !important',
            borderRadius: '4px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111827 !important',
            borderRadius: '4px !important',
          },
        }}
      >
        <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px">
          <Grid item textAlign="right" alignSelf="end">
            <IconButton onClick={() => onClose()}>
              <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
            </IconButton>
          </Grid>
          <Grid
            item
            sx={{
              paddingTop: { xs: '24px !important', md: '48px !important' },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '24px', md: '44px' },
                lineHeight: { xs: '32px', md: '48px' },
                color: COLORS.MAIN_DARK,
              }}
            >
              {t('disclaimerDialog.title')}
            </Typography>
          </Grid>
          <Grid item textAlign="center" display="flex" flexDirection="column">
            {mockItems[i18n.language].map((item: string, index: number) => (
              <Typography
                key={`disclaimer-item-${index}`}
                variant="bodyLarge"
                sx={{
                  mt: index === 0 ? 0 : 8,
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '28px',
                  color: COLORS.DARK_GREY,
                }}
              >
                {item}
              </Typography>
            ))}

            <img src="/images/shariah-logo.png" alt="books" width="64px" style={{ margin: '16px auto 0' }} />
            <img
              src="/images/verify-cert.png"
              alt="verify certificate"
              width="128px"
              style={{ margin: '16px auto 0', cursor: 'pointer' }}
              onClick={verifyCertificate}
            />
            <Typography
              variant="bodyLarge"
              sx={{
                mt: 4,
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.DARK_GREY,
              }}
            >
              {`${t('disclaimerDialog.verifyCert')} MFTC-2411-01-01-12-22`}
            </Typography>
          </Grid>
          <Grid item container rowSpacing="24px">
            <Box sx={{ width: '100%', display: 'grid' }}>
              <Grid item width="100%" sx={{ order: matchMdAndAboveView ? 0 : 1 }}>
                <MButton
                  variant="contained"
                  onClick={() => onClose()}
                  fullWidth
                  buttonProps={{
                    sx: {
                      width: { xs: '100%', md: '100%' },
                      height: { xs: '50px', md: 60 },
                      borderRadius: '80px',
                      boxShadow: 'none',
                    },
                  }}
                >
                  {t('back')}
                </MButton>
              </Grid>
              <Grid
                item
                width="100%"
                sx={{
                  order: matchMdAndAboveView ? 1 : 0,
                  mt: { xs: 'unset', md: 4 },
                  mb: { xs: 4, md: 'unset' },
                }}
              >
                <MButton
                  variant="outlined"
                  onClick={downloadDisclaimer}
                  isLoading={isDownloadLoading}
                  fullWidth
                  buttonProps={{
                    sx: {
                      width: { xs: '100%', md: '100%' },
                      height: { xs: '50px', md: 60 },
                      borderRadius: '80px',
                      boxShadow: 'none',
                      border: {
                        xs: `solid 1.5px ${COLORS.PRIMARY_BLUE}`,
                        md: `solid 2px ${COLORS.PRIMARY_BLUE}`,
                      },
                    },
                  }}
                >
                  <LogoutIcon sx={{ transform: 'rotate(90deg)', mr: '8px' }} />
                  {t(matchMdAndAboveView ? 'disclaimerDialog.download' : 'download')}
                </MButton>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
