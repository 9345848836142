import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import COLORS from '../../../constants/colors';
import { useFindProfileDataQuery, useSendProfileUpdateOTPMutation } from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useTypedDispatch } from '../../../redux/store';
import { getProfileDataFormSchema } from '../../../forms/validationSchema/formSchema';
import { profileDataFormFields } from '../../../forms/fields/profileData';
import { MTextField } from '../../../shared/MTextField';
import { MobileField } from '../../../shared/MobileField';
import { UpdateProfileDataPayload } from '../../../store_deprecated/types/authTypes';
import { updateProfileData } from '../../../store_deprecated/actions/authActions';

interface ProfileDataSectionProps {
  editMode: boolean;
}

export interface ProfileDataSectionRef {
  submit: () => Promise<any>;
  isValid: () => boolean;
}

const ProfileDataSection = React.forwardRef<ProfileDataSectionRef, ProfileDataSectionProps>((props, ref) => {
  const { t, i18n } = useTranslation();
  const { i18n: i18nApiError } = useTranslation('apiError');
  const isEnglish = i18n.language === 'en';
  const dispatch = useTypedDispatch();
  const { user } = useSelector(authSelector);
  const { data, isLoading } = useFindProfileDataQuery();
  const [sendProfileUpdateOTP] = useSendProfileUpdateOTPMutation();
  const [phoneSent, setPhoneSent] = useState('');
  const [emailSent, setEmailSent] = useState('');
  const [phoneOtpLoading, setPhoneOtpLoading] = useState(false);
  const [emailOtpLoading, setEmailOtpLoading] = useState(false);
  const [formSchema, setFormSchema] = useState(getProfileDataFormSchema({ emailChanged: false, phoneChanged: false }));
  const [customErrors, setCustomErrors] = useState<Record<string, string>>({});
  const [initialValues, setInitialValues] = useState({
    [profileDataFormFields.fullName]: user?.fullName || '',
    [profileDataFormFields.email]: user?.email,
    [profileDataFormFields.emailOtp]: '',
    [profileDataFormFields.phone]: user?.mobile,
    [profileDataFormFields.phoneOtp]: '',
    [profileDataFormFields.birthDate]: data?.birthDate
      ? moment(data.birthDate).locale(i18n.language).format('DD-MMM-YYYY')
      : '',
    // [profileDataFormFields.address]: data?.address || "",
    [profileDataFormFields.employerAddress]: data?.employerAddress || '',
    [profileDataFormFields.companyName]: data?.companyName || '',
    [profileDataFormFields.nationalId]: data?.nationalId || '',
  });

  const { submitForm, values, touched, errors, isValid, getFieldProps, isSubmitting, resetForm, setTouched } =
    useFormik({
      initialValues,
      initialTouched: {
        [profileDataFormFields.phone]: true,
        [profileDataFormFields.phoneOtp]: true,
        [profileDataFormFields.email]: true,
        [profileDataFormFields.emailOtp]: true,
        // [profileDataFormFields.address]: true,
        [profileDataFormFields.employerAddress]: true,
        [profileDataFormFields.companyName]: true,
      },
      validationSchema: formSchema,
      onSubmit: async (formValues) => {
        const castValues = formSchema.cast(formValues);

        const payload: UpdateProfileDataPayload = {
          email: castValues.email,
          phone: castValues.phone,
          employerAddress: castValues.employerAddress,
          companyName: castValues.companyName,
          emailOtp: castValues.emailOtp || '',
          phoneOtp: castValues.phoneOtp || '',
        };

        const res = (await dispatch(updateProfileData(payload))) as any;

        if (typeof res === 'string') {
          if (res === 'INVALID_EMAIL_OTP_CODE') {
            setCustomErrors((prev) => ({ ...prev, emailOtp: ' ' }));
          }
          if (res === 'INVALID_MOBILE_OTP_CODE') {
            setCustomErrors((prev) => ({ ...prev, phoneOtp: ' ' }));
          }
        }

        return res;
      },
    });

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await setTouched(Object.keys(values).reduce((res, key) => ({ ...res, [key]: true }), {}));

      return submitForm();
    },
    isValid: () => isValid,
  }));

  const emailChanged = values[profileDataFormFields.email] !== user?.email;
  const phoneChanged = values[profileDataFormFields.phone] !== user?.mobile;

  const items = [
    { title: 'userProfile.fullName', value: user?.fullName },
    // { title: "userProfile.address", value: data?.address || "" },
    { title: 'userProfile.mobileNumber', value: user?.mobile },
    {
      title: 'userProfile.employerAddress',
      value: data?.employerAddress || '',
    },
    { title: 'userProfile.email', value: user?.email },
    { title: 'userProfile.organization', value: data?.companyName || '' },
    {
      title: 'userProfile.birthDate',
      value: data?.birthDate ? moment(data.birthDate).locale(i18n.language).format('DD-MMM-YYYY') : '',
    },
    { title: 'userProfile.nationalId', value: data?.nationalId || '' },
  ];

  useEffect(() => {
    setFormSchema(getProfileDataFormSchema({ emailChanged, phoneChanged }));
  }, [emailChanged, phoneChanged]);

  useEffect(() => {
    if (!props.editMode) {
      setPhoneSent('');
      setEmailSent('');
      setPhoneOtpLoading(false);
      setEmailOtpLoading(false);
      setFormSchema(getProfileDataFormSchema({ emailChanged: false, phoneChanged: false }));
      setCustomErrors({});
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editMode]);

  useEffect(() => {
    const newValues = {
      [profileDataFormFields.fullName]: user?.fullName || '',
      [profileDataFormFields.email]: user?.email || '',
      [profileDataFormFields.emailOtp]: '',
      [profileDataFormFields.phone]: user?.mobile || '',
      [profileDataFormFields.phoneOtp]: '',
      [profileDataFormFields.birthDate]: data?.birthDate
        ? moment(data.birthDate).locale(i18n.language).format('DD-MMM-YYYY')
        : '',
      // [profileDataFormFields.address]: data?.address || "",
      [profileDataFormFields.employerAddress]: data?.employerAddress || '',
      [profileDataFormFields.companyName]: data?.companyName || '',
      [profileDataFormFields.nationalId]: data?.nationalId || '',
    };

    setInitialValues(newValues);

    resetForm({ values: newValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user]);

  useEffect(() => {
    setCustomErrors((prev) => ({ ...prev, emailOtp: '', phoneOtp: '' }));
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[profileDataFormFields.phoneOtp],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[profileDataFormFields.emailOtp],
  ]);

  const onPhoneBlur = async () => {
    if (
      values[profileDataFormFields.phone] &&
      phoneChanged &&
      phoneSent !== values[profileDataFormFields.phone] &&
      !errors.phone
    ) {
      setCustomErrors((prev) => ({ ...prev, phone: '' }));
      setPhoneOtpLoading(true);
      try {
        setPhoneSent(values[profileDataFormFields.phone]);

        await sendProfileUpdateOTP({
          mobileNumber: values[profileDataFormFields.phone],
        }).unwrap();
      } catch (error: any) {
        if (i18nApiError.exists(`apiError:${error?.data?.message}`)) {
          setCustomErrors((prev) => ({
            ...prev,
            phone: `apiError:${error?.data?.message}`,
          }));
        }
      }
      setPhoneOtpLoading(false);
    }

    if (!phoneChanged) {
      setCustomErrors((prev) => ({ ...prev, phone: '' }));
    }
  };

  const onEmailBlur = async () => {
    if (
      values[profileDataFormFields.email] &&
      emailSent !== values[profileDataFormFields.email] &&
      emailChanged &&
      !errors.email
    ) {
      setCustomErrors((prev) => ({ ...prev, email: '' }));
      setEmailOtpLoading(true);
      try {
        setEmailSent(values[profileDataFormFields.email]);

        await sendProfileUpdateOTP({
          email: values[profileDataFormFields.email],
        }).unwrap();
      } catch (error: any) {
        if (i18nApiError.exists(`apiError:${error?.data?.message}`)) {
          setCustomErrors((prev) => ({
            ...prev,
            email: `apiError:${error?.data?.message}`,
          }));
        }
      }
      setEmailOtpLoading(false);
    }

    if (!emailChanged) {
      setCustomErrors((prev) => ({ ...prev, email: '' }));
    }
  };

  const onInputOtp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (event.target.value) {
      // @ts-ignore
      event.target.value = Math.max(
        0,
        // @ts-ignore
        parseInt(event.target.value, 10).toString().slice(0, 6),
      );
    }
  };

  const onKeyDownOtp = (event: React.KeyboardEvent<HTMLInputElement>) =>
    ['e', 'E', '-', '.', ','].includes(event.key) && event.preventDefault();

  const renderLoaderInInput = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 12,
      }}
    >
      <CircularProgress size={30} />
    </Box>
  );

  const renderEditForm = () => (
    <Grid item container display="flex" columnSpacing={6} component="form" noValidate>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.fullName')}
        </Typography>
        <MTextField
          fullWidth
          required
          disabled
          margin="none"
          variant="outlined"
          error={touched.fullName && !!errors.fullName}
          helperText={touched.fullName && t(errors.fullName as string)}
          autoComplete="fullName"
          placeholder={t('userProfile.enterFullName')}
          {...getFieldProps(profileDataFormFields.fullName)}
        />
      </Grid>
      {/* <Grid */}
      {/*  item */}
      {/*  mb={8} */}
      {/*  md={6} */}
      {/*  sm={12} */}
      {/*  xs={12} */}
      {/*  lg={6} */}
      {/*  display="flex" */}
      {/*  flexDirection="column" */}
      {/* > */}
      {/*  <Typography */}
      {/*    variant={isEnglish ? "bodyMedium" : "cairoM"} */}
      {/*    sx={{ textTransform: "capitalize", color: COLORS.LIGHT_GRAY }} */}
      {/*  > */}
      {/*    {t("userProfile.address")} */}
      {/*  </Typography> */}
      {/*  <MTextField */}
      {/*    fullWidth */}
      {/*    required */}
      {/*    margin="none" */}
      {/*    variant="outlined" */}
      {/*    error={touched.address && !!errors.address} */}
      {/*    helperText={touched.address && t(errors.address as string)} */}
      {/*    autoComplete="address" */}
      {/*    placeholder={t("userProfile.enterAddress")} */}
      {/*    {...getFieldProps(profileDataFormFields.address)} */}
      {/*  /> */}
      {/* </Grid> */}
      <Grid item container mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Grid item container>
          <Grid item flexGrow={1}>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
            >
              {t('userProfile.mobileNumber')}
            </Typography>
          </Grid>
          {phoneChanged && phoneSent && !phoneOtpLoading && !customErrors?.phone && (
            <Grid item minWidth={85} maxWidth={85} ml={1}>
              <Typography
                variant={isEnglish ? 'bodyMedium' : 'cairoM'}
                sx={{
                  textTransform: 'capitalize',
                  color: COLORS.LIGHT_GRAY,
                }}
              >
                {t('userProfile.otp')}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item container display="flex" flexDirection="row" flexWrap="nowrap">
          <Grid item flexGrow={1} position="relative">
            <MobileField
              fullWidth
              required
              margin="none"
              variant="outlined"
              autoComplete="phone"
              placeholder={t('userProfile.enterPhone')}
              disabled={phoneOtpLoading}
              {...getFieldProps(profileDataFormFields.phone)}
              error={touched.phone && (!!errors.phone || !!customErrors?.phone)}
              helperText={touched.phone && (t(errors.phone as string) || t(customErrors?.phone))}
              onBlur={onPhoneBlur}
            />
            {phoneOtpLoading && renderLoaderInInput()}
          </Grid>
          {phoneChanged && phoneSent && !phoneOtpLoading && !customErrors?.phone && (
            <Grid item minWidth={85} maxWidth={85} ml={1}>
              <MTextField
                required
                margin="none"
                variant="outlined"
                type="number"
                autoComplete="one-time-code"
                placeholder={t('userProfile.enterPhoneOtp')}
                {...getFieldProps(profileDataFormFields.phoneOtp)}
                error={touched.phoneOtp && (!!errors.phoneOtp || !!customErrors?.phoneOtp)}
                helperText={touched.phoneOtp && (t(errors.phoneOtp as string) || t(customErrors?.phoneOtp))}
                onInput={onInputOtp}
                onKeyDown={onKeyDownOtp}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.employerAddress')}
        </Typography>
        <MTextField
          fullWidth
          required
          margin="none"
          variant="outlined"
          error={touched.employerAddress && !!errors.employerAddress}
          helperText={touched.employerAddress && t(errors.employerAddress as string)}
          autoComplete="employerAddress"
          placeholder={t('userProfile.employerAddressPlaceholder')}
          {...getFieldProps(profileDataFormFields.employerAddress)}
        />
      </Grid>
      <Grid item container mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Grid item container>
          <Grid item flexGrow={1}>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
            >
              {t('userProfile.email')}
            </Typography>
          </Grid>
          {emailChanged && emailSent && !emailOtpLoading && !customErrors?.email && (
            <Grid item minWidth={85} maxWidth={85} ml={1}>
              <Typography
                variant={isEnglish ? 'bodyMedium' : 'cairoM'}
                sx={{
                  textTransform: 'capitalize',
                  color: COLORS.LIGHT_GRAY,
                }}
              >
                {t('userProfile.otp')}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item container display="flex" flexDirection="row" flexWrap="nowrap">
          <Grid item flexGrow={1} position="relative">
            <MTextField
              fullWidth
              required
              margin="none"
              variant="outlined"
              autoComplete="email"
              placeholder={t('userProfile.enterEmail')}
              disabled={emailOtpLoading}
              {...getFieldProps(profileDataFormFields.email)}
              error={touched.email && (!!errors.email || !!customErrors?.email)}
              helperText={touched.email && (t(errors.email as string) || t(customErrors?.email))}
              onBlur={onEmailBlur}
            />
            {emailOtpLoading && renderLoaderInInput()}
          </Grid>
          {emailChanged && emailSent && !emailOtpLoading && !customErrors?.email && (
            <Grid item minWidth={85} maxWidth={85} ml={1}>
              <MTextField
                required
                margin="none"
                variant="outlined"
                autoComplete="emailOtp"
                placeholder={t('userProfile.enterEmailOtp')}
                {...getFieldProps(profileDataFormFields.emailOtp)}
                error={touched.emailOtp && (!!errors.emailOtp || !!customErrors?.emailOtp)}
                helperText={touched.emailOtp && (t(errors.emailOtp as string) || t(customErrors?.emailOtp))}
                onInput={onInputOtp}
                onKeyDown={onKeyDownOtp}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.organization')}
        </Typography>
        <MTextField
          fullWidth
          required
          margin="none"
          variant="outlined"
          error={touched.companyName && !!errors.companyName}
          helperText={touched.companyName && t(errors.companyName as string)}
          autoComplete="companyName"
          placeholder={t('userProfile.enterOrganization')}
          {...getFieldProps(profileDataFormFields.companyName)}
        />
      </Grid>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.birthDate')}
        </Typography>
        <MTextField
          fullWidth
          required
          disabled
          margin="none"
          variant="outlined"
          error={touched.birthDate && !!errors.birthDate}
          helperText={touched.birthDate && t(errors.birthDate as string)}
          autoComplete="birthDate"
          placeholder={t('userProfile.enterBirthDate')}
          {...getFieldProps(profileDataFormFields.birthDate)}
        />
      </Grid>
      <Grid item mb={8} md={6} sm={12} xs={12} lg={6} display="flex" flexDirection="column">
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}
        >
          {t('userProfile.nationalId')}
        </Typography>
        <MTextField
          fullWidth
          required
          disabled
          margin="none"
          variant="outlined"
          error={touched.nationalId && !!errors.nationalId}
          helperText={touched.nationalId && t(errors.nationalId as string)}
          autoComplete="nationalId"
          placeholder={t('userProfile.enterNationalId')}
          {...getFieldProps(profileDataFormFields.nationalId)}
        />
      </Grid>
    </Grid>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <Grid container display="flex" justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item container display="flex" columnSpacing={6}>
        {!props.editMode &&
          items.map(({ value, title }, i: number) => (
            <Grid
              key={`profile-data-${title}`}
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              display="flex"
              flexDirection="column"
              sx={i < items.length - 1 ? { mb: '32px' } : {}}
            >
              <Typography variant="bodyMedium" sx={{ textTransform: 'capitalize', color: COLORS.LIGHT_GRAY }}>
                {t(title)}
              </Typography>
              <Typography
                variant="bodyLargeM"
                sx={{
                  mt: '8px',
                  color: COLORS.MAIN_DARK,
                  wordWrap: 'break-word',
                }}
              >
                {value}
              </Typography>
            </Grid>
          ))}
        {props.editMode && renderEditForm()}
      </Grid>
    );
  };

  return (
    <Grid
      item
      container
      display="flex"
      sx={{
        p: '32px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '12px',
      }}
      component={Paper}
      elevation={0}
    >
      {renderContent()}
    </Grid>
  );
});

export default ProfileDataSection;
