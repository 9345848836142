import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { locations } from '../../../routes/locations';
import { useTypedDispatch } from '../../../redux/store';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import { getAvailablePortfoliosSelector } from '../../../store_deprecated/selectors/portfolioSelectors';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { findRecommendedPortfolio } from '../../../store_deprecated/actions/portfolioActions';
import { fetchAttachedDocuments } from '../../../store_deprecated/actions/docsActions';
import MButton from '../../../shared/MButton';
import COLORS from '../../../constants/colors';

const RiskAssessmentScore = React.lazy(() => import('./RiskAssessmentScore'));
const RecommendedPortfolios = React.lazy(() => import('./RecommendedPortfolios'));
const Expect2030 = React.lazy(() => import('./Expect2030'));

const mapPageToStep: Record<string, number> = {
  riskAssessmentScore: 0,
  recommendedPortfolios: 1,
  expect2030: 2,
};

const mapStepToPage: Record<number, string> = {
  0: 'riskAssessmentScore',
  1: 'recommendedPortfolios',
  2: 'expect2030',
};

const RiskSurveyView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [search, setSearch] = useSearchParams();
  const [step, setStep] = useState(mapPageToStep[search.get('page') as string] || 0);

  const surveyResult = useSelector(getSurveyResult);
  const availablePortfolios = useSelector(getAvailablePortfoliosSelector);

  const renderStep = () => {
    switch (step) {
      case 0:
        return <RiskAssessmentScore />;

      case 1:
        return <RecommendedPortfolios />;

      case 2:
        return <Expect2030 />;

      default:
        break;
    }
  };

  const renderCTA = () => {
    switch (step) {
      case 0:
        return 'next';

      case 1:
        return 'expect2030';

      default:
        return '';
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleSkip = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const retakeSurvey = () => {
    navigate(locations.survey());
  };

  useEffect(() => {
    dispatch(fetchAttachedDocuments());

    if (!surveyResult?.category?.id) {
      dispatch(fetchLastSurveyResult());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchRecommendedPortfolio = async (category: string) => {
      await dispatch(findRecommendedPortfolio(category));
    };

    if (surveyResult?.category?.id) {
      fetchRecommendedPortfolio(surveyResult?.category?.nameEn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyResult?.category]);

  useEffect(() => {
    setSearch({ page: mapStepToPage[step] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const renderRetakeSurveyButton = () => (
    <MButton
      variant="outlined"
      onClick={retakeSurvey}
      id="RetakeSurveyButton"
      fullWidth
      buttonProps={{
        sx: {
          width: { xs: '100%', md: '100%' },
          height: { xs: '50px', md: 60 },
          borderRadius: '80px',
          boxShadow: 'none',
        },
      }}
    >
      {t('retakeSurvey')}
    </MButton>
  );

  return (
    <Box
      sx={{
        marginLeft: { xs: 6, lg: 'auto' },
        marginRight: { xs: 6, lg: 'auto' },
        maxWidth: '1200px',
      }}
    >
      {renderStep()}
      <Box
        component="div"
        sx={{
          maxWidth: '1440px',
          margin: 'auto',
          mb: '24px',
        }}
      >
        {/* TODO: expect2030 is not included in MVP */}
        {[0].includes(step) && (
          <Box sx={{ mt: step === 0 ? '24px' : '16px', mb: '16px' }}>
            <MButton
              variant={step === 1 ? 'text' : 'contained'}
              fullWidth
              onClick={handleNext}
              buttonProps={{
                sx: {
                  width: { xs: '100%', md: '100%' },
                  height: { xs: '50px', md: 60 },
                  borderRadius: '80px',
                  boxShadow: 'none',
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
              }}
            >
              {t(renderCTA())}
            </MButton>
          </Box>
        )}
        {step === 0 && renderRetakeSurveyButton()}
        {Boolean(step) && availablePortfolios.data.totalItems > 0 && (
          <Box sx={{ mt: '16px' }}>
            <MButton
              variant="text"
              onClick={!step ? handleSkip : handleBack}
              id="SkipButton"
              fullWidth
              buttonProps={{
                sx: {
                  width: { xs: '100%', md: '100%' },
                  height: { xs: '50px', md: 60 },
                  borderRadius: '80px',
                  boxShadow: 'none',
                },
              }}
            >
              {/* TODO: The SKIP button should lead to “skip survey report; however, survey report is not part of the mvp. So please hide it */}
              {/*! step ? t("skip") : */ t('back')}
            </MButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RiskSurveyView;
