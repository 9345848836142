import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import COLORS from '../constants/colors';
import { parseApiError } from '../helpers/api';
import { useChangePasswordMutation } from '../redux/features/auth/auth.apiSlice';
import MButton from './MButton';
import { PasswordValidationRulesWithConfirmPassword } from '../constants/PasswordValidationRules';
import { changePasswordFormFields } from '../forms/fields/formFields';
import { changePasswordFormSchema } from '../forms/validationSchema/formSchema';
import { validate } from '../utils/validate';
import PasswordFieldRoles from '../components/User/Signup/PasswordFieldRoles';
import PasswordField from '../components/User/Signup/PasswordField';
import PasswordValidationStatus from '../constants/PasswordValidationStatus';

interface ChangePasswordDialogProps {
  open: boolean;
  onClose: (isSuccess?: boolean) => void;
}

export default function ChangePasswordDialog(props: ChangePasswordDialogProps) {
  const { open, onClose } = props;
  const { t, i18n } = useTranslation();
  const { t: tAuth } = useTranslation('auth', { useSuspense: true });
  const isEnglish = i18n.language === 'en';
  const [passwordValidation, setPasswordValidation] = useState({
    ...PasswordValidationRulesWithConfirmPassword,
  });

  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState({
    [PasswordValidationStatus.PASSWORD_AND_CONFIRM_PASSWORD_DOES_MATCH]:
      PasswordValidationRulesWithConfirmPassword[PasswordValidationStatus.PASSWORD_AND_CONFIRM_PASSWORD_DOES_MATCH],
  });

  const [changePassword, { isLoading, error, isError }] = useChangePasswordMutation();

  const handleClose = (res = false) => {
    if (isLoading) {
      return;
    }

    onClose(res);
  };

  const { handleSubmit, values, isValid, handleChange, resetForm } = useFormik({
    initialValues: {
      [changePasswordFormFields.oldPassword]: '',
      [changePasswordFormFields.newPassword]: '',
      [changePasswordFormFields.confirmNewPassword]: '',
    },
    validationSchema: changePasswordFormSchema,
    onSubmit: async (formValues) => {
      await changePassword({
        oldPassword: Buffer.from(formValues.oldPassword).toString('base64'),
        newPassword: Buffer.from(formValues.newPassword).toString('base64'),
      }).unwrap();
      handleClose(true);
    },
  });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const errors: any = validate({
      type: 'password',
      value: values[changePasswordFormFields.newPassword],
    });

    // @ts-ignore
    Object.keys(passwordValidation).forEach((condition: string) => {
      const rules = passwordValidation;
      // @ts-ignore
      rules[condition].isValid = !errors.includes(condition);
      setPasswordValidation(rules);
    });

    const confirmErrors: any =
      values[changePasswordFormFields.newPassword] === values[changePasswordFormFields.confirmNewPassword] &&
      values[changePasswordFormFields.confirmNewPassword] !== ''
        ? PasswordValidationStatus.PASSWORD_AND_CONFIRM_PASSWORD_DOES_MATCH
        : '';

    // @ts-ignore
    Object.keys(confirmPasswordValidation).forEach((condition: string) => {
      const rules = confirmPasswordValidation;
      // @ts-ignore
      rules[condition].isValid = confirmErrors.includes(condition);
      setConfirmPasswordValidation(rules);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[changePasswordFormFields.newPassword],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[changePasswordFormFields.confirmNewPassword],
  ]);

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Box sx={{ padding: '24px' }}>
        <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px">
          <Grid item textAlign="right" alignSelf="end">
            <IconButton onClick={() => handleClose()}>
              <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
            </IconButton>
          </Grid>
          <Grid
            item
            sx={{
              paddingTop: { xs: '24px !important', md: '48px !important' },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '24px', md: '44px' },
                lineHeight: { xs: '32px', md: '32px' },
                color: COLORS.MAIN_DARK,
              }}
            >
              {t('changePasswordDialog.title')}
            </Typography>
          </Grid>
          <Grid item textAlign="center" sx={{ mb: '24px' }}>
            <Typography
              variant="bodyLarge"
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.DARK_GREY,
              }}
            >
              {t('changePasswordDialog.description')}
            </Typography>
          </Grid>
          <Grid container direction="row" sx={{ mt: 1 }}>
            <PasswordFieldRoles
              isArabic={!isEnglish}
              password={values[changePasswordFormFields.newPassword]}
              passwordValidation={passwordValidation}
            />
          </Grid>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: '16px', width: '100%' }}>
            <PasswordField
              isArabic={!isEnglish}
              label="oldPassword"
              autoComplete="current-password"
              id={changePasswordFormFields.oldPassword}
              name={changePasswordFormFields.oldPassword}
              password={values[changePasswordFormFields.oldPassword]}
              handleChangePassword={handleChange}
            />
            <Box sx={{ mb: '16px' }} />
            <PasswordField
              isArabic={!isEnglish}
              label="newPassword"
              autoComplete="new-password"
              id={changePasswordFormFields.newPassword}
              name={changePasswordFormFields.newPassword}
              password={values[changePasswordFormFields.newPassword]}
              handleChangePassword={handleChange}
            />
            <Box sx={{ mb: '16px' }} />
            <PasswordField
              isArabic={!isEnglish}
              label="reEnterNewPassword"
              autoComplete="new-password"
              id={changePasswordFormFields.confirmNewPassword}
              name={changePasswordFormFields.confirmNewPassword}
              password={values[changePasswordFormFields.confirmNewPassword]}
              handleChangePassword={handleChange}
            />
            {(isLoading || isError) && (
              <Grid item>
                {isLoading && <CircularProgress />}
                {isError && (
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: 700,
                      fontSize: '18px',
                      lineHeight: '28px',
                      color: COLORS.RED,
                    }}
                  >
                    {tAuth(parseApiError(error)?.data?.message)}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item container rowSpacing="24px" mt={6}>
              <Grid item width="100%">
                <MButton
                  variant="contained"
                  buttonProps={{ type: 'submit' }}
                  disabled={!isValid || isLoading}
                  fullWidth
                >
                  {tAuth('saveNewPassword')}
                </MButton>
              </Grid>
              <Grid item width="100%">
                <MButton variant="text" onClick={() => handleClose()} disabled={isLoading} fullWidth>
                  {t('cancel')}
                </MButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Dialog>
  );
}
