import React from 'react';
import { useTranslation } from 'react-i18next';
// import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MButton from '../../shared/MButton';
import COLORS from '../../constants/colors';

const AskForAnAdvice: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  const goTo = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Box
      sx={{
        padding: { xs: '56px 24px 0', md: '32px' },
        mt: { xs: 'auto', md: '25vh' },
        mb: { xs: 'auto', md: '5vh' },
        minHeight: { xs: 'calc(100vh - 330px)', md: '40vh' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontSize: { xs: '18px', md: '24px' },
          }}
        >
          {t('askForAnAdvise.title')}
        </Typography>
        <Box
          sx={{
            mt: { xs: 2, md: 4 },
          }}
        >
          <MButton
            variant="contained"
            id="EmailUsButton"
            buttonProps={{
              sx: {
                width: { xs: 150, md: 160 },
                height: 50,
                padding: '8px 24px',
                borderRadius: '80px',
                boxShadow: 'none',
                mr: { xs: 1, md: 4 },
                ml: { xs: 1, md: 'unset' },
                mb: 2,
                backgroundColor: COLORS?.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              },
            }}
            typographyProps={{
              variant: 'bodyMediumB',
              textAlign: 'center',
              color: 'white',
            }}
            onClick={() => goTo('https://twitter.com/madkhol_en')}
          >
            <img
              style={{
                width: '21px',
                height: 'auto',
                margin: isEnglish ? 'auto 8px auto 0' : 'auto 0 auto 8px',
              }}
              src="/images/social-media/twitter-white.webp"
              alt="twitter logo"
            />
            {t('askForAnAdvise.twitter')}
          </MButton>
          <MButton
            variant="contained"
            id="EmailUsButton"
            buttonProps={{
              sx: {
                width: { xs: 150, md: 160 },
                height: 50,
                padding: '8px 24px',
                borderRadius: '80px',
                boxShadow: 'none',
                mx: { xs: 1, md: 'unset' },
                mb: 2,
                backgroundColor: COLORS?.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              },
            }}
            typographyProps={{
              variant: 'bodyMediumB',
              textAlign: 'center',
              color: 'white',
            }}
            onClick={() => goTo('https://wa.me/966557694449')}
          >
            <img
              style={{
                width: '24px',
                height: 'auto',
                margin: isEnglish ? 'auto 8px auto 0' : 'auto 0 auto 8px',
              }}
              src="/images/social-media/whatsapp-white.webp"
              alt="whatsapp logo"
            />
            {t('askForAnAdvise.whatsapp')}
          </MButton>
        </Box>
      </Box>
      {/* {config.calendlyBaseUrl ? (
        <InlineWidget
          url={config.calendlyBaseUrl}
          utm={{
            utmCampaign: "Ask For Advice",
            utmContent: "Ask For Advice",
            utmMedium: "Ask For Advice",
            utmSource: "Web App",
            utmTerm: "Ask For Advice",
          }}
          prefill={{
            email: data?.email,
            firstName: isEnglish ? data?.firstNameEn : data?.firstNameAr,
            lastName: isEnglish ? data?.familyNameEn : data?.familyNameAr,
            name: isEnglish ? data?.fullNameEn : data?.fullNameAr,
          }}
          styles={{ height: "650px" }}
        />
      ) : (
        <p>Calendly is not configured</p>
      )} */}
    </Box>
  );
};

export default AskForAnAdvice;
