export enum recurringPaymentFormFields {
  amount = 'amount',
  frequencyUnit = 'frequencyUnit',
  frequencyCount = 'frequencyCount',
  startDate = 'startDate',
  endDateType = 'endDateType',
  endDate = 'endDate',
  status = 'status'
}

export enum RecurringFrequencyUnit {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export const FrequencyOptions = [
  RecurringFrequencyUnit.HOURLY,
  RecurringFrequencyUnit.DAILY,
  RecurringFrequencyUnit.WEEKLY,
  RecurringFrequencyUnit.MONTHLY,
  RecurringFrequencyUnit.QUARTERLY,
];

export enum RecurringEndType {
  INFINITY = 'infinity',
  DATE = 'date',
}

export enum RecurringStatus {
  CREATED = 'Created', // Once it's first create by user (first step creation)
  ACTIVE = 'Active', // Once the card registered successfully (second step creation)
  EXPIRED = 'Expired',
  CANCELED = 'Canceled',
  DELETED = 'Deleted',
}

export const RecurringEndTypeOptions = [RecurringEndType.INFINITY, RecurringEndType.DATE];
