import { ActivityCategory, ActivityType, OrderSide, OrderStatus, OrderType } from '../../helpers/enums';
import { Logout } from './authTypes';

export const CLEAR_ALPACA = 'CLEAR_ALPACA';
export const GET_ALPACA_ACTIVITIES_REQUEST = 'GET_ALPACA_ACTIVITIES_REQUEST';
export const GET_ALPACA_ACTIVITIES_SUCCESS = 'GET_ALPACA_ACTIVITIES_SUCCESS';
export const GET_ALPACA_ACTIVITIES_FAIL = 'GET_ALPACA_ACTIVITIES_FAIL';

/* eslint-disable @typescript-eslint/naming-convention */

interface BaseActivity {
  id: string;
  account_id: string;
  activity_type: ActivityType;
}

export interface JNLCActivity extends BaseActivity {
  date: string;
  net_amount: string;
  description: string;
  status: string;
}

export interface CSDActivity extends BaseActivity {
  date: string;
  net_amount: string;
  description: string;
  status: string;
}

export interface FEEActivity extends BaseActivity {
  date: string;
  net_amount: string;
  description: string;
  status: string;
}

export interface FillActivity extends BaseActivity {
  transaction_time: string;
  type: OrderType;
  price: string | number;
  qty: string | number;
  side: OrderSide;
  symbol: string;
  leaves_qty: string | number;
  order_id: string;
  cum_qty: string | number;
  order_status: OrderStatus;
}

export type AccountActivity = JNLCActivity | CSDActivity | FEEActivity | FillActivity;

export interface AlpacaActivityRequestParams {
  date?: string;
  until?: string;
  after?: string;
  direction?: 'desc' | 'asc';
  page_size?: number;
  page_token?: string;
  category?: ActivityCategory;
}

export type AlpacaActivity = JNLCActivity | CSDActivity | FEEActivity | FillActivity;

export type AlpacaActivities = {
  data: AlpacaActivity[];
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export interface RequestAlpacaActivities {
  type: typeof GET_ALPACA_ACTIVITIES_REQUEST;
}

export interface RequestAlpacaActivitiesSuccess {
  type: typeof GET_ALPACA_ACTIVITIES_SUCCESS;
  payload: AlpacaActivity[];
}

export interface RequestAlpacaActivitiesFail {
  type: typeof GET_ALPACA_ACTIVITIES_FAIL;
  payload: string;
}

export interface ClearAlpaca {
  type: typeof CLEAR_ALPACA;
}

export type AlpacaAction =
  | RequestAlpacaActivities
  | RequestAlpacaActivitiesSuccess
  | RequestAlpacaActivitiesFail
  | Logout
  | ClearAlpaca;

export type AlpacaState = Readonly<{
  activities: AlpacaActivities;
}>;
