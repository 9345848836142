import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import { StyledTableCell } from '../../../shared/CustomTable';
import {
  PortfolioStatisticsData,
  RecommendedPortfolio,
  SubscribedPortfolio,
} from '../../../store_deprecated/types/portfolioTypes';
import { UserIndexFund } from '../../../store_deprecated/types/indexFundTypes';
import { formatNumber } from '../../../utils/number';
import { locations } from '../../../routes/locations';
import { PortfolioPositionRowDataItem, PortfolioPositions } from '../../../utils/PortfolioPositions';

interface PortfolioPositionsTableProps {
  portfolio?: SubscribedPortfolio | RecommendedPortfolio;
  userIndexFunds: UserIndexFund[];
  latestStatistics: PortfolioStatisticsData | null;
  beta?: boolean;
}

const PortfolioPositionsTableV2 = (props: PortfolioPositionsTableProps) => {
  const { portfolio, userIndexFunds, latestStatistics, beta = false } = props;
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [rows, setRows] = useState<PortfolioPositionRowDataItem[]>([]);
  const isEnglish = i18n.language === 'en';

  useEffect(() => {
    if (!portfolio) {
      return;
    }

    const portfolioPositions = new PortfolioPositions(
      beta
        ? {
            realTime: false,
            portfolio,
            userIndexFunds,
            portfolioStatisticsData: latestStatistics,
          }
        : {
            realTime: true,
            portfolio,
            userIndexFunds,
          },
    );

    const newRows = portfolioPositions.calculatePositions().filter((item) => item.units > 0);

    setRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio, userIndexFunds]);

  return (
    <TableContainer component={Paper} elevation={1} sx={{ minWidth: { xs: 'unset', md: 650 } }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ minWidth: { xs: '170px', md: 'auto' } }}>
              {t('portfolioPositions.assetClass')}
            </StyledTableCell>
            <StyledTableCell>{t('portfolioPositions.allocation')}</StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '170px', md: 'auto' } }}>
              {t('portfolioPositions.costBaseValue')}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '170px', md: 'auto' } }}>
              {t('portfolioPositions.marketValue')}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '170px', md: 'auto' } }}>
              {t('portfolioPositions.totalReturn')}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '170px', md: 'auto' } }}>
              {t('portfolioPositions.totalSarReturn')}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={`portfolio-positions-table-${row.id}`}>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.PRIMARY_BLUE,
                  fontWeight: 600,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '15%',
                }}
              >
                <Link
                  underline="none"
                  onClick={() =>
                    navigate(locations.fundDetails(row.indexFund.id), {
                      state: { from: pathname },
                    })
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  {isEnglish ? row.indexFund.strategy.nameEn : row.indexFund.strategy.nameAr}
                </Link>
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '10%',
                }}
              >
                {formatNumber(row.allocation, i18n.language, 2)}%
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '13%',
                }}
              >
                {formatNumber(row.costBaseValue, i18n.language, 2)}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '11%',
                }}
              >
                {formatNumber(row.marketValue, i18n.language, 2)}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '10%',
                }}
              >
                {formatNumber(row.totalReturn, i18n.language, 2)}%
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '13%',
                }}
              >
                {formatNumber(row.totalSarReturn, i18n.language, 2)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PortfolioPositionsTableV2;
