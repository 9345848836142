import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../redux/features/auth/auth.selectors';
import { logOut } from '../../redux/features/auth/auth.slice';
import { useAppDispatch } from '../../redux/hooks';
import { saveUserPreference } from '../../redux/modules/userPreference/userPreference.actions';
import { UserPreferenceTypeEnum } from '../../types/userPreference';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import { locations } from '../../routes/locations';

const Header: React.FC = memo(() => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const languages = { ar: 'العربية', en: 'English' };
  const language = i18n.language === 'ar' ? languages.en : languages.ar;
  const isArabic = i18n.language === 'ar';

  const { isLoggedIn } = useSelector(authSelector);
  const [logout] = useLogoutMutation();

  const logoutUser = async () => {
    try {
      await logout({});
      dispatch(logOut());
      navigate(locations.login());
      window.location.reload();
    } catch (error) {
      console.error('Logout failed', error);
    }
  };
  const handleChangeLanguage = () => {
    localStorage.setItem('i18nextLng', i18n.language === 'en' ? 'ar' : 'en');
    i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');

    if (isLoggedIn) {
      dispatch(
        saveUserPreference({
          type: UserPreferenceTypeEnum.PERF_LANG,
          value: i18n.language,
        }),
      );
    }
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      color="transparent"
      sx={{ boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)', height: '64px' }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ px: { xs: 0, lg: 28 } }}>
          <DesktopView
            isArabic={isArabic}
            isLoggedIn={isLoggedIn || false}
            language={language}
            logoutUser={logoutUser}
            handleChangeLanguage={handleChangeLanguage}
          />
          <MobileView
            isArabic={isArabic}
            isLoggedIn={isLoggedIn || false}
            locations={locations}
            language={language}
            logoutUser={logoutUser}
            handleChangeLanguage={handleChangeLanguage}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default Header;
