import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as Api from '../../helpers/api_deprecated';
import { getCongratulationModalAppearBefore, removeCongratulationModalAppearBefore } from '../../helpers/LocalStorage';
import {
  GET_PROFILE_DATA_FAIL,
  GET_PROFILE_DATA_REQUEST,
  GET_PROFILE_DATA_SUCCESS,
  LOGOUT,
  Logout,
  ProfileDataInfo,
  RequestGetProfileData,
  RequestGetProfileDataFail,
  RequestGetProfileDataSuccess,
  RequestUpdateProfileData,
  RequestUpdateProfileDataFail,
  RequestUpdateProfileDataSuccess,
  UPDATE_PROFILE_DATA_FAIL,
  UPDATE_PROFILE_DATA_REQUEST,
  UPDATE_PROFILE_DATA_SUCCESS,
  UpdateProfileDataPayload,
} from '../types/authTypes';

export const requestGetProfileData = (): RequestGetProfileData => ({
  type: GET_PROFILE_DATA_REQUEST,
});

export const requestGetProfileDataSuccess = (result: ProfileDataInfo): RequestGetProfileDataSuccess => ({
  type: GET_PROFILE_DATA_SUCCESS,
  payload: result,
});

export const requestGetProfileDataFail = (): RequestGetProfileDataFail => ({
  type: GET_PROFILE_DATA_FAIL,
});

export const requestUpdateProfileData = (): RequestUpdateProfileData => ({
  type: UPDATE_PROFILE_DATA_REQUEST,
});

export const requestUpdateProfileDataSuccess = (result: ProfileDataInfo): RequestUpdateProfileDataSuccess => ({
  type: UPDATE_PROFILE_DATA_SUCCESS,
  payload: result,
});

export const requestUpdateProfileDataFail = (): RequestUpdateProfileDataFail => ({
  type: UPDATE_PROFILE_DATA_FAIL,
});

export const findProfileData = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestGetProfileData());
  try {
    const response: any = await Api.getProfileData();

    dispatch(requestGetProfileDataSuccess(response.data));
    const userId = getCongratulationModalAppearBefore();
    if (userId !== response.data.userId) removeCongratulationModalAppearBefore();
    // return result;
  } catch (error) {
    dispatch(requestGetProfileDataFail());
  }
};

export const updateProfileData =
  (data: UpdateProfileDataPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestUpdateProfileData());
    try {
      const response: any = await Api.updateProfileData(data);

      dispatch(requestUpdateProfileDataSuccess(data as ProfileDataInfo));

      return response.data;
    } catch (error: any) {
      dispatch(requestUpdateProfileDataFail());

      return error.message;
    }
  };

export const logout = (): Logout => ({ type: LOGOUT });
