import React from 'react';
import PropTypes from 'prop-types';
import Slider, { SliderProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import COLORS from '../constants/colors';
import { formatNumber } from '../utils/number';

// @ts-ignore
function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

export const PrettoSlider = styled(Slider)({
  color: COLORS.SECONDARY_GREEN,
  height: 8,
  '&.hide-thumb .MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: COLORS.WHITE,
    border: '4px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: '0.75rem',
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: COLORS.SECONDARY_GREEN,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-rail': {
    color: COLORS.XX_LIGHT_GREEN,
  },
});

interface CustomSliderProps extends SliderProps {
  handleChange: (event: Event, value: number | number[], activeThumb: number) => void;
  currentStepIndex: number;
}

const CustomSlider = (props: CustomSliderProps) => {
  /* @ts-ignore */
  const { value, min, max, handleChange, currentStepIndex } = props;

  return (
    <PrettoSlider
      id={`Question-${currentStepIndex}TextField`}
      valueLabelDisplay="auto"
      valueLabelFormat={(v) => `${formatNumber(v)}`}
      aria-label="pretto slider"
      value={value}
      min={min}
      max={max}
      onChange={handleChange}
    />
  );
};

export default CustomSlider;
