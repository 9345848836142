import { useNavigate } from 'react-router-dom';
import { locations } from '../../routes/locations';

function useWebsiteRedirect() {
  const navigate = useNavigate();

  const redirect = () => {
    if (process.env.REACT_APP_WEBSITE_BASE_URL) {
      window.location.href = process.env.REACT_APP_WEBSITE_BASE_URL;
      return;
    }
    navigate(locations.home());
  };

  return { redirect };
}

export default useWebsiteRedirect;
