import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState } from 'react';
import { addDays, addMonths, format } from 'date-fns';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTypedDispatch } from '../../../redux/store';
import { saveSubscription } from '../../../store_deprecated/actions/walletActions';
import { SaveSubscriptionPayload } from '../../../store_deprecated/types/walletTypes';
import { recurringPaymentFormSchema } from '../../../forms/validationSchema/recurringPayment';
import MButton from '../../../shared/MButton';
import {
  FrequencyOptions,
  RecurringEndType,
  RecurringEndTypeOptions,
  RecurringFrequencyUnit,
  RecurringStatus,
  recurringPaymentFormFields,
} from '../../../forms/fields/recurringPayment';
import COLORS from '../../../constants/colors';
import CustomSwitch from '../../../shared/CustomSwitch';
import { formatValue } from '../../../helpers/string';
import { ar2En } from '../../../utils/number';

type Subscription = {
  id: number;
  amount: number;
  startDate: Date | string;
  endDate: Date | string | null;
  endDateType: string;
  frequencyUnit: string;
  frequencyCount?: number;
  status: string;
  paymentBrand: string;
  last4Digits: string;
};

type RecurrentBillingFormProps = {
  subscription: Subscription;
  onCloseRecurringSettings?: (id: number) => void;
  onOpenUpdatedRecurringNotification?: any;
};

const RecurrentBillingForm: React.FC<RecurrentBillingFormProps> = (props: RecurrentBillingFormProps) => {
  const { subscription, onCloseRecurringSettings, onOpenUpdatedRecurringNotification } = props;
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const dispatch = useTypedDispatch();

  const errRef = React.useRef<any>();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [recurringStatus, setRecurringStatus] = useState(false);

  const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development';
  const allowedFrequencyOptions = isDevelopment
    ? FrequencyOptions
    : [RecurringFrequencyUnit.WEEKLY, RecurringFrequencyUnit.MONTHLY, RecurringFrequencyUnit.QUARTERLY];

  const isEnglish = i18n.language === 'en';
  const xs = useMediaQuery('(max-width:600px)');

  const updateSubscription = async (payload: SaveSubscriptionPayload) => {
    try {
      (await dispatch(saveSubscription(payload))) as any;
      onOpenUpdatedRecurringNotification();
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (e: any) {
      setErrorMessage(e.message);
    }
  };

  const { handleSubmit, handleChange, values, isValid, setFieldValue, touched, errors, getFieldProps } = useFormik({
    initialValues: {
      [recurringPaymentFormFields.amount]: subscription.amount,
      [recurringPaymentFormFields.frequencyUnit]: subscription.frequencyUnit.toLowerCase(),
      [recurringPaymentFormFields.startDate]: new Date(subscription.startDate),
      [recurringPaymentFormFields.endDateType]: subscription.endDateType.toLowerCase(),
      [recurringPaymentFormFields.endDate]: new Date(subscription.endDate!) || null,
      [recurringPaymentFormFields.status]: subscription.status,
    },
    validateOnMount: true,
    validationSchema: recurringPaymentFormSchema,

    onSubmit: async (formValues) => {
      const payload: SaveSubscriptionPayload = {
        id: subscription.id,
        endDate: formValues.endDateType === RecurringEndType.INFINITY ? null : format(formValues.endDate, 'yyyy-MM-dd'),
        startDate: format(formValues.startDate, 'yyyy-MM-dd'),
        frequencyUnit: formValues.frequencyUnit,
        endDateType: formValues.endDateType,
        amount: Number(formValues.amount),
        status: recurringStatus ? formValues.status : 'Active',
      };

      updateSubscription(payload);
    },
  });

  const handleDateChange = (field: string, date: Date | null) => {
    setFieldValue(field, date);
  };

  const handleStatusChange = () => {
    setRecurringStatus(true);
    const status =
      values[recurringPaymentFormFields.status] === RecurringStatus.ACTIVE
        ? RecurringStatus.CANCELED
        : RecurringStatus.ACTIVE;
    setFieldValue(recurringPaymentFormFields.status, status);
  };

  React.useEffect(() => {
    if (values[recurringPaymentFormFields.endDateType] === RecurringEndType.INFINITY) {
      setFieldValue(recurringPaymentFormFields.endDate, null);
    } else if (subscription.endDate) {
      setFieldValue(recurringPaymentFormFields.endDate, new Date(subscription.endDate));
    } else {
      setFieldValue(recurringPaymentFormFields.endDate, addMonths(values[recurringPaymentFormFields.startDate], 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[recurringPaymentFormFields.endDateType],
  ]);

  const parseValue = (v: string | number) =>
    `${v}`
      // eslint-disable-next-line no-useless-escape
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    hasDecimal = true,
  ) => {
    const initialValue = hasDecimal ? event.target.value : event.target.value.split('.')[0];

    const newValue: string = parseValue(ar2En(initialValue));
    const newValueNumber = +newValue;

    // setValue(newValue);

    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;

      setFieldValue(field, event.target.value);

      return;
    }

    event.target.value = `${newValueNumber}`;

    // setValue(event.target.value);

    setFieldValue(field, event.target.value);
  };

  return (
    <Grid component="form" sx={{ gap: '24px', display: 'grid' }}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media (max-width:600px)': {
              flexDirection: 'column',
              alignItems: 'baseline',
            },
          }}
        >
          <Box
            sx={{
              '@media (max-width:600px)': {
                width: '100%',
              },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: { xs: 500, md: 500 },
                fontSize: { xs: '16px', md: '24px' },
                lineHeight: '44px',
                color: { xs: COLORS.WHITE, sm: COLORS.BLACK, md: COLORS.BLACK },
              }}
            >
              {t('dashboard.newWalletCard.recurringPaymentSettings')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              '@media (max-width:600px)': {
                width: '100%',
                justifyContent: 'start',
                flexDirection: 'row-reverse',
              },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: { xs: 500, md: 500 },
                fontSize: '14px',
                lineHeight: '44px',
                color: { xs: COLORS.WHITE, sm: COLORS.BLACK, md: COLORS.BLACK },
              }}
            >
              {t('dashboard.newWalletCard.recurringSwitch')}
            </Typography>
            <CustomSwitch
              checked={values[recurringPaymentFormFields.status] === RecurringStatus.ACTIVE}
              onClick={handleStatusChange}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Box>
            <Typography
              variant={isEnglish ? 'bodyMedium' : 'cairoM'}
              paragraph
              sx={{
                m: 0,
                fontSize: '14px',
                color: { xs: COLORS.WHITE, sm: COLORS.BLACK, md: COLORS.BLACK },
              }}
            >
              {t('recurringForm.amount')}
            </Typography>
            <TextField
              id="amount"
              variant="outlined"
              error={touched.amount && !!errors.amount}
              helperText={touched.amount && t(errors.amount as string)}
              {...getFieldProps(recurringPaymentFormFields.amount)}
              value={formatValue(!isEnglish, (values[recurringPaymentFormFields.amount] || '').toString()).replace(
                /٬/g,
                ',',
              )}
              name={recurringPaymentFormFields.amount}
              required
              sx={{
                borderRadius: '8px',
                '& .MuiFormHelperText-root': {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                },
                '& .MuiFormLabel-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
                '& .MuiInput-underline.Mui-error:after': {
                  borderBottomColor: 'transparent !important',
                },
                '& .MuiFormHelperText-root.Mui-error': {
                  backgroundColor: 'transparent !important',
                },
                width: xs ? '100%' : '50%',
              }}
              InputProps={{
                required: true,
                type: 'text',
                style: {
                  fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                  backgroundColor: 'white',
                },
              }}
              placeholder={t('recurringForm.amountPlaceholder')}
              type="text"
              onChange={(e) => handleFieldChange(e, recurringPaymentFormFields.amount)}
            />
          </Box>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection={xs ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={xs ? 'flex-start' : 'center'}
      >
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          color={COLORS.MAIN_DARK}
          paragraph
          sx={{
            fontSize: '16px',
            m: { xs: '0 0 16px 0', md: 0 },
            color: { xs: COLORS.WHITE, sm: COLORS.BLACK, md: COLORS.BLACK },
          }}
        >
          {t('recurringForm.frequencyUnit')}
        </Typography>
        <RadioGroup
          {...getFieldProps(recurringPaymentFormFields.frequencyUnit)}
          sx={{
            flexDirection: xs ? 'column' : 'row',
            gap: { xs: '16px', md: '24px' },
            width: { xs: '100%', md: 'auto' },
          }}
          name={recurringPaymentFormFields.frequencyUnit}
        >
          {allowedFrequencyOptions.map((option) => (
            <FormControlLabel
              key={option}
              id={`bank-type-${option}`}
              value={option}
              control={
                <Radio
                  sx={{
                    width: '24px',
                    height: '24px',
                    color: { xs: COLORS.GREEN, md: COLORS.SECONDARY_GREEN },
                    '&.Mui-checked': {
                      color: { xs: COLORS.GREEN, md: COLORS.SECONDARY_GREEN },
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: values[recurringPaymentFormFields.frequencyUnit] === option ? '16px' : '14px',
                    fontWeight: values[recurringPaymentFormFields.frequencyUnit] === option ? 600 : 400,
                    ml: 2,
                    color:
                      values[recurringPaymentFormFields.frequencyUnit] === option
                        ? { xs: COLORS.GREEN, md: COLORS.SECONDARY_GREEN }
                        : { xs: COLORS.WHITE, sm: COLORS.BLACK, md: COLORS.BLACK },
                  }}
                  textAlign="left"
                >
                  {t(`recurringForm.${option}`)}
                </Typography>
              }
              sx={{
                border: `1px solid ${COLORS.XX_LIGHT_GREEN}`,
                borderRadius: 1,
                padding: '12px',
                m: '0 !important',
              }}
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: xs ? 'flex-start' : 'center',
          }}
          style={{
            flexDirection: xs ? 'column' : 'row',
          }}
          variant="outlined"
        >
          <Typography
            variant={isEnglish ? 'bodyMedium' : 'cairoM'}
            textAlign="justify"
            paragraph
            sx={{
              fontSize: '16px',
              marginBottom: 0,
              m: { xs: '0 0 16px 0', md: 0 },
              color: { xs: COLORS.WHITE, sm: COLORS.BLACK, md: COLORS.BLACK },
            }}
          >
            {t('recurringForm.startDate')}
          </Typography>
          <DatePicker
            {...getFieldProps(recurringPaymentFormFields.startDate)}
            value={values[recurringPaymentFormFields.startDate]}
            onChange={(value) => handleDateChange(recurringPaymentFormFields.startDate, value)}
            maxDate={
              values[recurringPaymentFormFields.endDateType] === RecurringEndType.INFINITY
                ? null
                : addDays(values[recurringPaymentFormFields.endDate], -1)
            }
            slotProps={{
              textField: {
                // autoFocus: true,
                id: 'dateFilterFrom',
                name: 'dateFilterFrom',
                helperText: t(errors.startDate as string),
              },
            }}
            sx={{ borderRadius: 2, width: { xs: '100%', sm: '350px', backgroundColor: COLORS.WHITE } }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        flexDirection={xs ? 'column' : 'row'}
        display="flex"
        width="100%"
        alignItems={xs ? 'flex-start' : 'center'}
        justifyContent="space-between"
      >
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoM'}
          color={COLORS.MAIN_DARK}
          textAlign="justify"
          paragraph
          sx={{
            fontSize: '16px',
            marginBottom: 0,
            m: { xs: '0 0 16px 0', md: 0 },
            color: { xs: COLORS.WHITE, sm: COLORS.BLACK, md: COLORS.BLACK },
          }}
        >
          {t('recurringForm.endDate')}
        </Typography>

        <Grid
          alignItems="center"
          display="flex"
          flexDirection={xs ? 'column' : 'row'}
          width={xs ? '100%' : '85%'}
          justifyContent={xs ? 'space-between' : 'flex-end'}
          sx={{ gap: { xs: '16px', md: '24px' } }}
        >
          <RadioGroup
            {...getFieldProps(recurringPaymentFormFields.endDateType)}
            name={recurringPaymentFormFields.endDateType}
            style={{
              width: xs ? '100%' : '60%',
              justifyContent: xs ? 'space-between' : 'flex-end',
              flexDirection: 'row',
              gap: xs ? 0 : '24px',
            }}
          >
            {RecurringEndTypeOptions.map((option) => (
              <FormControlLabel
                key={option}
                id={`bank-type-${option}`}
                value={option}
                control={
                  <Radio
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: { xs: COLORS.GREEN, md: COLORS.SECONDARY_GREEN },
                      '&.Mui-checked': {
                        color: { xs: COLORS.GREEN, md: COLORS.SECONDARY_GREEN },
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: values[recurringPaymentFormFields.frequencyUnit] === option ? '16px' : '14px',
                      fontWeight: values[recurringPaymentFormFields.frequencyUnit] === option ? 600 : 400,
                      ml: 2,
                      color:
                        values[recurringPaymentFormFields.frequencyUnit] === option
                          ? { xs: COLORS.GREEN, md: COLORS.SECONDARY_GREEN }
                          : { xs: COLORS.WHITE, sm: COLORS.BLACK, md: COLORS.BLACK },
                    }}
                    textAlign="left"
                  >
                    {t(`recurringForm.${option}`)}
                  </Typography>
                }
                sx={{
                  border: `1px solid ${COLORS.XX_LIGHT_GREEN}`,
                  borderRadius: 1,
                  padding: '12px',
                  m: '0 !important',
                  width: { xs: '48%', md: 'auto' },
                }}
              />
            ))}
          </RadioGroup>
          <DatePicker
            {...getFieldProps(recurringPaymentFormFields.endDate)}
            disabled={values[recurringPaymentFormFields.endDateType] === RecurringEndType.INFINITY}
            value={values[recurringPaymentFormFields.endDate]}
            onChange={(value) => handleDateChange(recurringPaymentFormFields.endDate, value || null)}
            minDate={addDays(values[recurringPaymentFormFields.startDate], 1)}
            sx={{
              marginTop: 0,
              borderRadius: 2,
              width: { xs: '100%', sm: '350px', backgroundColor: COLORS.WHITE },
            }}
            slotProps={{
              textField: {
                autoFocus: true,
                id: 'dateFilterFrom',
                name: 'dateFilterFrom',
                helperText: t(errors.endDate as string),
              },
            }}
          />
        </Grid>
      </Grid>
      {errorMessage ? (
        <Grid item>
          <Typography
            component="h5"
            variant="body2"
            ref={errRef}
            className={errorMessage ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
            color="error"
          >
            {tApiError(errorMessage)}
          </Typography>
        </Grid>
      ) : null}
      <Grid item container columnSpacing="24px">
        <Grid item xs={12}>
          <MButton
            fullWidth
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={!isValid}
            id="SaveRecurringSettingsButton"
            typographyProps={{
              sx: { color: { xs: isValid ? COLORS.PRIMARY_BLUE : COLORS?.WHITE, sm: COLORS?.WHITE, md: COLORS.WHITE } },
            }}
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: { xs: COLORS?.WHITE, sm: COLORS?.X_DARK_BLUE, mds: COLORS?.X_DARK_BLUE },
                '&:hover': {
                  backgroundColor: { xs: COLORS?.WHITE, sm: COLORS?.X_DARK_BLUE, mds: COLORS?.X_DARK_BLUE },
                },
              },
            }}
          >
            {t('saveSettings')}
          </MButton>
        </Grid>
        <Grid item xs={12} sx={{ mb: { xs: '0px', md: 'unset' }, mt: '16px' }}>
          <MButton
            fullWidth
            variant="text"
            onClick={() => onCloseRecurringSettings && onCloseRecurringSettings(subscription.id)}
            id="save123"
            typographyProps={{ sx: { color: { xs: COLORS.WHITE, sm: COLORS.PRIMARY_BLUE, md: COLORS.PRIMARY_BLUE } } }}
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
              },
            }}
          >
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecurrentBillingForm;
