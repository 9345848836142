import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch } from '../../../redux/store';
import {
  getPortfolioStatisticsSelector,
  getSubscribedPortfolioSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import { StyledTab, StyledTabs, TabPanel } from '../../../shared/CustomTabs';
import TargetAllocationTab from '../PortfolioDetails/TargetAllocationTab';
import { findUserIndexFunds } from '../../../store_deprecated/actions/indexFundActions';
import {
  getUserIndexFundsIsLoadingSelector,
  getUserIndexFundsSelector,
} from '../../../store_deprecated/selectors/indexFundSelectors';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { findPortfolioStatistics } from '../../../store_deprecated/actions/portfolioActions';
import BetaPortfolioPositionsTab from '../PortfolioDetails/BetaPortfolioPositionsTab';
import CompoundingReturnTab from '../PortfolioDetails/CompoundingReturnTab';

const Loader = React.lazy(() => import('../../../shared/Loader'));

const ModeratePortfolio: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [value, setValue] = React.useState(0);

  const subscribedPortfolio = useSelector(getSubscribedPortfolioSelector);
  const { latest: data, isLoading: isLoadingLatestStatistics } = useSelector(getPortfolioStatisticsSelector);
  const userIndexFunds = useSelector(getUserIndexFundsSelector);
  const isLoadingUserIndexFunds = useSelector(getUserIndexFundsIsLoadingSelector);
  const isLoading = subscribedPortfolio.isLoading || isLoadingLatestStatistics || isLoadingUserIndexFunds;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(findPortfolioStatistics()),
          dispatch(fetchLastSurveyResult()),
          dispatch(findUserIndexFunds()),
        ]);
      } catch (e) {
        console.log('Some error', e);
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      sx={{
        maxWidth: '100%!important',
        padding: { xs: '0px!important', md: '32px!important' },
        mt: { xs: '-56px', md: 'auto' },
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%', mt: 12 }}>
          <Box>
            <StyledTabs
              orientation="horizontal"
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <StyledTab label={t('portfolioDetails.portfolioPositionsTabLabel')} key={0} />
              <StyledTab label={t('portfolioDetails.targetAllocationTabLabel')} key={1} />
              <StyledTab label={t('portfolioDetails.сompoundingReturnsTabLabel')} key={2} />
            </StyledTabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BetaPortfolioPositionsTab
              portfolio={subscribedPortfolio}
              cashAllocationPercentage={subscribedPortfolio?.userPortfolios?.[0]?.cacheAllocationPercentage}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TargetAllocationTab
              portfolio={subscribedPortfolio}
              cashAllocationPercentage={subscribedPortfolio?.userPortfolios?.[0]?.cacheAllocationPercentage}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CompoundingReturnTab
              portfolio={subscribedPortfolio}
              latestStatistics={data}
              userIndexFunds={userIndexFunds}
            />
          </TabPanel>
        </Box>
      )}
    </Container>
  );
};

export default ModeratePortfolio;
