import { LOGOUT } from '../types/authTypes';
import {
  ANSWER_QUESTION_FAIL,
  ANSWER_QUESTION_REQUEST,
  ANSWER_QUESTION_SUCCESS,
  ANSWERED_QUESTION_FAIL,
  ANSWERED_QUESTION_REQUEST,
  ANSWERED_QUESTION_SUCCESS,
  CLEAR_SURVEY,
  CONFIG_FAIL,
  CONFIG_REQUEST,
  CONFIG_SUCCESS,
  CURRENT_QUESTION_FAIL,
  CURRENT_QUESTION_REQUEST,
  CURRENT_QUESTION_SUCCESS,
  FINISH_SURVEY_CONTINUE,
  FINISH_SURVEY_FAIL,
  FINISH_SURVEY_REQUEST,
  FINISH_SURVEY_SUCCESS,
  GET_LAST_SURVEY_RESULT_FAIL,
  GET_LAST_SURVEY_RESULT_REQUEST,
  GET_LAST_SURVEY_RESULT_SUCCESS,
  START_SURVEY_FAIL,
  START_SURVEY_REQUEST,
  START_SURVEY_SUCCESS,
  SurveyAction,
  SurveyState,
} from '../types/surveyTypes';

const INITIAL_STATE: SurveyState = {
  questions: {
    data: [],
    isLoading: false,
    isErrorExist: false,
  },
  answers: {
    data: {},
    isLoading: false,
    isErrorExist: false,
  },
  survey: {
    data: { surveyId: 0, totalQuestions: -1, totalSections: -1 },
    isLoading: false,
    isErrorExist: false,
    result: {
      surveyId: 0,
      category: { id: 0, descriptionAr: '', descriptionEn: '', nameEn: '', nameAr: '' },
      score: '',
    },
  },
  config: {
    data: { surveyStatus: '' },
    isLoading: false,
    isErrorExist: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const surveyReducer = (state: SurveyState = INITIAL_STATE, action: SurveyAction = { type: LOGOUT }): SurveyState => {
  switch (action.type) {
    case START_SURVEY_REQUEST:
      return {
        ...state,
        survey: {
          ...state.survey,
          isLoading: true,
        },
      };

    case START_SURVEY_SUCCESS:
      return {
        ...state,
        survey: {
          ...state.survey,
          data: { ...action.payload },
          isLoading: false,
          isErrorExist: false,
        },
      };

    case START_SURVEY_FAIL:
      return {
        ...state,
        survey: {
          ...state.survey,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CONFIG_REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: true,
        },
      };

    case CONFIG_SUCCESS:
      return {
        ...state,
        config: {
          data: { ...action.payload },
          isLoading: false,
          isErrorExist: false,
        },
      };

    case CONFIG_FAIL:
      return {
        ...state,
        config: {
          ...state.config,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CURRENT_QUESTION_REQUEST:
      return {
        ...state,
        questions: {
          ...state.questions,
          isLoading: !action.payload,
        },
      };

    case CURRENT_QUESTION_SUCCESS:
      return {
        ...state,
        questions: {
          data: [...state.questions.data, action.payload],
          isLoading: false,
          isErrorExist: false,
        },
      };

    case CURRENT_QUESTION_FAIL:
      return {
        ...state,
        questions: {
          ...state.questions,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case ANSWER_QUESTION_SUCCESS:
    case ANSWERED_QUESTION_SUCCESS:
      return {
        ...state,
        answers: {
          data: {
            ...state.answers.data,
            [action.payload.questionId]: action.payload,
          },
          isLoading: false,
          isErrorExist: false,
        },
      };

    case ANSWER_QUESTION_REQUEST:
      return {
        ...state,
        answers: {
          ...state.answers,
          data: {
            ...state.answers.data,
            [action.payload.questionId]: action.payload,
          },
          isLoading: !action.payload.silence,
        },
      };

    case ANSWERED_QUESTION_REQUEST:
      return {
        ...state,
        answers: {
          ...state.answers,
          isLoading: !action.payload,
        },
      };

    case ANSWER_QUESTION_FAIL:
    case ANSWERED_QUESTION_FAIL:
      return {
        ...state,
        answers: {
          ...state.answers,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case FINISH_SURVEY_REQUEST:
      return {
        ...state,
        survey: {
          ...state.survey,
          isLoading: true,
        },
      };

    case FINISH_SURVEY_SUCCESS:
      return {
        ...state,
        survey: {
          ...state.survey,
          isErrorExist: false,
          isLoading: false,
          result: { ...action.payload },
        },
      };

    case FINISH_SURVEY_CONTINUE:
      return {
        ...state,
        survey: {
          ...state.survey,
          isLoading: false,
        },
      };

    case FINISH_SURVEY_FAIL:
      return {
        ...state,
        survey: {
          ...state.survey,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_LAST_SURVEY_RESULT_REQUEST:
      return {
        ...state,
        survey: {
          ...state.survey,
          isLoading: true,
        },
      };

    case GET_LAST_SURVEY_RESULT_SUCCESS:
      return {
        ...state,
        survey: {
          ...state.survey,
          isLoading: false,
          isErrorExist: false,
          result: action.payload ? { ...action.payload } : INITIAL_STATE.survey.result,
        },
      };

    case GET_LAST_SURVEY_RESULT_FAIL:
      return {
        ...state,
        survey: {
          ...state.survey,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CLEAR_SURVEY:
      return {
        ...state,
        config: INITIAL_STATE.config,
        survey: action.payload
          ? INITIAL_STATE.survey
          : {
              ...INITIAL_STATE.survey,
              result: state.survey.result,
            },
      };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default surveyReducer;
