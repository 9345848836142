import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { userPreferenceService } from '../../../api';
import { SaveUserPreferenceRequest } from '../../../types/userPreference';

export const fetchUserPreferenceByType = createAsyncThunk(
  'user-preference/fetch-all',
  (type: string, { rejectWithValue }) =>
    userPreferenceService.fetchUserPreferenceByType(type).catch((error) => rejectWithValue(error)),
);

export const saveUserPreference = createAsyncThunk(
  'user-preference/save',
  (data: SaveUserPreferenceRequest, { rejectWithValue }) =>
    userPreferenceService.saveUserPreference(data).catch((error) => rejectWithValue(error)),
);

export const cleanUserPreferences = createAction('user-preference/clean');
