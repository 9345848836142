import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import COLORS from '../constants/colors';
import { madkhoulBankInfoSelector } from '../store_deprecated/selectors/walletSelectors';
import MButton from './MButton';
import FundAmountField from '../components/User/Education/FundAmountField';
import RequestInvestSuccessfulScreen from '../components/User/Invest/RequestInvestSuccessfulScreen';
import Deposit, { DepositProps } from '../components/User/MyWallet/Deposit';
import { ar2En, formatNumber, round2Format } from '../utils/number';
import { formatValue } from '../helpers/string';
import { addMoreFundToPortfolio, resetSubscribedToPortfolio } from '../store_deprecated/actions/portfolioActions';
import { SubscribedPortfolio } from '../store_deprecated/types/portfolioTypes';
import { useTypedDispatch } from '../redux/hooks';
import { getAddMoreFundToPortfolioSelector } from '../store_deprecated/selectors/portfolioSelectors';

interface AddMoreFundDialogProps {
  open: boolean;
  showSuccessfulScreen?: boolean;
  closeDialog: (success?: boolean) => void;
  onSuccessButtonClick?: () => void;
  depositConfig: DepositProps | null;
  subscribedPortfolio?: SubscribedPortfolio;
}

const minSubscription = 1000;

export default function AddMoreFundDialog(props: AddMoreFundDialogProps) {
  const {
    open,
    showSuccessfulScreen = false,
    closeDialog = () => {},
    onSuccessButtonClick = () => {},
    depositConfig,
    subscribedPortfolio,
  } = props;
  const dispatch = useTypedDispatch();
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const [fundAmount, setFundAmount] = useState('');
  const isFundAmountError = Boolean(fundAmount) && +fundAmount < minSubscription;
  const isArabic = i18n.language === 'ar';

  const bankItem = useSelector(madkhoulBankInfoSelector);
  const addMoreFundToPortfolioData = useSelector(getAddMoreFundToPortfolioSelector);
  const error = tApiError(addMoreFundToPortfolioData.error);
  const { isLoading } = addMoreFundToPortfolioData;

  useEffect(() => () => setFundAmount(''), [open]);

  const onConfirm = async () => {
    const amount = +fundAmount.replace(/\D/g, '');
    console.log('MODERATE');
    if (fundAmount && subscribedPortfolio) {
      const result = (await dispatch(
        addMoreFundToPortfolio({
          portfolioId: subscribedPortfolio.id,
          fundAmount: amount,
        }),
      )) as any;

      if (result) {
        closeDialog(true);
      }
    } else {
      closeDialog(false);
      dispatch(resetSubscribedToPortfolio());
    }
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    setFundAmount('');
    return closeDialog(!showSuccessfulScreen);
  };

  const parseValue = (v: string | number) =>
    `${v}`
      // eslint-disable-next-line no-useless-escape
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleFieldChange = (event: any) => {
    const initialValue = event.target.value;

    const newValue: string = parseValue(ar2En(initialValue));
    const newValueNumber = +newValue;

    // setValue(newValue);

    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;

      setFundAmount(event.target.value);

      return;
    }

    event.target.value = `${newValueNumber}`;

    setFundAmount(event.target.value);
  };

  const renderContent = () => (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowSpacing="48px"
      padding="0px 24px"
    >
      <Grid item textAlign="right" alignSelf="end">
        <IconButton onClick={handleClose}>
          <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          paddingTop: { xs: '24px !important', md: '48px !important' },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '24px', md: '44px' },
            lineHeight: { xs: '32px', md: '48px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('addMoreFundDialog.title')}
        </Typography>
      </Grid>
      {/* <Grid
            item
            textAlign="center"
            sx={{
              paddingTop: { xs: "24px !important", md: "48px !important" },
            }}
          >
            <Typography
              variant="bodyLarge"
              sx={{
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "28px",
                color: COLORS.DARK_GREY,
              }}
            >
              {t("addMoreFundDialog.description")}
            </Typography>
          </Grid> */}
      <Grid item container flexDirection="row" justifyContent="space-around" alignItems="center">
        <Typography
          variant="bodyMedium"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('addMoreFundDialog.currentFund')}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '28px',
            color: COLORS.PRIMARY_BLUE,
          }}
        >
          {t('addMoreFundDialog.nPerMonth', {
            val: round2Format(Number(bankItem?.userBankDetails?.amount) || 0, i18n.language, true),
          })}
        </Typography>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <FundAmountField
          isArabic={isArabic}
          value={formatValue(isArabic, fundAmount.toString())}
          handleChange={handleFieldChange}
        />
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('addMoreFundDialog.note', { val: '1,000' })}
        </Typography>
      </Grid>
      {(isLoading || !!error || isFundAmountError) && (
        <Grid item>
          {isLoading && <CircularProgress />}
          {(!!error || isFundAmountError) && (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.RED,
              }}
            >
              {error ||
                tApiError('FUND_AMOUNT_LESS_THAN_MINIMUM_SUBSCRIPTION_AMOUNT', {
                  val: formatNumber(minSubscription, i18n.language, 2),
                })}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item container rowSpacing="24px">
        <Grid item width="100%">
          <MButton
            id="ConfirmAddMoreFundButton"
            variant="contained"
            onClick={onConfirm}
            disabled={!fundAmount || isFundAmountError || isLoading}
            fullWidth
            buttonProps={{
              sx: {
                backgroundColor: COLORS.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
              },
            }}
          >
            {t('addMoreFundDialog.confirmButtonTitle')}
          </MButton>
        </Grid>
        {depositConfig ? (
          <Grid item container columnSpacing="24px">
            <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' } }}>
              <Deposit {...depositConfig} page="dashboard" paddingTop={0} />
            </Grid>
          </Grid>
        ) : null}
        <Grid item width="100%">
          <MButton variant="outlined" onClick={handleClose} disabled={isLoading} fullWidth>
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ padding: '32px 24px' }}>
        {!showSuccessfulScreen ? (
          renderContent()
        ) : (
          <RequestInvestSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />
        )}
      </Box>
    </Dialog>
  );
}
