import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TutorialView from '../../components/User/Tutorials/TutorialView';

const Tutorials: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: { xs: '0 24px', md: '32px' },
        mt: { xs: '0', md: 'auto' },
        minHeight: '70vh',
      }}
    >
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid
          item
          sx={
            {
              // pl: { xs: "0px !important", sm: "24px !important" },
            }
          }
        >
          <Typography component="h1" variant="h5">
            {t('tutorials.title')}
          </Typography>
        </Grid>
        <Grid item mt="32px">
          <TutorialView />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tutorials;
