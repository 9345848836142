import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash.debounce';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';
import COLORS from '../../constants/colors';
import { addBankAccountFormSchema } from '../../forms/validationSchema/formSchema';
import { addBankAccountFormFields } from '../../forms/fields/formFields';
import { useTypedDispatch } from '../../redux/store';
import {
  banksIsLoadingSelector,
  banksSelector,
  editBankInfoSelector,
  getEditBankInfoIsLoading,
} from '../../store_deprecated/selectors/walletSelectors';
import {
  Bank,
  BankType,
  SaveBankInfoPayload,
  SaveBankInfoSuccessPayload,
} from '../../store_deprecated/types/walletTypes';
import {
  fetchEditBankInfo,
  fetchBanks,
  saveBankInfo,
  clearEditBankInfo,
} from '../../store_deprecated/actions/walletActions';
import Breadcrumb from '../../shared/Breadcrumb';
import { locations } from '../../routes/locations';
import MButton from '../../shared/MButton';

const AddEditBankAccount: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const id = Number(useParams().id);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const errRef = useRef<any>();
  const [errorMessage, setErrorMessage] = useState('');
  const isArabic = i18n.language === 'ar';

  const editBankInfo = useSelector(editBankInfoSelector);

  const createBankAccount = async (payload: SaveBankInfoPayload) => {
    try {
      const result: SaveBankInfoSuccessPayload = (await dispatch(saveBankInfo(payload))) as any;

      if (result.id) {
        navigate(locations.myWallet(), { replace: true });
      }
    } catch (e: any) {
      setErrorMessage(e.message);
    }
  };

  const {
    handleSubmit,
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    resetForm,
    getFieldProps,
    validateForm,
    handleChange,
  } = useFormik({
    initialValues: {
      [addBankAccountFormFields.bankType]: 0,
      [addBankAccountFormFields.bankName]: '',
      [addBankAccountFormFields.fullName]: '',
      [addBankAccountFormFields.accountNumber]: '',
      [addBankAccountFormFields.ibanNumber]: '',
      [addBankAccountFormFields.swiftCode]: '',
    },
    validationSchema: addBankAccountFormSchema,
    onSubmit: (formValues) => {
      const payload: SaveBankInfoPayload = {
        id,
        iban: formValues[addBankAccountFormFields.ibanNumber],
        swiftCode: formValues[addBankAccountFormFields.swiftCode],
        isPrimaryAccount: false,
        bankType: { type: BankType.OTHER },
        userBankDetails: {
          id: editBankInfo?.userBankDetails?.id,
          amount: 0,
          hold: 0,
          cashAllocationValue: 0,
          isLocal: +formValues[addBankAccountFormFields.bankType] === 0,
          accountNumber: formValues[addBankAccountFormFields.accountNumber],
          iban: formValues[addBankAccountFormFields.ibanNumber],
          swiftCode: formValues[addBankAccountFormFields.swiftCode],
          beneficiaryName: formValues[addBankAccountFormFields.fullName],
          beneficiaryBankName: formValues[addBankAccountFormFields.bankName],
          beneficiaryAddress1: '',
          beneficiaryAddress2: '',
        },
      };

      createBankAccount(payload);
    },
  });

  const fillAccountNumber = () => {
    if (+values[addBankAccountFormFields.bankType] === 0) {
      if (
        values[addBankAccountFormFields.ibanNumber].length > 1 &&
        (!(touched.ibanNumber && !!errors.ibanNumber) || touched.ibanNumber)
      ) {
        setFieldValue(addBankAccountFormFields.accountNumber, values[addBankAccountFormFields.ibanNumber].slice(-15));
      } else setFieldValue(addBankAccountFormFields.accountNumber, '');
    } else if (!touched.ibanNumber) {
      setFieldValue(addBankAccountFormFields.accountNumber, values[addBankAccountFormFields.accountNumber]);
    } else setFieldValue(addBankAccountFormFields.accountNumber, '');
  };

  useEffect(() => {
    fillAccountNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    touched.ibanNumber,
    errors.ibanNumber,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[addBankAccountFormFields.ibanNumber],
  ]);

  useEffect(() => {
    const bankType = values[addBankAccountFormFields.bankType];
    resetForm();
    setFieldValue(addBankAccountFormFields.bankType, bankType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[addBankAccountFormFields.bankType],
  ]);

  useEffect(() => {
    if (values[addBankAccountFormFields.ibanNumber] === '') setFieldValue(addBankAccountFormFields.accountNumber, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[addBankAccountFormFields.ibanNumber],
  ]);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[addBankAccountFormFields.bankName],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[addBankAccountFormFields.swiftCode],
  ]);

  const banks = useSelector(banksSelector);
  const banksLoading = useSelector(banksIsLoadingSelector);
  const editBankInfoLoading = useSelector(getEditBankInfoIsLoading);
  const isLoading = banksLoading || editBankInfoLoading;

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      onClick={() => navigate(locations.myWallet())}
      sx={{ color: COLORS.LIGHT_GRAY, cursor: 'pointer' }}
    >
      {t('breadcrumb.myWallet')}
    </Link>,
    <Typography key="2" color="text.primary">
      {t('breadcrumb.addBankAccount')}
    </Typography>,
  ];

  const onCancel = () => {
    navigate(locations.myWallet(), { replace: true });
  };

  const renderTextField = (params: TextFieldProps) => (
    <Grid item>
      <Typography
        variant={isArabic ? 'cairoM' : 'bodyMedium'}
        color={COLORS.MAIN_DARK}
        textAlign="justify"
        paragraph
        sx={{ marginBottom: '4px' }}
      >
        {t('bankName')}
      </Typography>
      <TextField
        required
        margin="none"
        id={addBankAccountFormFields.bankName}
        name={addBankAccountFormFields.bankName}
        value={values[addBankAccountFormFields.bankName]}
        error={touched.bankName && !!errors.bankName}
        helperText={touched.bankName && t(errors.bankName as string)}
        placeholder={t('selectYourBankName')}
        {...params}
      />
    </Grid>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAutocompleteSearch = useCallback(
    _debounce((_, searchValue) => {
      if (searchValue) {
        dispatch(fetchBanks(searchValue));
      }
    }),
    [],
  );

  const onAutocompleteChange = (_: SyntheticEvent, newValue: Bank | null) => {
    setFieldValue(addBankAccountFormFields.bankName, newValue ? newValue?.bankName : '');
    setFieldValue(addBankAccountFormFields.swiftCode, newValue ? newValue?.bic : '');
  };

  const onResetForm = () => {
    if (editBankInfo) {
      onAutocompleteSearch(null, editBankInfo.userBankDetails.beneficiaryName);
      resetForm({
        values: {
          [addBankAccountFormFields.bankType]: editBankInfo.userBankDetails.isLocal ? 0 : 1,
          [addBankAccountFormFields.bankName]: editBankInfo.userBankDetails.beneficiaryBankName,
          [addBankAccountFormFields.fullName]: editBankInfo.userBankDetails.beneficiaryName,
          [addBankAccountFormFields.ibanNumber]: editBankInfo.iban,
          [addBankAccountFormFields.accountNumber]: editBankInfo.userBankDetails.accountNumber,
          [addBankAccountFormFields.swiftCode]: editBankInfo.swiftCode,
        },
      });
    } else {
      resetForm();
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchEditBankInfo(id));
    }

    dispatch(fetchBanks(''));

    return () => {
      dispatch(clearEditBankInfo());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onResetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editBankInfo]);

  return (
    <Box
      sx={{
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        mt: { md: 'auto' },
      }}
    >
      <Grid container display="flex" flexDirection="column">
        <Grid item>
          <Breadcrumb breadcrumbs={breadcrumbs} />
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="column"
          component={Paper}
          elevation={0}
          sx={{ borderRadius: '12px', padding: '32px', mt: '30px' }}
        >
          <Typography
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 500,
              fontSize: 'clamp(1rem,1vw + 1rem,1.5rem)',
              lineHeight: '32px',
              pb: '32px',
            }}
            variant="h6"
          >
            {t('accountDetails')}
          </Typography>

          <Grid component="form" item container rowSpacing="32px" flexDirection="column" xs={12} md={6} noValidate>
            <Grid item>
              <RadioGroup {...getFieldProps(addBankAccountFormFields.bankType)}>
                {/* TODO: Disabled International bank by request from Saad */}
                {/* {["localSaudiBank", "internationalBank"].map((option, i) => ( */}
                {['localSaudiBank'].map((option, i) => (
                  <FormControlLabel
                    key={option}
                    id={`bank-type-${i + 1}`}
                    value={i}
                    control={
                      <Radio
                        sx={{
                          color: COLORS.SECONDARY_GREEN,
                          '&.Mui-checked': {
                            color: COLORS.SECONDARY_GREEN,
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
                          color:
                            +values[addBankAccountFormFields.bankType] === +i
                              ? COLORS.SECONDARY_GREEN
                              : COLORS.MAIN_DARK,
                        }}
                        textAlign="left"
                      >
                        {t(option)}
                      </Typography>
                    }
                    sx={{
                      border: `1px solid ${COLORS.XX_LIGHT_GREEN}`,
                      borderRadius: 1,
                      marginBottom: 3,
                      marginLeft: 0,
                      marginRight: 0,
                      padding: '4px 3px',
                    }}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item>
              {+values[addBankAccountFormFields.bankType] === 0 ? (
                <Autocomplete
                  fullWidth
                  title={t('bankName')}
                  options={banksLoading ? [] : banks}
                  renderInput={renderTextField}
                  onInputChange={onAutocompleteSearch}
                  onChange={onAutocompleteChange}
                  getOptionLabel={(bank) => (isArabic ? bank.bankNameAr : bank.bankName)}
                  value={
                    banks.find((bank) =>
                      [bank.bankName, bank.bankNameAr].includes(values[addBankAccountFormFields.bankName]),
                    ) || null
                  }
                  isOptionEqualToValue={(bank, value) => bank.id === value.id}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {isArabic ? option.bankNameAr : option.bankName} ({option.bic})
                    </li>
                  )}
                  loading={banksLoading}
                />
              ) : (
                renderTextField({
                  fullWidth: true,
                  placeholder: t('enterYourBankName'),
                  onChange: (e) => {
                    setFieldValue(addBankAccountFormFields.bankName, e.target.value);
                  },
                })
              )}
            </Grid>
            <Grid item>
              <Typography
                variant={isArabic ? 'cairoM' : 'bodyMedium'}
                color={COLORS.MAIN_DARK}
                textAlign="justify"
                paragraph
                sx={{ marginBottom: '4px' }}
              >
                {t('fullName')}
              </Typography>
              <TextField
                fullWidth
                required
                margin="none"
                variant="outlined"
                error={touched.fullName && !!errors.fullName}
                helperText={touched.fullName && t(errors.fullName as string)}
                autoComplete="fullName"
                placeholder={t('enterFullName')}
                {...getFieldProps(addBankAccountFormFields.fullName)}
                InputProps={{
                  sx: {
                    input: {
                      '::-webkit-input-placeholder': {
                        color: '#D1D5DB !important',
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant={isArabic ? 'cairoM' : 'bodyMedium'}
                color={COLORS.MAIN_DARK}
                textAlign="justify"
                paragraph
                sx={{ marginBottom: '4px' }}
              >
                {t('bankAccountIbanNumber')}
              </Typography>
              <TextField
                fullWidth
                required
                margin="none"
                variant="outlined"
                error={touched.ibanNumber && !!errors.ibanNumber}
                helperText={touched.ibanNumber && t(errors.ibanNumber as string)}
                autoComplete="ibanNumber"
                placeholder="SA4420000001234567891234"
                {...getFieldProps(addBankAccountFormFields.ibanNumber)}
                onChange={(e) => {
                  e.target.value = e.target.value?.replace(/\s/g, '');

                  handleChange(e);
                }}
                InputProps={{
                  sx: {
                    input: {
                      '::-webkit-input-placeholder': {
                        color: '#D1D5DB !important',
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant={isArabic ? 'cairoM' : 'bodyMedium'}
                color={COLORS.MAIN_DARK}
                textAlign="justify"
                paragraph
                sx={{ marginBottom: '4px' }}
              >
                {t('accountNumber')}
              </Typography>
              <TextField
                fullWidth
                required
                margin="none"
                disabled={+values[addBankAccountFormFields.bankType] === 0}
                variant="outlined"
                error={touched.accountNumber && !!errors.accountNumber}
                helperText={touched.accountNumber && t(errors.accountNumber as string)}
                autoComplete="accountNumber"
                placeholder={t('enterAccountNumber')}
                {...getFieldProps(addBankAccountFormFields.accountNumber)}
              />
            </Grid>
            <Grid item hidden={+values[addBankAccountFormFields.bankType] === 0}>
              <Typography
                variant={isArabic ? 'cairoM' : 'bodyMedium'}
                color={COLORS.MAIN_DARK}
                textAlign="justify"
                paragraph
                sx={{ marginBottom: '4px' }}
              >
                {t('swiftCode')}
              </Typography>
              <TextField
                fullWidth
                required
                margin="none"
                variant="outlined"
                error={touched.swiftCode && !!errors.swiftCode}
                helperText={touched.swiftCode && t(errors.swiftCode as string)}
                autoComplete="swiftCode"
                placeholder="AAAA-BB-CC-123"
                {...getFieldProps(addBankAccountFormFields.swiftCode)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            component="h5"
            variant="body2"
            ref={errRef}
            className={errorMessage ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
            color="error"
            sx={{ mb: 4 }}
          >
            {tApiError(errorMessage)}
          </Typography>
        </Grid>
        <Grid item>
          <MButton
            fullWidth
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={!isValid || isLoading}
            id="SaveAccountButton"
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: COLORS?.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              },
            }}
          >
            {t('saveAccount')}
          </MButton>
        </Grid>
        <Grid item sx={{ mt: '16px' }}>
          <MButton
            variant="text"
            onClick={onCancel}
            id="CancelButton"
            fullWidth
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
              },
            }}
          >
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditBankAccount;
