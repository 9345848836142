import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useFormik } from 'formik';
import { locations } from '../../../routes/locations';
import COLORS from '../../../constants/colors';
import Breadcrumb from '../../../shared/Breadcrumb';
import { useTypedDispatch } from '../../../redux/store';
import {
  getRedemptionSelector,
  getSubscribedPortfolioIsLoadingSelector,
  getSubscribedPortfolioSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import { redemptionFormFields } from '../../../forms/fields/formFields';
import { dynamicValidationValues, getRedemptionFormSchema } from '../../../forms/validationSchema/formSchema';
import {
  RedemptionPayload,
  SubscribedPortfolio,
  SubscribedPortfolioStatus,
  SubscriptionStatus,
} from '../../../store_deprecated/types/portfolioTypes';
import { redeemPortfolio } from '../../../store_deprecated/actions/portfolioActions';
import MButton from '../../../shared/MButton';
import {
  getUserIndexFundsIsLoadingSelector,
  getUserIndexFundsSelector,
} from '../../../store_deprecated/selectors/indexFundSelectors';
import { findUserIndexFunds } from '../../../store_deprecated/actions/indexFundActions';
import { ar2En, formatNumber } from '../../../utils/number';
import { formatValue } from '../../../helpers/string';
import RequestInvestSuccessfulScreen from '../Invest/RequestInvestSuccessfulScreen';

type CustomizedState = {
  from?: string;
};

const RedemptionView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const [validationSchema, setValidationSchema] = React.useState(getRedemptionFormSchema());
  const [openSuccessfulDialog, setOpenSuccessfulDialog] = React.useState(false);
  const [amountDisabled, setAmountDisabled] = React.useState(false);
  const [marketValue, setMarketValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(0);
  const state = location.state as CustomizedState;
  const isArabic = i18n.language === 'ar';

  const subscribedPortfolio = useSelector(getSubscribedPortfolioSelector);
  const redemption = useSelector(getRedemptionSelector);
  const isSubPortfolioLoading = useSelector(getSubscribedPortfolioIsLoadingSelector);
  const isUserIndexFundsLoading = useSelector(getUserIndexFundsIsLoadingSelector);
  const isLoading = isSubPortfolioLoading || isUserIndexFundsLoading || redemption.isLoading;
  const userIndexFunds = useSelector(getUserIndexFundsSelector);

  const makeRedeemPortfolio = async (payload: RedemptionPayload) => {
    try {
      const response = await dispatch(redeemPortfolio(payload));

      if (response) {
        setOpenSuccessfulDialog(true);
      }
    } catch (e: any) {
      console.error('redeemPortfolio error', e.message);
    }
  };

  const { handleSubmit, values, touched, errors, isValid, getFieldProps, setFieldValue } = useFormik({
    initialValues: {
      [redemptionFormFields.amount]: 0,
      [redemptionFormFields.reason]: '',
      [redemptionFormFields.redeemAll]: false,
    },
    validationSchema,
    initialTouched: { reason: true },
    onSubmit: (formValues) => {
      const payload: RedemptionPayload = {
        subscribedPortfolioId: subscribedPortfolio.id,
        ...formValues,
        amount: formValues.redeemAll ? 0 : +formValues.amount,
      };

      makeRedeemPortfolio(payload);
    },
  });

  useEffect(() => {
    const currentValue = userIndexFunds.reduce(
      (res, item) => res + item.indexFund.netAssetValuePerUnit * (item.numOfUnits || 0),
      0,
    );

    let userPortfolios: SubscribedPortfolio['userPortfolios'] = [];

    if (subscribedPortfolio?.status?.name === SubscribedPortfolioStatus.ACCEPTED) {
      userPortfolios = subscribedPortfolio.userPortfolios || [];
    }

    const cashValue = userPortfolios.reduce((res, up) => {
      const uniqStatuses = [...new Set(up?.userPortfolioRequests?.map((upr) => upr.status.name))];

      if (uniqStatuses.length === 1 && uniqStatuses[0] === SubscriptionStatus.APPROVED) {
        return res + up.cacheAllocationValue;
      }

      return res;
    }, 0);

    setMarketValue(currentValue);
    setMaxValue(currentValue + cashValue);

    dynamicValidationValues.redemptionMaxAmount = currentValue + cashValue;

    setValidationSchema(getRedemptionFormSchema());
  }, [userIndexFunds, subscribedPortfolio]);

  useEffect(() => {
    if (!subscribedPortfolio?.id) {
      navigate(locations.dashboard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedPortfolio]);

  useEffect(() => {
    dispatch(findUserIndexFunds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDialog = () => {
    setOpenSuccessfulDialog(false);
  };

  const onSuccessButtonClick = () => {
    closeDialog();
    navigate(locations.orders());
  };

  const onCancel = () => {
    const backUrl = state?.from ? state?.from : locations.dashboard();

    navigate(backUrl);
  };

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      onClick={() => navigate(locations.dashboard())}
      sx={{ color: COLORS.LIGHT_GRAY, cursor: 'pointer' }}
    >
      {t('breadcrumb.dashboard')}
    </Link>,
    <Typography key="2" color="text.primary">
      {/* // @ts-ignore */}
      {t('breadcrumb.redemption')}
    </Typography>,
  ];

  const formatUnit = (unit: string) => (i18n.exists(`unit.${unit}`) ? t(`unit.${unit}`) : unit);

  const parseValue = (v: string | number) =>
    `${v}`
      // eslint-disable-next-line no-useless-escape
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const initialValue = event.target.value;

    const newValue: string = parseValue(ar2En(initialValue));
    let newValueNumber = +newValue;

    // setValue(newValue);

    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;

      setFieldValue(redemptionFormFields.amount, event.target.value);

      return;
    }

    if (newValueNumber > maxValue) {
      newValueNumber = maxValue;
    }

    event.target.value = `${newValueNumber}`;

    // setValue(event.target.value);

    setFieldValue(redemptionFormFields.amount, event.target.value);
  };

  useEffect(() => {
    if (+values[redemptionFormFields.amount] > marketValue) {
      setFieldValue(redemptionFormFields.redeemAll, true);

      setAmountDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[redemptionFormFields.amount]]);

  useEffect(() => {
    if (+values[redemptionFormFields.amount] > marketValue && !values[redemptionFormFields.redeemAll]) {
      setFieldValue(redemptionFormFields.amount, marketValue);
    }

    if (values[redemptionFormFields.redeemAll]) {
      setFieldValue(redemptionFormFields.amount, maxValue);
    }

    setAmountDisabled(values[redemptionFormFields.redeemAll]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[redemptionFormFields.redeemAll]]);

  return (
    <Box
      sx={{
        padding: '32px',
        mt: { xs: '-56px', md: 'auto' },
        minHeight: '70vh',
      }}
    >
      <Grid item>
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid
        container
        flexDirection="column"
        justifyContent="space-between"
        rowSpacing={8}
        component={Paper}
        elevation={0}
        sx={{ paddingX: 8, paddingBottom: 8, borderRadius: 6, mt: 7 }}
      >
        <Grid item>
          <Typography component="h1" variant="h5" sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
            {t('redemption.title')}
          </Typography>
        </Grid>
        <Grid item>
          <Alert
            icon={<ErrorOutlineIcon fontSize="inherit" />}
            sx={{
              padding: '12px 24px',
              borderRadius: '6px',
              color: COLORS.MAIN_DARK,
              '& .MuiAlert-message, & .MuiAlert-icon': {
                p: 0,
                overflow: 'hidden',
              },
              '& .MuiAlert-message': {
                width: { xs: '90%', md: '100%' },
              },
            }}
            severity="warning"
          >
            <Typography variant="bodyMedium">{t('redemption.warning')}</Typography>
          </Alert>
        </Grid>
        {redemption.error && (
          <Grid item>
            <Alert
              icon={<ErrorOutlineIcon fontSize="inherit" />}
              sx={{
                padding: '12px 24px',
                borderRadius: '6px',
                color: COLORS.MAIN_DARK,
                '& .MuiAlert-message, & .MuiAlert-icon': {
                  p: 0,
                  overflow: 'hidden',
                },
                '& .MuiAlert-message': {
                  width: { xs: '90%', md: '100%' },
                },
              }}
              severity="error"
            >
              <Typography variant="bodyMedium">{tApiError(redemption.error)}</Typography>
            </Alert>
          </Grid>
        )}
        <Grid item container>
          <Grid item container xs={12} sm={6} display="flex" flexDirection="column">
            <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
              {t('redemption.product')}
              <span>&nbsp;</span>
              <Typography variant="bodyLargeM" sx={{ color: COLORS.MAIN_DARK }}>
                {isArabic ? subscribedPortfolio.nameAr : subscribedPortfolio.nameEn}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant={isArabic ? 'cairoM' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="justify"
            paragraph
            sx={{ marginBottom: 1 }}
          >
            {t('redemption.amountLabel', {
              max: formatNumber(maxValue, i18n.language, 2),
            })}
          </Typography>
          <OutlinedInput
            fullWidth
            required
            margin="none"
            type="text"
            endAdornment={
              <InputAdornment position="end" sx={{ fontSize: '20px' }}>
                {formatUnit('SAR')}
              </InputAdornment>
            }
            disabled={amountDisabled}
            inputProps={{ min: 0, max: maxValue }}
            error={touched.amount && !!errors.amount}
            autoComplete="redemption-amount"
            placeholder={t('redemption.amountPlaceholder')}
            id={redemptionFormFields.amount}
            name={redemptionFormFields.amount}
            value={formatValue(isArabic, (values[redemptionFormFields.amount] || '').toString()).replace(/٬/g, ',')}
            onChange={handleFieldChange}
          />
          <FormHelperText error={touched.amount && !!errors.amount} sx={{ margin: '3px 14px 0 14px' }}>
            {touched.amount &&
              `${t(errors.amount as string, {
                value: formatValue(isArabic, +values[redemptionFormFields.amount] < 1000 ? '1000' : ''),
              })} ${+values[redemptionFormFields.amount] < 1000 ? formatUnit('SAR') : ''}`}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant={isArabic ? 'cairoM' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="justify"
            paragraph
            sx={{ marginBottom: 1 }}
          >
            {t('redemption.reasonLabel')}
          </Typography>
          <TextField
            fullWidth
            required
            multiline
            margin="none"
            variant="outlined"
            type="text"
            InputProps={{ inputProps: { maxLength: 250 } }}
            error={touched.reason && !!errors.reason}
            helperText={touched.reason && t(errors.reason as string)}
            autoComplete="redemption-reason"
            placeholder={t('redemption.reasonPlaceholder')}
            id={redemptionFormFields.reason}
            {...getFieldProps(redemptionFormFields.reason)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            label={t('redemption.redeemAllLabel')}
            labelPlacement="end"
            componentsProps={{ typography: { variant: 'bodyLargeM' } }}
            id={redemptionFormFields.redeemAll}
            {...getFieldProps(redemptionFormFields.redeemAll)}
            control={
              <Switch
                checked={values[redemptionFormFields.redeemAll]}
                {...getFieldProps(redemptionFormFields.redeemAll)}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid item sx={{ mt: 7 }} textAlign="center">
        <Typography variant="bodyLarge" sx={{ color: COLORS.LIGHT_GRAY }}>
          {t('redemption.note')}
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 4 }}>
        <MButton
          fullWidth
          variant="contained"
          onClick={() => handleSubmit()}
          disabled={!isValid || isLoading}
          id="ConfirmWithdrawalButton"
          buttonProps={{
            sx: {
              width: { xs: '100%', md: '100%' },
              height: { xs: '50px', md: 60 },
              borderRadius: '80px',
              boxShadow: 'none',
              backgroundColor: COLORS.X_DARK_BLUE,
              '&:hover': {
                backgroundColor: COLORS.X_DARK_BLUE,
              },
            },
          }}
        >
          {t('redemption.confirmWithdrawal')}
        </MButton>
      </Grid>
      <Grid item sx={{ mt: 4 }}>
        <MButton
          variant="text"
          onClick={onCancel}
          id="CancelButton"
          fullWidth
          buttonProps={{
            sx: {
              width: { xs: '100%', md: '100%' },
              height: { xs: '50px', md: 60 },
              borderRadius: '80px',
              boxShadow: 'none',
            },
          }}
        >
          {t('cancel')}
        </MButton>
      </Grid>

      <Dialog open={openSuccessfulDialog} onClose={closeDialog}>
        <Box sx={{ padding: '32px 24px' }}>
          <RequestInvestSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default RedemptionView;
