import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type * as CSS from 'csstype';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PopupForm from '../components/User/Footer/PopupForm';
import { toggleEmailUsPopup } from '../store_deprecated/actions/appActions';
import { getEmailUpVisibilityStatus } from '../store_deprecated/selectors/appSelectors';
import SOCIAL_MEDIA from '../assets/social-media.json';
import COLORS from '../constants/colors';
import MButton from './MButton';

interface EmailUsButtonProps {
  isEmailUpVisible: boolean;
  emailUsText: string;
  handleShowEmailUsPopup: () => void;
}

const EmailUsButton: React.FC<EmailUsButtonProps> = ({ isEmailUpVisible, emailUsText, handleShowEmailUsPopup }) => (
  <Box
    sx={{
      position: 'relative',
      margin: { xs: 'auto', md: 'unset' },
      width: { xs: '100%', md: 'fit-content' },
      textAlign: { xs: 'center', md: 'right' },
    }}
  >
    <PopupForm showEmailUsPopup={isEmailUpVisible} handleShowEmailUsPopup={handleShowEmailUsPopup} />
    <MButton
      variant="contained"
      id="EmailUsButton"
      buttonProps={{
        startIcon: <MailOutlineIcon />,
        sx: {
          backgroundColor: COLORS.X_DARK_BLUE,
          width: 160,
          height: 56,
          padding: '20px 25px',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: COLORS.X_DARK_BLUE,
          },
        },
      }}
      typographyProps={{
        variant: 'bodyMediumB',
        textAlign: 'center',
        color: 'white',
        fontSize: '14px',
      }}
      onClick={handleShowEmailUsPopup}
    >
      {emailUsText}
    </MButton>
  </Box>
);

interface FooterProps {
  backgroundColor?: CSS.Property.BackgroundColor;
}

const Footer: React.FC<FooterProps> = memo(({ backgroundColor = 'transparent' }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const isEmailUpVisible = useSelector(getEmailUpVisibilityStatus);
  const isArabic = i18n.language === 'ar';

  const goTo = (url: string) => {
    window.open(url, '_blank');
  };

  const handleShowEmailUsPopup = () => {
    dispatch(toggleEmailUsPopup());
  };

  useEffect(() => {
    if (isEmailUpVisible) handleShowEmailUsPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box
      sx={{
        padding: {
          xs:
            location.pathname === '/account-opening-agreement' || location.pathname === '/terms-and-conditions'
              ? '32px 0 32px'
              : '24px ',
          md: '32px 64px 32px',
        },
        '@media screen and (orientation: landscape)': {
          padding: { xs: '50px 16px 32px', md: '32px 64px 32px' },
        },
        backgroundColor,
      }}
    >
      <Box
        display="flex"
        sx={{
          justifyContent: 'right',
          padding: '0 0 16px',
          '@media screen and (orientation: landscape)': {
            padding: '0 0 10px',
          },
          '@media (max-width:768px)': {
            justifyContent: 'center',
          },
        }}
      >
        <EmailUsButton
          isEmailUpVisible={isEmailUpVisible}
          handleShowEmailUsPopup={handleShowEmailUsPopup}
          emailUsText={t('emailUs')}
        />
      </Box>

      <Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            gap: '5px',
            mt: '16px',
            '@media (max-width:768px)': {
              justifyContent: 'center',
              flexDirection: 'column',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {SOCIAL_MEDIA.map((item, index) => (
              <Box key={index}>
                <img
                  key={item.id}
                  src={`/images/${item.name}.svg`}
                  alt={`${item.name} logo`}
                  onClick={() => goTo(item.url)}
                />
              </Box>
            ))}
          </Box>
          <Box>
            <Typography
              variant={isArabic ? 'bodySmallAr' : 'bodySmall'}
              component="span"
              sx={{
                mt: '5px',
                color: COLORS?.BLACK,
                fontSize: isArabic ? '12px' : '14px',
                fontWeight: 600,
              }}
            >
              {!isArabic ? '\u00a9' : ''}
              {`${new Date().getFullYear()} ${isArabic ? '\u00a9' : ''} ${t('reservedRight')}`}
            </Typography>
          </Box>
        </Box>
        {/* <Grid
          container
          sx={{
            marginTop: { xs: '16px', sm: '16px' },
            display: 'flex',
            padding: 0,
            flexDirection: { xs: 'column-reverse', sm: 'row' },
          }}
        >
          <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'unset' } }}>
            <Typography
              variant={isArabic ? 'bodySmallAr' : 'bodySmall'}
              sx={{
                color: COLORS.LIGHT_GRAY,
              }}
            >
              {!isArabic ? '\u00a9' : ''}
              {`${new Date().getFullYear()} ${isArabic ? '\u00a9' : ''} ${t('copyRights')}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            justifyContent={isArabic ? { xs: 'center', sm: 'flex-start' } : { xs: 'center', sm: 'flex-end' }}
            display="flex"
            sx={{
              direction: 'initial',
              '& img': {
                marginLeft: { xs: '10px', sm: '20px' },
                marginRight: { xs: '10px', sm: '0px' },
                marginBottom: { xs: '14px', sm: '0px' },
                cursor: 'pointer',
              },
            }}
          >
            {SOCIAL_MEDIA.map((item) => (
              <img
                key={item.id}
                src={`/images/social-media/${item.name}.svg`}
                alt={`${item.name} logo`}
                onClick={() => goTo(item.url)}
              />
            ))}
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  );
});

export default Footer;
