import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { resetPasswordMobileFormFields } from '../../../forms/fields/formFields';
import { resetPasswordMobileFormSchema } from '../../../forms/validationSchema/formSchema';
import { CustomTextField } from '../../../shared/CustomTextField';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';

import 'react-phone-number-input/style.css';
import './mobile-field.css';

interface ResetPasswordMobileFormProps {
  errorMessage?: string;
  toggleRestoreType: () => void;
  onSubmit: (mobile: string) => void;
  mobile: string;
}

const ResetPasswordMobileForm = (props: ResetPasswordMobileFormProps) => {
  const { toggleRestoreType, onSubmit, errorMessage, mobile } = props;
  const { t, i18n } = useTranslation('auth', { useSuspense: true });
  const isEnglish = i18n.language === 'en';

  const { handleSubmit, values, handleChange, errors, isValid } = useFormik({
    initialValues: { [resetPasswordMobileFormFields.mobile]: mobile },
    validationSchema: resetPasswordMobileFormSchema,
    onSubmit: (formValues) => {
      onSubmit(formValues.mobile);
    },
  });

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" sx={{ py: '9%' }}>
      <Grid item sx={{ mb: '24px' }}>
        <Typography
          component="h1"
          variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'}
          sx={{ textTransform: 'capitalize', mb: '24px' }}
        >
          {t('restorePassword')}
        </Typography>
        {/* <Typography */}
        {/*  variant={isEnglish ? "bodyLarge" : "cairoBody"} */}
        {/*  color={COLORS.LIGHT_GRAY} */}
        {/* > */}
        {/*  Lorem ipsum dolor sit amet adipiscing elit. */}
        {/* </Typography> */}
      </Grid>
      <Grid
        item
        xs={11}
        sm={8}
        md={6}
        lg={5}
        xl={4}
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '48px',
          width: '100%',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05);',
          borderRadius: '12px',
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <Typography
            variant={!isEnglish ? 'cairoR16' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="justify"
            paragraph
            sx={{ marginBottom: 1 }}
          >
            {t('signUpFormMobileLabel')}
          </Typography>
          <Box
            sx={{
              mb: 7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '62px',
                height: '53px',
                border: '1px solid #D1D5DB',
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: '4px',
                mb: 'auto',
                p: 4,
              }}
            >
              <img src="/images/auth/saudi-arabia.svg" alt="Saudi Arabia Flag" />
            </Box>
            <PhoneInput
              id={resetPasswordMobileFormFields.mobile}
              name={resetPasswordMobileFormFields.mobile}
              value={values[resetPasswordMobileFormFields.mobile]}
              onChange={(value: string) =>
                handleChange({
                  target: {
                    name: resetPasswordMobileFormFields.mobile,
                    type: 'phone',
                    value,
                  },
                })
              }
              international
              withCountryCallingCode
              countryCallingCodeEditable={false}
              country="SA"
              defaultCountry="SA"
              countries={['SA']}
              inputComponent={CustomTextField}
              style={{ width: '100%' }}
              numberInputProps={{
                variant: 'outlined',
                fullWidth: true,
                placeholder: t('signUpFormMobilePlaceholder'),
                autoComplete: 'tel',
                sx: {
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '& fieldset': { borderColor: COLORS.XLIGHT_GRAY },
                  },
                  '& .MuiFormHelperText-root': {
                    fontFamily: !isEnglish ? 'Noto Kufi Arabic' : 'Montserrat',
                  },
                },
                InputProps: {
                  style: {
                    fontFamily: !isEnglish ? 'Noto Kufi Arabic' : 'Montserrat',
                    fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                  },
                },
                error: !!errors.mobile,
                helperText: t(errors.mobile || ''),
              }}
            />
          </Box>
          <Typography
            component="h5"
            variant="body2"
            className={errorMessage ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
            color="error"
            sx={{ mb: '24px' }}
          >
            {errorMessage}
          </Typography>
          <MButton
            fullWidth
            variant="contained"
            disabled={!isValid || !values[resetPasswordMobileFormFields.mobile]}
            buttonProps={{
              type: 'submit',
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
              },
            }}
          >
            {t('next')}
          </MButton>
        </Box>
        <Grid container flexDirection="column" justifyContent="center" alignItems="center" sx={{ mt: '24px' }}>
          <Grid item>
            <Typography variant="bodySmall">
              <Link onClick={toggleRestoreType} underline="none" sx={{ mx: '5px', cursor: 'pointer' }}>
                <Typography variant={isEnglish ? 'bodyMediumB' : 'cairoBold'} component="span">
                  {t('restoreWithEmail')}
                </Typography>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordMobileForm;
