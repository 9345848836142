import { ProfileDataInfo } from '../store_deprecated/types/authTypes';
import { RecommendedPortfolio } from '../store_deprecated/types/portfolioTypes';
import { JSONParse } from './utils';

export const setAccessToken = (token: string) => localStorage.setItem('accessToken', token);

export const getAccessToken = () => localStorage.getItem('accessToken') || '';

export const removeAccessToken = () => localStorage.removeItem('accessToken');

export const setLoggedIn = (value: boolean) => localStorage.setItem('loggedIn', JSON.stringify(value));

export const getLoggedIn = () => JSONParse(localStorage.getItem('loggedIn') || '');

export const setMenuOpen = (value: boolean) => localStorage.setItem('menuOpen', JSON.stringify(value));

export const getMenuOpen = () => JSONParse(localStorage.getItem('menuOpen') || '');

export const setCongratulationModalAppearBefore = (userId: string | null) =>
  localStorage.setItem('congratulationModalAppearBefore', JSON.stringify(userId));

export const getCongratulationModalAppearBefore = () =>
  JSONParse(localStorage.getItem('congratulationModalAppearBefore') || '');

export const removeCongratulationModalAppearBefore = () => localStorage.removeItem('congratulationModalAppearBefore');

export const setRecommendedPortfolioAllocations = (
  userId: number,
  portfolioId: number,
  value: { id: number; weight: number }[],
): void => localStorage.setItem(`recommendedPortfolioAllocations-${userId}-${portfolioId}`, JSON.stringify(value));

export const getRecommendedPortfolioAllocations = (
  userId: number,
  portfolioId: number,
): { id: number; weight: number }[] | undefined =>
  JSONParse(localStorage.getItem(`recommendedPortfolioAllocations-${userId}-${portfolioId}`) || '');

export const removeRecommendedPortfolioAllocations = (userId: number, portfolioId: number) =>
  localStorage.removeItem(`recommendedPortfolioAllocations-${userId}-${portfolioId}`);

export const setSelectedPortfolio = (userId: number, portfolio: RecommendedPortfolio) =>
  localStorage.setItem(`selectedPortfolio-${userId}`, JSON.stringify(portfolio));

export const getSelectedPortfolio = (userId: number): RecommendedPortfolio =>
  JSONParse(localStorage.getItem(`selectedPortfolio-${userId}`) || 'null');

export const removeSelectedPortfolio = (userId: number) => localStorage.removeItem(`selectedPortfolio-${userId}`);

export const removeLoggedIn = () => localStorage.removeItem('loggedIn');

export const setNafazAdditionalData = (userId: number, data: Partial<ProfileDataInfo>) =>
  localStorage.setItem(`nafazAdditionalData-${userId}`, JSON.stringify(data));

export const getNafazAdditionalData = (userId: number) =>
  JSON.parse(localStorage.getItem(`nafazAdditionalData-${userId}`) || 'null');

export const clearNafazAdditionalData = (userId: number) => localStorage.removeItem(`nafazAdditionalData-${userId}`);
