import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useGetAppConfigQuery } from '../../../redux/features/config/config.apiSlice';
import { ReduxState, useTypedDispatch } from '../../../redux/store';
import {
  findRecommendedPortfolio,
  findSubscribedPortfolio,
  fetchAvailablePortfolios,
} from '../../../store_deprecated/actions/portfolioActions';
import { ConfigKey } from '../../../types/config';
import RiskAssessmentScore from './RiskAssessmentScore';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { fetchUserPreferenceByType } from '../../../redux/modules/userPreference/userPreference.actions';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import {
  getRecommendedPortfolioSelector,
  getSubscribedPortfolioSelector,
  getAvailablePortfoliosSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import { RecommendedPortfolio, SubscribedPortfolio } from '../../../store_deprecated/types/portfolioTypes';
import RecommendedPortfoliosView from '../../../shared/RecommendedPortfoliosView';
import { fetchAttachedDocuments } from '../../../store_deprecated/actions/docsActions';
import { fetchBankInfo } from '../../../store_deprecated/actions/walletActions';
import Loader from '../../../shared/Loader';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import { userPreferenceByTypeSelector } from '../../../redux/modules/userPreference/userPreference.selectors';

const InvestView: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [initialized, setInitialized] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subPortfolio, setSubPortfolio] = React.useState<SubscribedPortfolio | undefined>();
  const [recommPortfolio, setRecommPortfolio] = React.useState<RecommendedPortfolio | undefined>();

  const surveyResult = useSelector(getSurveyResult);
  const subscribedPortfolio = useSelector(getSubscribedPortfolioSelector);
  const recommendedPortfolio = useSelector(getRecommendedPortfolioSelector);
  const availablePortfolios = useSelector(getAvailablePortfoliosSelector);
  const { userPreference: savedRecommendedPortfolio } = useSelector((state: ReduxState) =>
    userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO),
  );

  const { data: appConfigCacheAllocation, isLoading: isAppConfigCacheAllocationLoading } = useGetAppConfigQuery(
    ConfigKey.CACHE_ALLOCATION_PERCENTAGE,
  );
  const cashAllocationPercentage = JSON.parse(appConfigCacheAllocation?.value || '0');
  const savedRecommendedPortfolioData = savedRecommendedPortfolio ? JSON.parse(savedRecommendedPortfolio) : [];

  const isLoading = recommendedPortfolio.isLoading || isAppConfigCacheAllocationLoading;

  const fetchRecommendedPortfolio = useCallback((category: string) => {
    dispatch(findRecommendedPortfolio(category));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getAvailablePortfolios = () => {
      try {
        Promise.all([dispatch(fetchAvailablePortfolios())]);
      } catch (e) {
        console.log('Some error');
      }
    };

    getAvailablePortfolios();

    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO)).unwrap(),
          dispatch(fetchBankInfo()),
          dispatch(fetchAttachedDocuments()),
          dispatch(fetchLastSurveyResult()),
          dispatch(findSubscribedPortfolio()),
        ]);
      } catch (e) {
        console.log('Some error');
      }
      setInitialized(true);
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialized) {
      fetchRecommendedPortfolio(surveyResult.category?.nameEn);
    }

    if (subscribedPortfolio?.id) {
      setSubPortfolio(subscribedPortfolio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyResult, subscribedPortfolio, initialized]);

  useEffect(() => {
    if (recommendedPortfolio?.id) {
      setRecommPortfolio(recommendedPortfolio);
    }
  }, [recommendedPortfolio]);

  useEffect(() => {
    window.addEventListener(
      'orientationchange',
      () => {
        setInitialized(false);
        setTimeout(() => {
          setInitialized(true);
        }, 0);
      },
      false,
    );

    // eslint-disable-next-line no-restricted-globals, react-hooks/exhaustive-deps
  }, []);

  if (!initialized || !availablePortfolios.data.totalItems) {
    return <Loader />;
  }

  return (
    <Box sx={{ padding: { xs: '48px 24px', md: '32px 32px 0 32px' } }} maxWidth="100%">
      <Grid container flexDirection="column" rowSpacing="32px">
        <Grid item sx={{ paddingTop: { xs: '0 !important', md: '32px !important' } }} maxWidth="100%">
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontWeight: { xs: 600, md: 500 },
              fontSize: { xs: '14px', md: '24px' },
              lineHeight: { xs: '20px', md: '32px' },
            }}
          >
            {t('invest.title')}
          </Typography>
        </Grid>

        <Grid item maxWidth="100%">
          <RiskAssessmentScore />
        </Grid>

        <Grid item maxWidth="100%">
          <RecommendedPortfoliosView
            recommendedPortfolio={recommPortfolio}
            subscribedPortfolio={subPortfolio}
            loading={isLoading}
            cashAllocationPercentage={cashAllocationPercentage}
            savedRecommendedPortfolioData={savedRecommendedPortfolioData}
          />
        </Grid>

        {/* TODO: Hidden till implement Goals */}
        {/* <Grid item id="SetGoal" maxWidth="100%"> */}
        {/*  <Typography */}
        {/*    variant="h5" */}
        {/*    fontWeight={500} */}
        {/*    fontSize="24px" */}
        {/*    lineHeight="32px" */}
        {/*    color={COLORS.MAIN_DARK} */}
        {/*  > */}
        {/*    {t("invest.setGoal")} */}
        {/*  </Typography> */}
        {/* </Grid> */}
        {/* <Grid item maxWidth="100%"> */}
        {/*  <Goals /> */}
        {/* </Grid> */}
        {/* <Grid item maxWidth="100%"> */}
        {/*  <MButton */}
        {/*    variant="contained" */}
        {/*    onClick={() => alert("Coming soon.")} */}
        {/*    fullWidth */}
        {/*    buttonProps={{ */}
        {/*      sx: { */}
        {/*        width: { xs: "100%", md: "100%" }, */}
        {/*        height: { xs: "50px", md: 60 }, */}
        {/*        borderRadius: "80px", */}
        {/*      }, */}
        {/*    }} */}
        {/*  > */}
        {/*    {t("invest.retirementCalculator")} */}
        {/*  </MButton> */}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
};

export default InvestView;
