import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RecommendedPortfolio, SubscribedPortfolio } from '../store_deprecated/types/portfolioTypes';
import { getSaveChartColor } from '../utils/charts';
import { round4, formatNumber } from '../utils/number';

type Dataset = {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
};

interface DoughnutChartProps {
  portfolio?: SubscribedPortfolio | RecommendedPortfolio;
  cashAllocationPercentage: number;
  shownInSlider?: boolean;
}

const PortfolioDoughnutChart = (props: DoughnutChartProps) => {
  const { portfolio, cashAllocationPercentage, shownInSlider } = props;
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const matchMobileView = useMediaQuery(shownInSlider ? '(max-width:900px)' : '(max-width:600px)');
  const anotherPaddingValues = shownInSlider
    ? {}
    : {
        right: matchMobileView ? 0 : 80,
        top: matchMobileView ? 0 : 80,
        bottom: matchMobileView ? 0 : 80,
      };
  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: !shownInSlider,
      padding: {
        left: matchMobileView ? 0 : 80,
        ...anotherPaddingValues,
      },
    },
    plugins: {
      legend: {
        display: true,
        maxWidth: matchMobileView ? 170 : 300,
        position: isEnglish ? 'left' : 'right',
        rtl: !isEnglish,
        labels: {
          boxWidth: 20,
          font: {
            // eslint-disable-next-line no-nested-ternary
            size: matchMobileView ? (shownInSlider ? 10 : 12) : 18,
            family: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
          },
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: ({ label, formattedValue }) => `${label}:${formatNumber(+formattedValue, i18n.language, 2)}%`,
        },
      },
    },
  };
  const [data, setData] = useState({
    labels: [] as string[],
    datasets: [] as Dataset[],
  });

  useEffect(() => {
    if (portfolio?.id) {
      const newData = {
        ...data,
        labels: [] as string[],
        datasets: [
          {
            label: 'My First Dataset',
            data: [] as number[],
            backgroundColor: [] as string[],
            borderColor: [] as string[],
            borderWidth: 1,
          },
        ],
      };

      const assetsWeight = portfolio.associatedIndexFunds.reduce(
        (res, item) => {
          const { asset } = item.indexFund;

          if (!asset) {
            return res;
          }

          if (res[asset.id]) {
            res[asset.id].weight = round4(res[asset.id].weight + +item.weight);
          } else {
            res[asset.id] = {
              name: isEnglish ? asset.nameEn : asset.nameAr,
              weight: round4(+item.weight),
            };
          }

          return res;
        },
        {} as Record<number, { name: string; weight: number }>,
      );

      const items = Object.values(assetsWeight);
      items.sort((a: any, b: any) => b.weight - a.weight);

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        newData.labels.push(item.name);
        newData.datasets[0].data.push(item.weight);
        newData.datasets[0].backgroundColor.push(getSaveChartColor(items.length + 1, i));
        newData.datasets[0].borderColor.push(getSaveChartColor(items.length + 1, i));
      }

      newData.labels.push(t('cash'));
      newData.datasets[0].data.push(cashAllocationPercentage || 0);
      newData.datasets[0].backgroundColor.push(getSaveChartColor(items.length + 1, items.length));
      newData.datasets[0].borderColor.push(getSaveChartColor(items.length + 1, items.length));

      setData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio, cashAllocationPercentage, isEnglish]);

  return <Doughnut data={data} options={options} />;
};

export default PortfolioDoughnutChart;
