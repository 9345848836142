import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, IconButton, Box, Typography, Grid, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import COLORS from '../../../constants/colors';
import START_INVEST_STEPS from '../../../assets/data/start-invest-steps-modal.json';
import Deposit, { DepositProps } from '../MyWallet/Deposit';

export interface CongratulationModalProps {
  open: boolean;
  beneficiaryName: string;
  accountNumber: string;
  ibanNumber: string;
  depositConfig: DepositProps | null;
  handleClose: (e: any) => void;
}

const CongratulationModal: React.FC<CongratulationModalProps> = ({
  open,
  beneficiaryName,
  accountNumber,
  ibanNumber,
  depositConfig,
  handleClose,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [showSuccessfulToast, setShowSuccessfulToast] = useState(false);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{
        [theme.breakpoints.down('sm')]: {
          '& .MuiDialog-container .MuiDialog-paper': {
            margin: '12px',
            width: 'calc(100% - 24px)',
          },
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: { xs: 4, md: 8 },
          marginTop: 8,
          '&::-webkit-scrollbar': {
            width: '5px !important',
            backgroundColor: 'transparent !important',
            borderRadius: '4px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111827 !important',
            borderRadius: '4px !important',
          },
        }}
      >
        <img src="/images/confetti.png" alt="confetti" style={{ width: '120px', margin: 'auto', display: 'block' }} />

        <Box sx={{ mt: 5 }}>
          <Typography
            variant="h1"
            color={COLORS.MAIN_DARK}
            textAlign="center"
            paragraph
            sx={{
              marginBottom: 1,
              fontSize: { xs: '16px', md: '18px' },
              lineHeight: { xs: '28px', md: '32px' },
            }}
          >
            {t('dashboard.congratulation.dialog.hero')}
          </Typography>
          <ol>
            {START_INVEST_STEPS[i18n.language as 'en' | 'ar'].map((step: any) => (
              <li key={step.id}>
                <Typography
                  variant="h5"
                  color={COLORS.MAIN_DARK}
                  paragraph
                  sx={{
                    marginBottom: 1,
                    width: '95%',
                    fontSize: { xs: '16px', md: '16px' },
                    lineHeight: { xs: '28px', md: '32px' },
                  }}
                >
                  {step.text}
                </Typography>
              </li>
            ))}
          </ol>
        </Box>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            marginBottom: '12px',
            alignItems: { xs: 'flex-start', md: 'center' },
          }}
        >
          <Grid item xs={8} textAlign="left">
            <Typography
              variant="bodySmall"
              sx={{
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
              }}
            >
              {t('bankName')}
            </Typography>
            <Typography
              sx={{
                color: COLORS.MAIN_DARK,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
              }}
              variant="h6"
            >
              {t('arabNationalBank')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            marginBottom: '12px',
            alignItems: { xs: 'flex-start', md: 'center' },
          }}
        >
          <Grid item xs={8} textAlign="left">
            <Typography
              variant="bodySmall"
              sx={{
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
              }}
            >
              {t('dashboard.congratulation.dialog.beneficiaryName')}
            </Typography>
            <Typography
              sx={{
                color: COLORS.MAIN_DARK,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
              }}
              variant="h6"
            >
              {beneficiaryName}
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign="right" alignItems="bottom">
            <Link
              component="button"
              color="inherit"
              variant="body2"
              onClick={() => {
                navigator.clipboard.writeText(beneficiaryName);
                setShowSuccessfulToast(true);
              }}
            >
              {t('dashboard.congratulation.dialog.copy')}
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            marginBottom: '12px',
            alignItems: { xs: 'flex-start', md: 'center' },
          }}
        >
          <Grid item xs={8} textAlign="left">
            <Typography
              variant="bodySmall"
              sx={{
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
              }}
            >
              {t('dashboard.congratulation.dialog.accountNumber')}
            </Typography>
            <Typography
              sx={{
                color: COLORS.MAIN_DARK,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
              }}
              variant="h6"
            >
              {accountNumber}
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign="right" alignItems="bottom">
            <Link
              component="button"
              color="inherit"
              variant="body2"
              onClick={() => {
                navigator.clipboard.writeText(accountNumber);
                setShowSuccessfulToast(true);
              }}
            >
              {t('dashboard.congratulation.dialog.copy')}
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            marginBottom: '12px',
            alignItems: { xs: 'flex-start', md: 'center' },
          }}
        >
          <Grid item xs={8} textAlign="left">
            <Typography
              variant="bodySmall"
              sx={{
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
              }}
            >
              {t('dashboard.congratulation.dialog.ibanNumber')}
            </Typography>
            <Typography
              sx={{
                color: COLORS.MAIN_DARK,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
              }}
              variant="h6"
            >
              {ibanNumber}
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign="right" alignItems="bottom">
            <Link
              component="button"
              color="inherit"
              variant="body2"
              onClick={() => {
                navigator.clipboard.writeText(ibanNumber);
                setShowSuccessfulToast(true);
              }}
            >
              {t('dashboard.congratulation.dialog.copy')}
            </Link>
          </Grid>
        </Grid>
        {depositConfig ? (
          <Grid item container columnSpacing="24px">
            <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' } }}>
              <Deposit {...depositConfig} page="dashboard" />
            </Grid>
          </Grid>
        ) : null}
        <Typography
          sx={{
            marginTop: 3,
            color: COLORS.LIGHT_GRAY,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
          }}
          variant="h6"
        >
          {t('dashboard.congratulation.dialog.note')}
        </Typography>
      </DialogContent>
      <Snackbar
        sx={{
          maxWidth: 'unset',
          minWidth: 'unset',
          width: 'fit-content',
          m: 'auto',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
          },
        }}
        message={t('dashboard.congratulation.dialog.copied')}
        open={showSuccessfulToast}
        onClose={() => setShowSuccessfulToast(false)}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Dialog>
  );
};

export default CongratulationModal;
