import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import CircularProgress from '@mui/material/CircularProgress';
import { getSurveyRequestIsLoading, getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import COLORS from '../../../constants/colors';
import GaugeChart from '../../../shared/GaugeChart';
import { locations } from '../../../routes/locations';
import MButton from '../../../shared/MButton';
import { getSubscribedPortfolioSelector } from '../../../store_deprecated/selectors/portfolioSelectors';

const RiskAssessmentScore: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [render, rerender] = useState(false);
  const isEnglish = i18n.language === 'en';

  const matchMobileView = useMediaQuery('(max-width:600px)');
  const matchMobileLandscapeView = useMediaQuery('(max-height:600px) and (max-width:900px)');

  const subscribeToPortfolio = useSelector(getSubscribedPortfolioSelector);
  const surveyResult = useSelector(getSurveyResult);
  const isLoading = useSelector(getSurveyRequestIsLoading);
  const surveyScore = surveyResult ? +surveyResult.score : 0;

  const retakeSurvey = () => {
    navigate(locations.survey());
  };

  const downloadResultsAsPdf = () => {
    alert('Coming soon.');
  };

  const screenOrientation =
    // eslint-disable-next-line no-restricted-globals, react-hooks/exhaustive-deps
    screen?.orientation?.angle || window.innerHeight > window.innerWidth;

  useEffect(() => {
    setTimeout(() => {
      rerender(!render);
    }, 0);
    // eslint-disable-next-line no-restricted-globals, react-hooks/exhaustive-deps
  }, [screenOrientation]);

  const renderDetails = () => (
    <>
      <Grid
        item
        container
        sx={{
          width: '100%',
          height: { xs: '150px', md: '200px' },
          alignSelf: 'center',
          pt: { xs: '40px', md: '0' },
        }}
        xs={12}
        md={10}
        lg={8}
        xl={6}
        flexWrap="nowrap"
        alignItems="flex-end"
        justifyContent="center"
      >
        <Grid
          item
          xs={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="right"
          sx={{
            mx: 0,
            '@media screen and (orientation: portrait)': {
              marginBottom: matchMobileView ? '-12%' : '6%',
            },
            '@media screen and (orientation: landscape)': {
              marginBottom: matchMobileLandscapeView ? '-20%' : '6%',
            },
          }}
        >
          <Typography
            variant={isEnglish ? 'bodySmallM' : 'cairoBodySmall'}
            sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'nowrap' }}
          >
            {t('invest.lowRisk')}
          </Typography>
          <Typography variant="bodySmall" sx={{ color: COLORS.DARK_GREY }}>
            0
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            '@media screen and (orientation: portrait)': {
              marginTop: '-7%',
              marginBottom: '-10%',
            },
            '@media screen and (orientation: landscape)': {
              marginTop: '-7%',
              marginBottom: matchMobileLandscapeView ? '-30%' : '-10%',
            },
          }}
        >
          <GaugeChart needleValue={surveyScore} reverse={!isEnglish} />
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="left"
          sx={{
            mx: 0,
            '@media screen and (orientation: portrait)': {
              marginBottom: matchMobileView ? '-12%' : '6%',
            },
            '@media screen and (orientation: landscape)': {
              marginBottom: matchMobileLandscapeView ? '-20%' : '6%',
            },
          }}
        >
          <Typography
            variant={isEnglish ? 'bodySmallM' : 'cairoBodySmall'}
            sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'nowrap' }}
          >
            {t('invest.highRisk')}
          </Typography>
          <Typography variant="bodySmall" sx={{ color: COLORS.DARK_GREY }}>
            100
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        textAlign="center"
        sx={{
          px: { xs: '0', md: '95px' },
          '@media screen and (orientation: portrait)': {
            pt: { xs: '48px', md: '70px' },
          },
          '@media screen and (orientation: landscape)': {
            pt: { xs: '50vh', md: '70px' },
          },
          m: 'auto',
        }}
      >
        <Typography variant={isEnglish ? 'bodyLarge' : 'cairoBody'} sx={{ color: COLORS.LIGHT_GRAY }}>
          {t('yourScore')}:&nbsp;
          <span style={{ color: COLORS.SECONDARY_GREEN, fontWeight: 600 }}>{surveyScore}</span>
        </Typography>
      </Grid>
      <Grid
        item
        container
        rowSpacing={3.5}
        display="flex"
        flexDirection="column"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        sx={{
          px: { xs: '0', md: '95px' },
          '@media screen and (orientation: portrait)': {
            pt: { xs: '48px', md: '70px' },
          },
          '@media screen and (orientation: landscape)': {
            pt: { xs: '10vh', md: '70px' },
          },
        }}
      >
        <Grid item>
          <Typography variant={isEnglish ? 'bodyLarge' : 'cairoBody'} sx={{ color: COLORS.LIGHT_GRAY }}>
            {t('invest.youAre')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={isEnglish ? 'h2' : 'cairoHeader3'} sx={{ color: COLORS.MAIN_DARK, my: '18px' }}>
            {isEnglish ? surveyResult?.category?.nameEn : surveyResult?.category?.nameAr}
          </Typography>
        </Grid>
        <Grid item sx={{ textAlign: { xs: 'left', md: 'center' } }}>
          <Typography variant={isEnglish ? 'bodyLarge' : 'cairoBody'} sx={{ color: COLORS.MAIN_DARK }}>
            {isEnglish ? surveyResult?.category?.descriptionEn : surveyResult?.category?.descriptionAr}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const renderLoader = () => (
    <Grid item sx={{ height: '200px' }} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );

  return (
    <Grid
      item
      container
      flexDirection={{ xs: 'row', md: 'column' }}
      component={Paper}
      elevation={0}
      sx={{
        background: COLORS.WHITE,
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '24px',
        padding: '32px 28px',
      }}
    >
      <Grid item>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontWeight: { xs: 400, md: 500 },
            fontSize: { xs: '18px', md: '24px' },
            lineHeight: { xs: '28px', md: '32px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('invest.riskAssessmentScore')}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: 'block', md: 'none' },
          width: '100%',
          height: '5px',
        }}
      >
        <br />
      </Grid>
      <Grid item sx={{ marginTop: { xs: 0, md: '8px' } }}>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {surveyResult.createdAt && moment(surveyResult.createdAt).locale(i18n.language).format('DD MMM YYYY')}
        </Typography>
      </Grid>
      {isLoading && renderLoader()}
      {!isLoading && renderDetails()}
      <Grid
        item
        sx={{
          mt: '64px',
          px: { xs: 0, md: '47px' },
          width: '100%',
          display: 'none', // TODO: Hide as it is not go to MVP
        }}
      >
        <MButton
          variant="outlined"
          onClick={downloadResultsAsPdf}
          // disabled={isLoading}
          id="DownloadResultsAsPdfButton"
          fullWidth
          buttonProps={{
            sx: {
              width: { xs: '100%', md: '100%' },
              height: { xs: '50px', md: 60 },
              borderRadius: '80px',
              boxShadow: 'none',
            },
          }}
        >
          <LogoutIcon sx={{ transform: 'rotate(90deg)', mr: '8px' }} />
          {t('invest.downloadResultsAsPdf')}
        </MButton>
      </Grid>
      <Grid
        item
        sx={{
          mt: '32px',
          px: { xs: 0, md: '47px' },
          width: '100%',
          display: !subscribeToPortfolio?.id ? 'block' : 'none',
        }}
      >
        <MButton
          variant="text"
          disabled={isLoading}
          onClick={retakeSurvey}
          id="SkipButton"
          fullWidth
          buttonProps={{
            sx: {
              height: { xs: '50px', md: 60 },
            },
          }}
        >
          {t('retakeSurvey')}
        </MButton>
      </Grid>
    </Grid>
  );
};

export default RiskAssessmentScore;
