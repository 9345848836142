import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useTypedDispatch } from '../../../redux/store';
import { createHyperPayCheckoutAction, fetchSubscriptions } from '../../../store_deprecated/actions/walletActions';
import COLORS from '../../../constants/colors';
import NewCards from './NewCards';
import { subscriptionsIsLoading } from '../../../store_deprecated/selectors/walletSelectors';
import PaymentSuccessfulScreen from '../../../shared/PaymentSuccessfulScreen';
import PaymentFailureScreen from '../../../shared/PaymentFailureScreen';
import PaymentHyperPayProcessingScreen from '../../../shared/PaymentHyperPayProcessingScreen';
import MButton from '../../../shared/MButton';

type HyperPayCheckout = {
  id: string;
  result: {
    code: string;
    description: string;
  };
  buildNumber: string;
  timestamp: string;
  ndc: string;
};

const BetaCards: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useTypedDispatch();
  const isLoadingSubscriptions = useSelector(subscriptionsIsLoading);

  const [showSuccessfulScreen, setShowSuccessfulScreen] = React.useState(false);
  const [showFailureScreen, setShowFailureScreen] = React.useState(true);
  const [showHyperPayProcessingScreen, setShowHyperPayProcessingScreen] = React.useState(false);

  const [openCardRegistraionDialog, setOpenCardRegistraionDialog] = React.useState(false);

  const [successIndicator, setSuccessIndicator] = React.useState<IAnyObject>();
  const [hyperPayCheckoutId, setHyperPayCheckoutId] = React.useState<string>('');
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

  const { user } = useSelector(authSelector);

  // TODO: remove  after testing
  let displayRecurringButton = false;
  if (!isProduction || (isProduction && user?.id === 3941)) {
    displayRecurringButton = true;
  }

  const closeDialog = () => {
    setOpenCardRegistraionDialog(false);
    // TODO: make sure what we should add here to close the dialog
  };

  const onSuccessButtonClick = () => {
    closeDialog();
    window.location.reload();
  };

  const handleHyperPayPayment = async (amount: number, paymentType: 'DB' | 'PA' | 'RG', brand: string) => {
    const result = (await dispatch(
      createHyperPayCheckoutAction({
        currency: 'SAR',
        amount,
        paymentType,
        brand,
      }),
    )) as HyperPayCheckout;

    setHyperPayCheckoutId(result.id);
  };

  const onCloseDepositDialog = async (
    provider:
      | 'card'
      | 'apple'
      | 'appleRecurring'
      | 'hyperPayMada'
      | 'hyperPayRecurring'
      | 'hyperPayVisaOrMaster'
      | 'hyperPayRegistration',
    depositAmount: number,
  ) => {
    if (depositAmount < 0) {
      setOpenCardRegistraionDialog(false);
      return;
    }

    try {
      setIsLoading(true);
      sessionStorage.setItem('hyperPayPaymentType', 'RG');
      setShowHyperPayProcessingScreen(true);
      await handleHyperPayPayment(depositAmount, 'RG', 'MADA');
    } catch (err: any) {
      closeDialog();
      console.log('[Deposit some error]', err);
    }
    setIsLoading(false);
  };

  const handleConfirm = (provider: 'hyperPayRegistration') => {
    if (isLoading) {
      return;
    }

    return !showSuccessfulScreen ? onCloseDepositDialog(provider, 0) : closeDialog();
  };

  const onDeposit = () => {
    setShowSuccessfulScreen(false);
    setShowHyperPayProcessingScreen(false);
    setShowFailureScreen(false);
    setOpenCardRegistraionDialog(true);
    handleConfirm('hyperPayRegistration');
  };

  React.useEffect(() => {
    if (!openCardRegistraionDialog) {
      setOpenCardRegistraionDialog(false);
      setSuccessIndicator(undefined);
      setHyperPayCheckoutId('');
      setError('');
      setIsLoading(false);
      setTimeout(() => {
        // delay showSuccessfulScreen as the deposit content is shown for a short time before closing the modal
        setShowSuccessfulScreen(false);
        setShowHyperPayProcessingScreen(false);
      }, 10);
    }
  }, [openCardRegistraionDialog]);

  React.useEffect(() => {
    dispatch(fetchSubscriptions());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItems = () => (isLoading && isLoadingSubscriptions ? <CircularProgress /> : <NewCards />);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        {displayRecurringButton && (
          <MButton
            variant="contained"
            onClick={onDeposit}
            typographyProps={{
              sx: {
                padding: { xs: '0px', md: '8px 24px' },
                borderRadius: '80px',
                border: 'none',
                color: COLORS.WHITE,
                fontSize: 14,
                fontWeight: 400,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              },
            }}
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: COLORS.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
              },
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            {t('dashboard.newWalletCard.addPaymentMethods')}
          </MButton>
        )}
        <Typography
          variant="h2"
          sx={{
            fontWeight: { xs: 600, md: 500 },
            fontSize: { xs: '20px', md: '24px' },
            lineHeight: '44px',
            color: COLORS.BLACK,
          }}
        >
          {t('dashboard.newWalletCard.myPaymentMethods')}
        </Typography>
      </Box>
      <Box sx={{ mb: '24px', width: '100%' }}>{renderItems()}</Box>
      {/* <MButton
        variant="contained"
        onClick={onDeposit}
        typographyProps={{
          sx: {
            padding: '8px 24px',
            borderRadius: '80px',
            border: 'none',
            color: COLORS.WHITE,
            fontSize: 14,
            fontWeight: 400,
            justifyContent: 'center',
          },
        }}
        buttonProps={{
          sx: {
            display: { xs: 'block', md: 'none' },
            p: 0,
            mt: 2,
            borderRadius: '80px',
            backgroundImage: 'none',
            width: { xs: '100%', md: '233px' },
            backgroundColor: COLORS.X_DARK_BLUE,
            height: '44px',
            '&:hover': {
              backgroundColor: COLORS.X_DARK_BLUE,
              boxShadow: 'none',
            },
          },
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
        {t('dashboard.newWalletCard.addPaymentMethods')}
      </MButton> */}
      <Dialog open={openCardRegistraionDialog} onClose={closeDialog} disableScrollLock>
        <Box sx={{ padding: '32px 24px' }}>
          {!showHyperPayProcessingScreen && !showSuccessfulScreen && !showFailureScreen}
          {showHyperPayProcessingScreen && (
            <PaymentHyperPayProcessingScreen
              checkoutId={hyperPayCheckoutId}
              isRecurring={false}
              onCloseRecurringSettings={onSuccessButtonClick}
            />
          )}
          {showSuccessfulScreen && <PaymentSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />}
          {showFailureScreen && <PaymentFailureScreen onSuccessButtonClick={onSuccessButtonClick} />}
        </Box>
      </Dialog>
    </>
  );
};

export default BetaCards;
