const getYTVideoThumbnail = (url: string, size?: string) => {
  if (!url) {
    return '';
  }

  size = !size ? 'big' : size;

  const results = url.match('[\\?&]v=([^&#]*)');
  const video = !results ? url : results[1];

  if (size === 'small') {
    return `http://img.youtube.com/vi/${video}/2.jpg`;
  }

  return `http://img.youtube.com/vi/${video}/0.jpg`;
};

export default getYTVideoThumbnail;
