import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReduxState } from '../../store';
import { UserPreferenceState } from './userPreference.types';

export const userPreferenceSelector = (state: ReduxState) => state.userPreference;
export const userPreferenceByTypeSelector = createSelector(
  userPreferenceSelector,
  (state: ReduxState, type: string) => type,
  (userPreferenceState: UserPreferenceState, type: string) => ({
    userPreference: userPreferenceState.userPreferences[type],
    fetchStatus: userPreferenceState.fetchStatus,
  }),
);
export const userPreferenceLoadingSelector = createSelector(
  userPreferenceSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);
