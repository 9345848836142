import * as APIs from '../../constants/APIs';
import { AlpacaActivityRequestParams } from '../../store_deprecated/types/alpacaTypes';
import { ApiService } from '../http';

const HTTP = new ApiService();

export async function getAlpacaActivities(params: AlpacaActivityRequestParams) {
  try {
    return await HTTP.get(APIs.GET_ALPACA_ACTIVITIES, { params });
  } catch (error) {
    HTTP.handleError(error);
  }
}
