import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import COLORS from '../../../constants/colors';
import { StyledTableCell } from '../../../shared/CustomTable';
import { RecommendedPortfolio, SubscribedPortfolio } from '../../../store_deprecated/types/portfolioTypes';
import { IndexFund } from '../../../store_deprecated/types/indexFundTypes';
import { formatNumber } from '../../../utils/number';

type RowDataItem = {
  indexFund: IndexFund;
  id: number;
  allocation: number;
  costBaseValue: number;
  marketValue: number;
  totalReturn: number;
  totalSarReturn: number;
};

function createData(indexFund: IndexFund, id: number, allocation: number, index: number): RowDataItem {
  return {
    indexFund,
    id,
    allocation,
    costBaseValue: index ? 0 : 45000,
    marketValue: index ? 0 : 50000,
    totalReturn: index ? 0 : 11,
    totalSarReturn: index ? 0 : 5000,
  };
}

interface PortfolioPositionsTableProps {
  portfolio?: SubscribedPortfolio | RecommendedPortfolio;
}

const PortfolioPositionsTable = ({ portfolio }: PortfolioPositionsTableProps) => {
  const { t, i18n } = useTranslation();
  const [rows, setRows] = useState<RowDataItem[]>([]);
  const isEnglish = i18n.language === 'en';

  useEffect(() => {
    const newRows =
      portfolio?.associatedIndexFunds.map((item, index) => createData(item.indexFund, item.id, item.weight, index)) ||
      [];

    setRows(newRows);
  }, [portfolio]);

  return (
    <TableContainer component={Paper} elevation={1} sx={{ minWidth: { xs: 'unset', md: 650 } }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ minWidth: { xs: '200px', md: 'auto' } }}>
              {t('portfolioPositions.assetClass')}
            </StyledTableCell>
            <StyledTableCell>{t('portfolioPositions.allocation')}</StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '200px', md: 'auto' } }}>
              {t('portfolioPositions.costBaseValue')}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '200px', md: 'auto' } }}>
              {t('portfolioPositions.marketValue')}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '200px', md: 'auto' } }}>
              {t('portfolioPositions.totalReturn')}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '200px', md: 'auto' } }}>
              {t('portfolioPositions.totalSarReturn')}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={`portfolio-positions-table-${row.id}`}>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.PRIMARY_BLUE,
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  width: '22%',
                }}
              >
                {isEnglish ? row.indexFund.strategy.nameEn : row.indexFund.strategy.nameAr}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  width: '10%',
                }}
              >
                {formatNumber(row.allocation, i18n.language, 2)}%
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  width: '17%',
                }}
              >
                {formatNumber(row.costBaseValue, i18n.language, 2)}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  width: '17%',
                }}
              >
                {formatNumber(row.marketValue, i18n.language, 2)}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  width: '17%',
                }}
              >
                {formatNumber(row.totalReturn, i18n.language, 2)}%
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  width: '17%',
                }}
              >
                {formatNumber(row.totalSarReturn, i18n.language, 2)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PortfolioPositionsTable;
