import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { ChartOptions } from 'chart.js';
import COLORS from '../constants/colors';
import { FundChartDataItem } from '../store_deprecated/types/indexFundTypes';
import { round2Format } from '../utils/number';

interface MarketPerformanceChartProps {
  chartData: FundChartDataItem[];
}

const MarketPerformanceChart: React.FC<MarketPerformanceChartProps> = ({ chartData }) => {
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState<ChartOptions<'line'>>({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        filter(tooltipItem) {
          return tooltipItem.datasetIndex === 0;
        },
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        min: 0,
        max: 30000,
        ticks: {
          // Include a dollar sign in the ticks
          callback(value, index, ticks) {
            return round2Format(+value || 0, undefined);
          },
        },
      },
    },
  });
  const [data, setData] = useState({
    labels: ['25 Aug', '26 Aug', '27 Aug', '28 Aug', '29 Aug'] as string[],
    datasets: [
      {
        label: 'Dataset 1',
        fill: true,
        tension: 0.4,
        pointBorderWidth: 0,
        pointRadius: 0,
        data: [25000, 9000, 14000, 9000, 23000],
        borderColor: COLORS.ACCENT_CYAN,
        backgroundColor: COLORS.ACCENT_CYAN,
      },
      {
        label: 'Dataset 2',
        fill: true,
        tension: 0.2,
        pointBorderWidth: 0,
        pointRadius: 0,
        data: [14900, 25100, 25100, 19000, 18000],
        borderColor: COLORS.X_LIGHT_GREEN,
        backgroundColor: COLORS.X_LIGHT_GREEN,
      },
    ],
  });

  useEffect(() => {
    const newData = {
      labels: [] as string[],
      datasets: [
        {
          ...data.datasets[0],
          data: [] as number[],
          label: t('fundDetails.fundPerformance'),
        },
        { ...data.datasets[1], data: [] as number[] },
      ],
    };

    for (const chartDatum of chartData) {
      newData.labels.push(chartDatum.date);
      newData.datasets[0].data.push(chartDatum.fundNav);
      newData.datasets[1].data.push(chartDatum.benchmarkNav);
    }

    const newOptions = _cloneDeep(options);

    const minNav = Math.min(...[...newData.datasets[0].data, ...newData.datasets[1].data]);

    _set(newOptions, 'scales.y.min', Math.min(0, minNav));
    _set(newOptions, 'scales.y.max', undefined);

    setData(newData);
    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, i18n.language]);

  return <Line data={data} options={options} />;
};

export default MarketPerformanceChart;
