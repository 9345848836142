import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import OTPInput from '../Login/OTPInput';
import CountDown from '../Login/CountDown';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';

interface ResetPasswordOtpFormProps {
  errorMessage?: string;
  onSubmit: (otp: string) => void;
  onResendOTP: () => void;
}

const OTP_INPUT_LENGTH = 4;

const ResetPasswordOtpForm = ({ onSubmit, onResendOTP, errorMessage }: ResetPasswordOtpFormProps) => {
  const { t, i18n } = useTranslation('auth', { useSuspense: true });
  const isEnglish = i18n.language === 'en';
  const [otp, setOtp] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [otpSendTime, setOtpSendTime] = useState(Date.now());

  const resendOTP = async () => {
    setOtp('');
    setErrMsg('');
    setOtpSendTime(Date.now());
    onResendOTP();
  };

  const handleSubmitOtp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (otp.length !== OTP_INPUT_LENGTH && otpSendTime) {
      return setErrMsg('missingFields');
    }
    if (!otpSendTime) {
      return setErrMsg('resendOTP');
    }

    setErrMsg('');

    onSubmit(otp);
  };

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" sx={{ py: '9%' }}>
      <Grid item sx={{ mb: '24px' }}>
        <Typography
          component="h1"
          variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'}
          sx={{ textTransform: 'capitalize', mb: '24px' }}
        >
          {t('restorePassword')}
        </Typography>
        {/* <Typography */}
        {/*  variant={isEnglish ? "bodyLarge" : "cairoBody"} */}
        {/*  color={COLORS.LIGHT_GRAY} */}
        {/* > */}
        {/*  Lorem ipsum dolor sit amet adipiscing elit. */}
        {/* </Typography> */}
      </Grid>
      <Grid
        item
        xs={10}
        md={6}
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '48px',
          paddingBottom: '24px',
          width: '100%',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05);',
          borderRadius: '12px',
        }}
      >
        <Box component="form" onSubmit={handleSubmitOtp} noValidate sx={{ mt: 1, width: '100%' }}>
          <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK }}>
            {t('enterMobileCode')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              direction: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '56px',
              mt: 4,
              mb: 6,
            }}
          >
            <OTPInput
              autoFocus
              isArabic={!isEnglish}
              length={OTP_INPUT_LENGTH}
              clearOTP={otpSendTime !== 0}
              className="otpContainer"
              inputClassName="otpInput singleInput"
              onChangeOTP={(v) => setOtp(v)}
              style={{ height: '100%' }}
              // isNumberInput // adding isNumberInput make input field doesn't accept arabic numbers
              inputStyle={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: COLORS.XLIGHT_GRAY,
                borderRadius: '8px',
                height: '60px',
                width: '60px',
              }}
            />
          </Box>
          <Typography
            component="h5"
            variant="body2"
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
            color="error"
            sx={{ mb: '24px' }}
          >
            {t(errorMessage || errMsg)}
          </Typography>
          <MButton
            fullWidth
            variant="contained"
            disabled={otp.length !== OTP_INPUT_LENGTH && !otpSendTime}
            buttonProps={{
              type: 'submit',
              sx: {
                mb: '24px',
                boxShadow: 'none',
                height: { xs: '50px', md: 60 },
              },
            }}
            typographyProps={{ variant: isEnglish ? 'h6' : 'cairoH4' }}
          >
            {t('submit')}
          </MButton>
          <CountDown otpSendTime={otpSendTime} setOtpSendTime={setOtpSendTime} resendOTP={resendOTP} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordOtpForm;
