import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import SignupView from '../../components/User/Signup/SignupView';
import OTPView from '../../components/User/Signup/OTPView';
import { useSendSignupOTPMutation, useSignupMutation } from '../../redux/features/auth/auth.apiSlice';
import { validate } from '../../utils/validate';
import PasswordValidationRules from '../../constants/PasswordValidationRules';
import { locations } from '../../routes/locations';
import { AuthApiError } from '../../constants/ApiError';
import { setCredentials } from '../../redux/features/auth/auth.slice';

const Loader = React.lazy(() => import('../../shared/Loader'));

const fullNameLength = 100;
const emailLength = 100;

interface SignupProps {
  isArabic: boolean;
}

const OTP_INPUT_LENGTH = 4;

const Signup: React.FC<SignupProps> = ({ isArabic }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation('apiError');

  const [fullName, setFullName] = useState('');
  const [fullNameInvalidMessage, setFullNameInvalidMessage] = useState('');

  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileNumberInvalidMessage, setMobileNumberInvalidMessage] = useState('');

  const [email, setEmail] = useState('');
  const [emailInvalidMessage, setEmailInvalidMessage] = useState('');

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    ...PasswordValidationRules,
  });

  const [otp, setOtp] = useState('');
  const [showEmailOTPScreen, setShowEmailOTPScreen] = useState(false);

  const [otpSendTime, setOtpSendTime] = useState(0);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [signup, { isLoading: isSignupLoading }] = useSignupMutation();
  const [sendSignUpOTP, { isLoading: isSendSignUpOTPLoading }] = useSendSignupOTPMutation();

  const [showLoader, setShowLoader] = useState(isSignupLoading || isSendSignUpOTPLoading);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangeFullName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFullNameInvalidMessage('');
    const fName = event.target.value;
    if (fName.length > fullNameLength) return;

    setFullName(fName);
  };

  const getFullNameValidationError = () => {
    let fullNameValidationStatus = '';

    if (fullName === '') {
      fullNameValidationStatus = 'requiredField';
    } else if (!validate({ type: 'fullName', value: fullName })) {
      fullNameValidationStatus = 'invalidNameFormat';
    }

    return fullNameValidationStatus;
  };

  const handleFullNameValidation = () => {
    setFullNameInvalidMessage(getFullNameValidationError());
  };

  const handleChangeMobile = (value: string) => {
    if (typeof value !== 'string') {
      console.error('handleChangeMobile called without a string value', value);
      return;
    }
    const prefix = '+966';
    let newValue = value;
    if (!newValue.startsWith(prefix)) {
      newValue = prefix + newValue.replace(/[^0-9]/g, '');
    }
    if (newValue === prefix || newValue === '') {
      setMobileNumber(prefix);
    } else {
      if (newValue.includes(prefix) && /966$/.test(newValue)) {
        newValue = newValue.replace(/966$/, '');
      }
      setMobileNumber(newValue);
      setMobileNumberInvalidMessage('');
    }
  };

  const getMobileValidationError = () => {
    let mobileValidationStatus = '';

    if (!mobileNumber) {
      mobileValidationStatus = 'requiredField';
    } else if (!validate({ type: 'mobile', value: mobileNumber })) {
      mobileValidationStatus = 'invalidFormat';
    }

    return mobileValidationStatus;
  };

  const handleMobileValidation = () => {
    setMobileNumberInvalidMessage(getMobileValidationError());
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailInvalidMessage('');
    const emailValue = event.target.value;

    if (emailValue.length > emailLength) {
      return;
    }

    setEmail(emailValue);
  };

  const getEmailValidationError = () => {
    let emailValidationStatus = '';

    if (email === '') {
      emailValidationStatus = 'requiredField';
    } else if (!validate({ type: 'email', value: email })) {
      emailValidationStatus = 'invalidFormat';
    }

    return emailValidationStatus;
  };

  const handleEmailValidation = () => {
    setEmailInvalidMessage(getEmailValidationError());
  };

  const handleChangeEmailOtp = (newOtp: string) => {
    setOtp(newOtp);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPassword = event.target.value.replace(/\s/g, '');
    setPassword(newPassword);

    const errors: any = validate({ type: 'password', value: newPassword });
    // @ts-ignore
    Object.keys(passwordValidation).forEach((condition: string) => {
      const rules = passwordValidation;
      // @ts-ignore
      rules[condition].isValid = !errors.includes(condition);
      setPasswordValidation(rules);
    });
  };

  const isValidAnswer = (recheck = false) => {
    let isPasswordValid = true;
    Object.keys(passwordValidation).forEach((item) => {
      isPasswordValid = !!passwordValidation[item].isValid && isPasswordValid;
    });

    if (recheck) {
      handleFullNameValidation();
      handleMobileValidation();
      handleEmailValidation();
    }

    return !!(
      isPasswordValid &&
      !getFullNameValidationError() &&
      fullName &&
      !getMobileValidationError() &&
      mobileNumber &&
      !getEmailValidationError() &&
      email
    );
  };

  const resetFieldsAndValidations = () => {
    setFullName('');
    setFullNameInvalidMessage('');
    setMobileNumber('');
    setMobileNumberInvalidMessage('');
    setEmail('');
    setEmailInvalidMessage('');
    setPassword('');
    setShowPassword(false);
    setShowEmailOTPScreen(false);
    // setErrorMessage("");
    setPasswordValidation({ ...PasswordValidationRules });
  };

  const sendMobileOTPCode = async () => {
    try {
      setOtpSendTime(Date.now());

      await sendSignUpOTP({ email, mobileNumber }).unwrap();
    } catch (error: any) {
      setShowEmailOTPScreen(false);
      if (i18n.exists(`apiError:${error?.data?.message}`)) {
        setOtpErrorMessage(`apiError:${error?.data?.message}`);
      }
      if (error?.data?.message === AuthApiError.USER_ALREADY_EXISTS) {
        resetFieldsAndValidations();
        setErrorMessage(`apiError:${error?.data?.message}`);
      } else {
        setOtpErrorMessage('unexpectedError');
      }
    }
  };

  const resendOTP = async () => {
    setOtp('');
    setOtpErrorMessage('');
    await sendMobileOTPCode();
  };

  const handleNext = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValidAnswer(true)) {
      return;
    }

    setErrorMessage('');
    setOtpErrorMessage('');
    setShowLoader(true);
    // TODO: when we use saudi arabian numbers, this line should be used
    // TODO: check if the number has the country code or not
    // await sendOTPCode(`+966${mobileNumber}`)
    setShowEmailOTPScreen(true);
    await sendMobileOTPCode();
    setShowLoader(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (otp.length !== OTP_INPUT_LENGTH && otpSendTime) {
      return setOtpErrorMessage('missingFields');
    }
    if (!otpSendTime) {
      return setOtpErrorMessage('resendOTP');
    }

    setOtpErrorMessage('');
    // TODO: when we use saudi arabian numbers, EG line should be replaced
    // TODO: check if the number has the country code or not

    const payload = {
      fullName,
      email,
      mobileNumber,
      password,
      otpCode: otp,
    };
    setShowLoader(true);

    try {
      const res = await signup(payload).unwrap();
      dispatch(setCredentials(res));

      navigate(locations.home());
    } catch (error: any) {
      if (i18n.exists(`apiError:${error?.data?.message}`)) {
        setOtpErrorMessage(`apiError:${error?.data?.message}`);
      }
      if (error?.data?.message === AuthApiError.EMAIL_OTP_IS_NOT_VALID) {
        setShowEmailOTPScreen(true);
      }
      if (error?.data?.message === AuthApiError.USER_ALREADY_EXISTS) {
        resetFieldsAndValidations();
        setErrorMessage(`apiError:${error?.data?.message}`);
      }
    }

    setShowLoader(false);
  };

  useEffect(
    () => () => {
      resetFieldsAndValidations();
    },
    [],
  );

  function renderOtpView() {
    if (showEmailOTPScreen) {
      return (
        <OTPView
          key="otp-mobile"
          type="mobile"
          isArabic={isArabic}
          handleChangeOtp={handleChangeEmailOtp}
          handleSubmit={handleSubmit}
          resendOTP={resendOTP}
          otpSendTime={otpSendTime}
          setOtpSendTime={setOtpSendTime}
          otpErrorMessage={otpErrorMessage}
          length={OTP_INPUT_LENGTH}
        />
      );
    }
  }

  const renderForm = () => {
    if (!showEmailOTPScreen) {
      return (
        <SignupView
          isArabic={isArabic}
          handleNext={handleNext}
          handleMouseDownPassword={handleMouseDownPassword}
          handleChangeFullName={handleChangeFullName}
          handleFullNameValidation={handleFullNameValidation}
          handleChangeMobile={handleChangeMobile}
          handleMobileValidation={handleMobileValidation}
          handleChangeEmail={handleChangeEmail}
          handleEmailValidation={handleEmailValidation}
          handleChangePassword={handleChangePassword}
          isValidAnswer={isValidAnswer}
          fullName={fullName}
          fullNameInvalidMessage={fullNameInvalidMessage}
          mobileNumber={mobileNumber}
          mobileNumberInvalidMessage={mobileNumberInvalidMessage}
          email={email}
          emailInvalidMessage={emailInvalidMessage}
          password={password}
          passwordValidation={passwordValidation}
          errorMessage={errorMessage}
        />
      );
    }

    return renderOtpView();
  };

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: 'calc(100vh - 64px - 130px)',
      }}
    >
      <Box
        sx={{
          width: 300,
          position: 'absolute',
          top: '3%',
          right: 0,
          textAlign: 'right',
          zIndex: -1,
          display: { md: 'block', sm: 'none', xs: 'none' },
        }}
      >
        <img src={isArabic ? '/images/logo-frame-ar.svg' : '/images/logo-frame-en.svg'} alt="madkhol logo frame" />
      </Box>
      {showLoader ? <Loader /> : renderForm()}
    </Box>
  );
};

export default Signup;
