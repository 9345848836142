import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Typography, Grid, Box, Link, Paper, CircularProgress } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFindProfileDataQuery } from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import Breadcrumb from '../../../shared/Breadcrumb';
import COLORS from '../../../constants/colors';
import { useTypedDispatch } from '../../../redux/store';
import { KycStatus } from '../../../store_deprecated/types/authTypes';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import { locations } from '../../../routes/locations';
import NafazAdditionalDataForm from './NafazAdditionalDataForm';

const NafazDataView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const matchMobileView = useMediaQuery('(max-width:600px)');
  const dispatch = useTypedDispatch();
  const { user } = useSelector(authSelector);
  const isEnglish = i18n.language === 'en';

  const { data, isLoading } = useFindProfileDataQuery();

  useEffect(() => {
    if (data?.id && data?.status?.name === KycStatus.ACTIVE) {
      navigate(locations.dashboard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (user?.id) {
      dispatch(findProfileData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      // onClick={() => navigate(locations.pro())}
      sx={{ color: COLORS.LIGHT_GRAY, cursor: 'pointer', fontSize: '14px' }}
    >
      {t(matchMobileView ? 'breadcrumb.completeProfileMobile' : 'breadcrumb.completeProfile')}
    </Link>,
    <Typography
      key="2"
      sx={{
        color: COLORS?.BLACK,
        fontWeight: 600,
        fontSize: '14px',
      }}
    >
      {t(matchMobileView ? 'breadcrumb.nafazDataMobile' : 'breadcrumb.nafazData')}
    </Typography>,
  ];

  const items = [
    { title: 'nationalId', value: data?.nationalId },
    // { title: 'usedLanguage', value: data?.language },
    // { title: 'genderCode', value: '2019' },
    { title: 'gender', value: t(`gender.${data?.gender?.name?.toLowerCase()}`) },

    {
      title: 'inceptionDate',
      value: moment(data?.idIssueDate)
        .locale(i18n.language)
        .format('DD-MMM-YYYY'),
    },
    {
      title: 'countryOfIssuance',
      value: isEnglish ? data?.issueLocationEn : data?.issueLocationAr,
    },
    {
      title: 'expiryDate',
      value: moment(data?.idExpiryDate)
        .locale(i18n.language)
        .format('DD-MMM-YYYY'),
    },
    // { title: "usedLanguage", value: "used_language" },
    // { title: "genderCode", value: data?.gender?.name },
    {
      title: 'fullName',
      value: isEnglish ? data?.fullNameEn : data?.fullNameAr,
    },
    {
      title: 'birthDate',
      value: moment(data?.birthDate)
        .locale(i18n.language)
        .format('DD-MMM-YYYY'),
    },
    {
      title: 'nationality',
      value: isEnglish ? data?.nationalityEn : data?.nationalityAr,
    },
    {
      title: 'firstName',
      value: isEnglish ? data?.firstNameEn : data?.firstNameAr,
    },
    {
      title: 'fatherName',
      value: isEnglish ? data?.fatherNameEn : data?.fatherNameAr,
    },
    {
      title: 'grandFatherName',
      value: isEnglish ? data?.grandFatherNameEn : data?.grandFatherNameAr,
    },
    {
      title: 'familyName',
      value: isEnglish ? data?.familyNameEn : data?.familyNameAr,
    },
    {
      title: 'address',
      value: data?.address
        ? `${data?.address?.buildingNumber}, ${data?.address?.streetName}, ${data?.address?.additionalNumber} ${data?.address?.district}, ${data?.address?.postCode}, ${data?.address?.city}`
        : '',
      // TODO: In case we need different order for En and Ar
      // isEnglish
      // ? `${data?.address?.buildingNumber} ${data?.address?.streetName}, ${data?.address?.additionalNumber} ${data?.address?.district}, ${data?.address?.postCode} ${data?.address?.city}`
      // : `${data?.address?.city} ${data?.address?.postCode}, ${data?.address?.district} ${data?.address?.additionalNumber}, ${data?.address?.streetName} ${data?.address?.buildingNumber}`
    },
    // { title: "postalCode", value: "postal_code" },
  ];

  return (
    <Grid
      sx={{ padding: { xs: '20px 25px', md: '0px 32px 32px 32px' } }}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Grid
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          px: '70px',
          '@media (max-width:1050px)': {
            px: '0px',
          },
        }}
      >
        <Grid
          item
          sx={{
            mb: 8,
            '@media (max-width: 1050px)': {
              display: 'none',
            },
          }}
          alignSelf="flex-start"
        >
          <Breadcrumb breadcrumbs={breadcrumbs} />
        </Grid>

        <Grid item>
          <Typography
            component="h1"
            // variant="largeDisplayH"
            sx={{
              fontSize: { xs: '18px', md: '20px' },
              fontWeight: 600,
              '@media (max-width: 1050px)': {
                lineHeight: '27px',
                textAlign: 'center',
                mb: '20px',
              },
            }}
          >
            {t(matchMobileView ? 'nafazData.titleMobile' : 'nafazData.title')}
          </Typography>
        </Grid>

        {/* <Box>
          <Typography
            sx={{
              color: COLORS?.BLACK,
              fontSize: { xs: '16px', md: '20px' },
              textAlign: 'center',
              '@media (max-width: 1050px)': {
                lineHeight: '20px',
                mb: '4px',
              },
            }}
          >
            {t('nafazData.description')}
          </Typography>
        </Box> */}

        <Box>
          <Typography
            sx={{
              color: COLORS?.BLACK,
              fontSize: { xs: '16px', md: '20px' },
              mt: '-5px',
              mb: '32px',
            }}
          >
            {t('nafazData.subDescription')}
          </Typography>
        </Box>

        <Grid
          item
          component={Paper}
          elevation={0}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          sx={{
            borderRadius: '12px',
            paddingX: { xs: 6, md: 8 },
            paddingY: { xs: 6, md: 8 },
            width: '1030px',
            '@media (max-width: 1300px)': {
              width: '100%',
            },
          }}
        >
          {isLoading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!isLoading && (
            <Grid item container flexDirection="column">
              {items.map((item) => (
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  key={`${item.title}-${i18n.language}`}
                  sx={{
                    '@media (max-width: 1050px)': {
                      mb: '15px',
                    },
                  }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography
                      variant="bodyLargeM"
                      sx={{
                        color: COLORS.LIGHT_GRAY,
                        fontSize: { xs: '0.875rem', md: '1.125rem' },
                      }}
                    >
                      {t(`nafazData.${item.title}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography
                      variant="bodyMedium"
                      sx={{
                        color: COLORS.BLACK,
                        fontSize: { xs: '0.875rem', md: '18px' },
                        fontWeight: 500,
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>

        {data && <NafazAdditionalDataForm profileData={data} />}
      </Grid>
    </Grid>
  );
};

export default NafazDataView;
