import {
  FetchUserPreferenceResponse,
  SaveUserPreferenceRequest,
  SaveUserPreferenceResponse,
} from '../types/userPreference';
import { ApiService } from './common/ApiService';

class UserPreferenceService extends ApiService {
  pathname = 'app/user-preference';

  fetchUserPreferenceByType = async (type: string) =>
    this.get<FetchUserPreferenceResponse>(`${this.pathname}/type/${type}`);

  saveUserPreference = async (data: SaveUserPreferenceRequest) =>
    this.post<SaveUserPreferenceResponse, SaveUserPreferenceRequest>(`${this.pathname}`, data);
}

export const userPreferenceService = new UserPreferenceService();
