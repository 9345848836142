import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  answerQuestion,
  finishSurvey,
  getAnsweredQuestion,
  getConfig,
  getLastSurveyResult,
  getQuestion,
  startSurvey,
} from '../../helpers/api_deprecated';
import {
  ANSWER_QUESTION_FAIL,
  ANSWER_QUESTION_REQUEST,
  ANSWER_QUESTION_SUCCESS,
  ANSWERED_QUESTION_FAIL,
  ANSWERED_QUESTION_REQUEST,
  ANSWERED_QUESTION_SUCCESS,
  AnsweredQuestion,
  AnsweredQuestionPayload,
  AnswerQuestionPayload,
  CLEAR_SURVEY,
  ClearSurvey,
  CONFIG_FAIL,
  CONFIG_REQUEST,
  CONFIG_SUCCESS,
  ConfigData,
  CURRENT_QUESTION_FAIL,
  CURRENT_QUESTION_REQUEST,
  CURRENT_QUESTION_SUCCESS,
  FINISH_SURVEY_CONTINUE,
  FINISH_SURVEY_FAIL,
  FINISH_SURVEY_REQUEST,
  FINISH_SURVEY_SUCCESS,
  FinishSurveyPayload,
  GET_LAST_SURVEY_RESULT_FAIL,
  GET_LAST_SURVEY_RESULT_REQUEST,
  GET_LAST_SURVEY_RESULT_SUCCESS,
  QuestionData,
  QuestionPayload,
  RequestAnsweredQuestion,
  RequestAnsweredQuestionFail,
  RequestAnsweredQuestionSuccess,
  RequestAnswerQuestion,
  RequestAnswerQuestionFail,
  RequestAnswerQuestionSuccess,
  RequestConfig,
  RequestConfigFail,
  RequestConfigSuccess,
  RequestCurrentQuestion,
  RequestCurrentQuestionFail,
  RequestCurrentQuestionSuccess,
  RequestFinishSurvey,
  RequestFinishSurveyContinue,
  RequestFinishSurveyFail,
  RequestFinishSurveySuccess,
  RequestGetLastSurveyResult,
  RequestGetLastSurveyResultFail,
  RequestGetLastSurveyResultSuccess,
  RequestStartSurvey,
  RequestStartSurveyFail,
  RequestStartSurveySuccess,
  START_SURVEY_FAIL,
  START_SURVEY_REQUEST,
  START_SURVEY_SUCCESS,
  SurveyData,
  SurveyResultData,
} from '../types/surveyTypes';

export const requestConfig = (): RequestConfig => ({
  type: CONFIG_REQUEST,
});

export const requestConfigSuccess = (config: ConfigData): RequestConfigSuccess => ({
  type: CONFIG_SUCCESS,
  payload: config,
});

export const requestConfigFail = (): RequestConfigFail => ({
  type: CONFIG_FAIL,
});

export const requestAnsweredQuestion = (silence = false): RequestAnsweredQuestion => ({
  type: ANSWERED_QUESTION_REQUEST,
  payload: silence,
});

export const requestAnsweredQuestionSuccess = (answer: AnsweredQuestion): RequestAnsweredQuestionSuccess => ({
  type: ANSWERED_QUESTION_SUCCESS,
  payload: answer,
});

export const requestAnsweredQuestionFail = (): RequestAnsweredQuestionFail => ({
  type: ANSWERED_QUESTION_FAIL,
});

export const requestStartSurvey = (): RequestStartSurvey => ({
  type: START_SURVEY_REQUEST,
});

export const requestStartSurveySuccess = (survey: SurveyData): RequestStartSurveySuccess => ({
  type: START_SURVEY_SUCCESS,
  payload: survey,
});

export const requestStartSurveyFail = (): RequestStartSurveyFail => ({
  type: START_SURVEY_FAIL,
});

export const requestCurrentQuestion = (silence = false): RequestCurrentQuestion => ({
  type: CURRENT_QUESTION_REQUEST,
  payload: silence,
});

export const requestCurrentQuestionSuccess = (question: QuestionData): RequestCurrentQuestionSuccess => ({
  type: CURRENT_QUESTION_SUCCESS,
  payload: question,
});

export const requestCurrentQuestionFail = (): RequestCurrentQuestionFail => ({
  type: CURRENT_QUESTION_FAIL,
});

export const requestAnswerQuestion = (payload: AnswerQuestionPayload): RequestAnswerQuestion => ({
  type: ANSWER_QUESTION_REQUEST,
  payload,
});

export const requestAnswerQuestionSuccess = (answer: AnswerQuestionPayload): RequestAnswerQuestionSuccess => ({
  type: ANSWER_QUESTION_SUCCESS,
  payload: answer,
});

export const requestAnswerQuestionFail = (): RequestAnswerQuestionFail => ({
  type: ANSWER_QUESTION_FAIL,
});

export const requestFinishSurvey = (): RequestFinishSurvey => ({
  type: FINISH_SURVEY_REQUEST,
});

export const requestFinishSurveySuccess = (result: SurveyResultData): RequestFinishSurveySuccess => ({
  type: FINISH_SURVEY_SUCCESS,
  payload: result,
});

export const requestFinishSurveyContinue = (): RequestFinishSurveyContinue => ({
  type: FINISH_SURVEY_CONTINUE,
});

export const requestFinishSurveyFail = (): RequestFinishSurveyFail => ({
  type: FINISH_SURVEY_FAIL,
});

export const requestGetLastSurveyResult = (): RequestGetLastSurveyResult => ({
  type: GET_LAST_SURVEY_RESULT_REQUEST,
});

export const requestGetLastSurveyResultSuccess = (result: SurveyResultData): RequestGetLastSurveyResultSuccess => ({
  type: GET_LAST_SURVEY_RESULT_SUCCESS,
  payload: result,
});

export const requestGetLastSurveyResultFail = (): RequestGetLastSurveyResultFail => ({
  type: GET_LAST_SURVEY_RESULT_FAIL,
});

export const clearSurvey = (isClearSurveyResult = false): ClearSurvey => ({
  type: CLEAR_SURVEY,
  payload: isClearSurveyResult,
});

export const startUserSurvey = (): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestStartSurvey());
  try {
    const response: any = await startSurvey();

    dispatch(requestStartSurveySuccess(response.data));
  } catch (error) {
    dispatch(requestStartSurveyFail());
    throw error;
  }
};

export const fetchCurrentQuestion =
  (payload: QuestionPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestCurrentQuestion(payload.silence));
    try {
      const { surveyId, order } = payload;
      const response: any = await getQuestion(surveyId, order);

      dispatch(requestCurrentQuestionSuccess({ ...response.data, order }));

      return response.data.id;
    } catch (error) {
      dispatch(requestCurrentQuestionFail());
      throw error;
    }
  };

export const answerCurrentQuestion =
  (payload: AnswerQuestionPayload): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestAnswerQuestion(payload));
    try {
      const { answer, questionId, surveyResultId } = payload;
      await answerQuestion(payload);

      dispatch(requestAnswerQuestionSuccess({ questionId, answer, surveyResultId }));
    } catch (error) {
      dispatch(requestAnswerQuestionFail());
      throw error;
    }
  };

export const fetchConfig = (): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestConfig());
  try {
    const response: any = await getConfig();

    dispatch(requestConfigSuccess(response.data));

    return response.data;
  } catch (error) {
    dispatch(requestConfigFail());
    throw error;
  }
};

export const fetchAnsweredQuestion =
  (payload: AnsweredQuestionPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestAnsweredQuestion(payload.silence));
    try {
      const { surveyResultId, questionId } = payload;
      const response: any = await getAnsweredQuestion(surveyResultId, questionId);

      const { answer } = response.data;
      dispatch(requestAnsweredQuestionSuccess({ questionId, answer, surveyResultId }));
      return answer;
    } catch (error) {
      dispatch(requestAnsweredQuestionFail());
      throw error;
    }
  };

export const finishUserSurvey =
  (payload: FinishSurveyPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestFinishSurvey());
    try {
      const { surveyResultId, surveyId } = payload;
      const response: { data: SurveyResultData } = await finishSurvey(surveyResultId);

      if ('category' in response.data) {
        dispatch(
          requestFinishSurveySuccess({
            category: response.data.category,
            score: response.data.score,
            surveyId,
          }),
        );
      } else {
        dispatch(requestFinishSurveyContinue());
      }

      return response.data;
    } catch (error) {
      dispatch(requestFinishSurveyFail());
      throw error;
    }
  };

export const fetchLastSurveyResult = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestGetLastSurveyResult());
  try {
    const response: any = await getLastSurveyResult();

    dispatch(requestGetLastSurveyResultSuccess(response.data));

    return response.data;
  } catch (error) {
    dispatch(requestGetLastSurveyResultFail());
    throw error;
  }
};
