import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import TagManager from 'react-gtm-module';
import { store } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

/**
 * TODO: We have to setup using different environments
 *
 * TagManager.initialize({
 *   gtmId: process.env.REACT_APP_GTM_ID,
 *   auth: process.env.REACT_APP_GTM_AUTH,
 *   preview: process.env.REACT_APP_GTM_PREVIEW,
 * });
 */
TagManager.initialize({
  gtmId: 'GTM-KGK59QJ',
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
