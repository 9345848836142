import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { apiSlice } from '../api/common/apiSlice';
import alpacaReducer from '../store_deprecated/reducers/alpacaReducer';
import appReducer from '../store_deprecated/reducers/appReducer';
import docsReducer from '../store_deprecated/reducers/docsReducer';
import faqReducer from '../store_deprecated/reducers/faqReducer';
import indexFundReducer from '../store_deprecated/reducers/indexFundReducer';
import portfolioReducer from '../store_deprecated/reducers/portfolioReducer';
import surveyReducer from '../store_deprecated/reducers/surveyReducer';
import transactionReducer from '../store_deprecated/reducers/transactionReducer';
import userAgreementReducer from '../store_deprecated/reducers/userAgreementReducer';
import walletReducer from '../store_deprecated/reducers/walletReducer';
import { authReducer } from './features/auth/auth.slice';
import { userPreferenceReducer } from './modules/userPreference/userPreference.reducer';
import { subscriptionReducer } from './modules/subscription/subscription.reducer';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    portfolioState: portfolioReducer,
    surveyState: surveyReducer,
    walletState: walletReducer,
    docsState: docsReducer,
    transactionState: transactionReducer,
    faqState: faqReducer,
    indexFundState: indexFundReducer,
    appState: appReducer,
    agreementState: userAgreementReducer,
    alpacaState: alpacaReducer,
    userPreference: userPreferenceReducer,
    subscription: subscriptionReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

// enable listener behavior for the store
setupListeners(store.dispatch);

export * from './hooks';

export type AppDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof store.getState>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, AnyAction>;
