import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  deleteUserBankInfo,
  doCreateApplePayPayment,
  doCreateApplePaySession,
  doCreateMastercardSession,
  doCreateHyperPayCheckout,
  doQueryHyperPayPaymentStatus,
  saveUserSubscription,
  getSubscriptions,
  doWithdraw,
  getAnbMasterardConfig,
  getBankInfo,
  getBanks,
  getEditBankInfo,
  getNetWithdraw,
  getStatementDelta,
  saveUserBankInfo,
  setPrimaryUserBankAccount,
  setPrimaryUserSubscriptionCard,
  deleteUserSubscription,
  doQueryHyperPayRegistrationStatus,
  getCreatedSubscription,
} from '../../helpers/api_deprecated';
import {
  Bank,
  BANK_INFO_FAIL,
  BANK_INFO_REQUEST,
  BANK_INFO_SUCCESS,
  BankItem,
  BANKS_FAIL,
  BANKS_REQUEST,
  BANKS_SUCCESS,
  CLEAR_EDIT_BANK_INFO,
  CLEAR_WALLET,
  ClearEditBankInfo,
  ClearWallet,
  CREATE_APPLE_PAY_PAYMENT_FAIL,
  CREATE_APPLE_PAY_PAYMENT_REQUEST,
  CREATE_APPLE_PAY_PAYMENT_SUCCESS,
  CREATE_APPLE_PAY_SESSION_FAIL,
  CREATE_APPLE_PAY_SESSION_REQUEST,
  CREATE_APPLE_PAY_SESSION_SUCCESS,
  CREATE_MASTERCARD_SESSION_FAIL,
  CREATE_MASTERCARD_SESSION_REQUEST,
  CREATE_MASTERCARD_SESSION_SUCCESS,
  CREATE_HYPER_PAY_CHECKOUT_FAIL,
  CREATE_HYPER_PAY_CHECKOUT_REQUEST,
  CREATE_HYPER_PAY_CHECKOUT_SUCCESS,
  QUERY_HYPER_PAY_PAYMENT_STATUS_FAIL,
  QUERY_HYPER_PAY_PAYMENT_STATUS_REQUEST,
  QUERY_HYPER_PAY_PAYMENT_STATUS_SUCCESS,
  SAVE_SUBSCRIPTION_FAIL,
  SAVE_SUBSCRIPTION_SUCCESS,
  SAVE_SUBSCRIPTION_REQUEST,
  SUBSCRIPTIONS_REQUEST,
  SUBSCRIPTIONS_SUCCESS,
  SUBSCRIPTIONS_FAIL,
  CreateApplePayPayment,
  CreateApplePayPaymentFail,
  CreateApplePayPaymentPayload,
  CreateApplePayPaymentSuccess,
  CreateApplePaySession,
  CreateApplePaySessionFail,
  CreateApplePaySessionPayload,
  CreateApplePaySessionSuccess,
  CreateMastercardSession,
  CreateMastercardSessionFail,
  CreateMastercardSessionPayload,
  CreateMastercardSessionSuccess,
  CreateHyperPayCheckout,
  CreateHyperPayCheckoutFail,
  CreateHyperPayCheckoutPayload,
  CreateHyperPayCheckoutSuccess,
  QueryHyperPayPaymentStatus,
  QueryHyperPayPaymentStatusFail,
  QueryHyperPayPaymentStatusPayload,
  QueryHyperPayPaymentStatusSuccess,
  SaveSubscriptionPayload,
  RequestSaveSubscription,
  RequestSaveSubscriptionFail,
  RequestSaveSubscriptionSuccess,
  RequestSubscriptionsSuccess,
  RequestSubscriptionsFail,
  RequestSubscriptions,
  SubscriptionItem,
  DELETE_BANK_INFO_FAIL,
  DELETE_BANK_INFO_REQUEST,
  DELETE_BANK_INFO_SUCCESS,
  DO_WITHDRAW_FAIL,
  DO_WITHDRAW_REQUEST,
  DO_WITHDRAW_SUCCESS,
  EDIT_BANK_INFO_FAIL,
  EDIT_BANK_INFO_REQUEST,
  EDIT_BANK_INFO_SUCCESS,
  FETCH_ANB_MASTERCARD_CONFIG_FAIL,
  FETCH_ANB_MASTERCARD_CONFIG_REQUEST,
  FETCH_ANB_MASTERCARD_CONFIG_SUCCESS,
  FetchAnbMastercardConfig,
  FetchAnbMastercardConfigFail,
  FetchAnbMastercardConfigSuccess,
  GET_NET_WITHDRAW_FAIL,
  GET_NET_WITHDRAW_REQUEST,
  GET_NET_WITHDRAW_SUCCESS,
  RequestBankInfo,
  RequestBankInfoFail,
  RequestBankInfoSuccess,
  RequestBanks,
  RequestBanksFail,
  RequestBanksSuccess,
  RequestDeleteBankInfo,
  RequestDeleteBankInfoFail,
  RequestDeleteBankInfoSuccess,
  RequestEditBankInfo,
  RequestEditBankInfoFail,
  RequestEditBankInfoSuccess,
  RequestGetNetWithdraw,
  RequestGetNetWithdrawFail,
  RequestGetNetWithdrawSuccess,
  RequestGetStatementDelta,
  RequestGetStatementDeltaFail,
  RequestGetStatementDeltaSuccess,
  RequestSaveBankInfo,
  RequestSaveBankInfoFail,
  RequestSaveBankInfoSuccess,
  RequestSetPrimaryBankAccount,
  RequestSetPrimaryBankAccountFail,
  RequestSetPrimaryBankAccountSuccess,
  RequestWithdraw,
  RequestWithdrawFail,
  RequestWithdrawSuccess,
  SAVE_BANK_INFO_FAIL,
  SAVE_BANK_INFO_REQUEST,
  SAVE_BANK_INFO_SUCCESS,
  SaveBankInfoPayload,
  SaveBankInfoSuccessPayload,
  SET_PRIMARY_BANK_ACCOUNT_FAIL,
  SET_PRIMARY_BANK_ACCOUNT_REQUEST,
  SET_PRIMARY_BANK_ACCOUNT_SUCCESS,
  WithdrawPayload,
  SET_PRIMARY_SUBSCRIPTION_CARD_REQUEST,
  RequestSetPrimarySubscriptionCard,
  SET_PRIMARY_SUBSCRIPTION_CARD_SUCCESS,
  RequestSetPrimarySubscriptionCardSuccess,
  SET_PRIMARY_SUBSCRIPTION_CARD_FAIL,
  RequestSetPrimarySubscriptionCardFail,
  QueryHyperPayRegistrationStatusPayload,
  QueryHyperPayRegistrationStatus,
  QueryHyperPayRegistrationStatusSuccess,
  QueryHyperPayRegistrationStatusFail,
  QUERY_HYPER_PAY_REGISTRATION_STATUS_REQUEST,
  QUERY_HYPER_PAY_REGISTRATION_STATUS_SUCCESS,
  QUERY_HYPER_PAY_REGISTRATION_STATUS_FAIL,
  DELETE_SUBSCRIPTION_REQUEST,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  RequestDeleteSubscription,
  RequestDeleteSubscriptionSuccess,
  RequestDeleteSubscriptionFail,
  RequestCreatedSubscription,
  CREATED_SUBSCRIPTION_REQUEST,
  RequestCreatedSubscriptionSuccess,
  CREATED_SUBSCRIPTION_SUCCESS,
  RequestCreatedSubscriptionFail,
  CREATED_SUBSCRIPTION_FAIL,
  GET_STATEMENT_DELTA_REQUEST,
  GET_STATEMENT_DELTA_SUCCESS,
  GET_STATEMENT_DELTA_FAIL,
} from '../types/walletTypes';

export const requestBankInfo = (): RequestBankInfo => ({
  type: BANK_INFO_REQUEST,
});

export const requestBankInfoSuccess = (bankInfo: BankItem[]): RequestBankInfoSuccess => ({
  type: BANK_INFO_SUCCESS,
  payload: bankInfo,
});

export const requestBankInfoFail = (): RequestBankInfoFail => ({
  type: BANK_INFO_FAIL,
});

export const requestBanks = (): RequestBanks => ({
  type: BANKS_REQUEST,
});

export const requestBanksSuccess = (banks: Bank[]): RequestBanksSuccess => ({
  type: BANKS_SUCCESS,
  payload: banks,
});

export const requestBanksFail = (): RequestBanksFail => ({
  type: BANKS_FAIL,
});

export const requestSaveBankInfo = (): RequestSaveBankInfo => ({
  type: SAVE_BANK_INFO_REQUEST,
});

export const requestSaveBankInfoSuccess = (payload: SaveBankInfoSuccessPayload): RequestSaveBankInfoSuccess => ({
  type: SAVE_BANK_INFO_SUCCESS,
  payload,
});

export const requestSaveBankInfoFail = (): RequestSaveBankInfoFail => ({
  type: SAVE_BANK_INFO_FAIL,
});

export const requestDeleteBankInfo = (): RequestDeleteBankInfo => ({
  type: DELETE_BANK_INFO_REQUEST,
});

export const requestDeleteBankInfoSuccess = (): RequestDeleteBankInfoSuccess => ({
  type: DELETE_BANK_INFO_SUCCESS,
});

export const requestDeleteBankInfoFail = (): RequestDeleteBankInfoFail => ({
  type: DELETE_BANK_INFO_FAIL,
});

export const requestSetPrimaryBankAccount = (): RequestSetPrimaryBankAccount => ({
  type: SET_PRIMARY_BANK_ACCOUNT_REQUEST,
});

export const requestSetPrimaryBankAccountSuccess = (): RequestSetPrimaryBankAccountSuccess => ({
  type: SET_PRIMARY_BANK_ACCOUNT_SUCCESS,
});

export const requestSetPrimaryBankAccountFail = (): RequestSetPrimaryBankAccountFail => ({
  type: SET_PRIMARY_BANK_ACCOUNT_FAIL,
});

export const requestEditBankInfo = (): RequestEditBankInfo => ({
  type: EDIT_BANK_INFO_REQUEST,
});

export const requestEditBankInfoSuccess = (payload: BankItem): RequestEditBankInfoSuccess => ({
  type: EDIT_BANK_INFO_SUCCESS,
  payload,
});

export const requestEditBankInfoFail = (): RequestEditBankInfoFail => ({
  type: EDIT_BANK_INFO_FAIL,
});

export const requestWithdraw = (): RequestWithdraw => ({
  type: DO_WITHDRAW_REQUEST,
});

export const requestWithdrawSuccess = (): RequestWithdrawSuccess => ({
  type: DO_WITHDRAW_SUCCESS,
});

export const requestWithdrawFail = (payload: string): RequestWithdrawFail => ({
  type: DO_WITHDRAW_FAIL,
  payload,
});

export const fetchAnbMastercardConfig = (): FetchAnbMastercardConfig => ({
  type: FETCH_ANB_MASTERCARD_CONFIG_REQUEST,
});

export const fetchAnbMastercardConfigSuccess = (): FetchAnbMastercardConfigSuccess => ({
  type: FETCH_ANB_MASTERCARD_CONFIG_SUCCESS,
});

export const fetchAnbMastercardConfigFail = (payload: string): FetchAnbMastercardConfigFail => ({
  type: FETCH_ANB_MASTERCARD_CONFIG_FAIL,
  payload,
});

export const createMastercardSession = (): CreateMastercardSession => ({
  type: CREATE_MASTERCARD_SESSION_REQUEST,
});

export const createMastercardSessionSuccess = (): CreateMastercardSessionSuccess => ({
  type: CREATE_MASTERCARD_SESSION_SUCCESS,
});

export const createMastercardSessionFail = (payload: string): CreateMastercardSessionFail => ({
  type: CREATE_MASTERCARD_SESSION_FAIL,
  payload,
});

export const createHyperPayCheckout = (): CreateHyperPayCheckout => ({
  type: CREATE_HYPER_PAY_CHECKOUT_REQUEST,
});

export const createHyperPayCheckoutSuccess = (): CreateHyperPayCheckoutSuccess => ({
  type: CREATE_HYPER_PAY_CHECKOUT_SUCCESS,
});

export const createHyperPayCheckoutFail = (payload: string): CreateHyperPayCheckoutFail => ({
  type: CREATE_HYPER_PAY_CHECKOUT_FAIL,
  payload,
});

export const queryHyperPayPaymentStatus = (): QueryHyperPayPaymentStatus => ({
  type: QUERY_HYPER_PAY_PAYMENT_STATUS_REQUEST,
});

export const queryHyperPayPaymentStatusSuccess = (): QueryHyperPayPaymentStatusSuccess => ({
  type: QUERY_HYPER_PAY_PAYMENT_STATUS_SUCCESS,
});

export const queryHyperPayPaymentStatusFail = (payload: string): QueryHyperPayPaymentStatusFail => ({
  type: QUERY_HYPER_PAY_PAYMENT_STATUS_FAIL,
  payload,
});

export const queryHyperPayRegistrationStatus = (): QueryHyperPayRegistrationStatus => ({
  type: QUERY_HYPER_PAY_REGISTRATION_STATUS_REQUEST,
});

export const queryHyperPayRegistrationStatusSuccess = (): QueryHyperPayRegistrationStatusSuccess => ({
  type: QUERY_HYPER_PAY_REGISTRATION_STATUS_SUCCESS,
});

export const queryHyperPayRegistrationStatusFail = (payload: string): QueryHyperPayRegistrationStatusFail => ({
  type: QUERY_HYPER_PAY_REGISTRATION_STATUS_FAIL,
  payload,
});

export const requestSaveSubscription = (): RequestSaveSubscription => ({
  type: SAVE_SUBSCRIPTION_REQUEST,
});

export const requestSaveSubscriptionSuccess = (): RequestSaveSubscriptionSuccess => ({
  type: SAVE_SUBSCRIPTION_SUCCESS,
});

export const requestSaveSubscriptionFail = (): RequestSaveSubscriptionFail => ({
  type: SAVE_SUBSCRIPTION_FAIL,
});

export const requestSubscriptions = (): RequestSubscriptions => ({
  type: SUBSCRIPTIONS_REQUEST,
});

export const requestSubscriptionsSuccess = (subscriptions: SubscriptionItem[]): RequestSubscriptionsSuccess => ({
  type: SUBSCRIPTIONS_SUCCESS,
  payload: subscriptions,
});

export const requestSubscriptionsFail = (): RequestSubscriptionsFail => ({
  type: SUBSCRIPTIONS_FAIL,
});

export const requestSetPrimarySubscriptionCard = (): RequestSetPrimarySubscriptionCard => ({
  type: SET_PRIMARY_SUBSCRIPTION_CARD_REQUEST,
});

export const requestSetPrimarySubscriptionCardSuccess = (): RequestSetPrimarySubscriptionCardSuccess => ({
  type: SET_PRIMARY_SUBSCRIPTION_CARD_SUCCESS,
});

export const requestSetPrimarySubscriptionCardFail = (): RequestSetPrimarySubscriptionCardFail => ({
  type: SET_PRIMARY_SUBSCRIPTION_CARD_FAIL,
});

export const requestDeleteSubscription = (): RequestDeleteSubscription => ({
  type: DELETE_SUBSCRIPTION_REQUEST,
});

export const requestDeleteSubscriptionSuccess = (): RequestDeleteSubscriptionSuccess => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
});

export const requestDeleteSubscriptionFail = (): RequestDeleteSubscriptionFail => ({
  type: DELETE_SUBSCRIPTION_FAIL,
});

export const requestCreatedSubscription = (): RequestCreatedSubscription => ({
  type: CREATED_SUBSCRIPTION_REQUEST,
});

export const requestCreatedSubscriptionSuccess = (
  subscription: SubscriptionItem,
): RequestCreatedSubscriptionSuccess => ({
  type: CREATED_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});

export const requestCreatedSubscriptionFail = (): RequestCreatedSubscriptionFail => ({
  type: CREATED_SUBSCRIPTION_FAIL,
});

export const createApplePayPayment = (): CreateApplePayPayment => ({
  type: CREATE_APPLE_PAY_PAYMENT_REQUEST,
});

export const createApplePayPaymentSuccess = (): CreateApplePayPaymentSuccess => ({
  type: CREATE_APPLE_PAY_PAYMENT_SUCCESS,
});

export const createApplePayPaymentFail = (payload: string): CreateApplePayPaymentFail => ({
  type: CREATE_APPLE_PAY_PAYMENT_FAIL,
  payload,
});

export const createApplePaySession = (): CreateApplePaySession => ({
  type: CREATE_APPLE_PAY_SESSION_REQUEST,
});

export const createApplePaySessionSuccess = (): CreateApplePaySessionSuccess => ({
  type: CREATE_APPLE_PAY_SESSION_SUCCESS,
});

export const createApplePaySessionFail = (payload: string): CreateApplePaySessionFail => ({
  type: CREATE_APPLE_PAY_SESSION_FAIL,
  payload,
});

export const requestGetNetWithdraw = (): RequestGetNetWithdraw => ({
  type: GET_NET_WITHDRAW_REQUEST,
});

export const requestGetNetWithdrawSuccess = (payload: number): RequestGetNetWithdrawSuccess => ({
  type: GET_NET_WITHDRAW_SUCCESS,
  payload,
});

export const requestGetNetWithdrawFail = (payload: string): RequestGetNetWithdrawFail => ({
  type: GET_NET_WITHDRAW_FAIL,
  payload,
});

export const requestGetStatementDelta = (): RequestGetStatementDelta => ({
  type: GET_STATEMENT_DELTA_REQUEST,
});

export const requestGetStatementDeltaSuccess = (payload: number): RequestGetStatementDeltaSuccess => ({
  type: GET_STATEMENT_DELTA_SUCCESS,
  payload,
});

export const requestGetStatementDeltaFail = (payload: string): RequestGetStatementDeltaFail => ({
  type: GET_STATEMENT_DELTA_FAIL,
  payload,
});

export const clearWallet = (): ClearWallet => ({
  type: CLEAR_WALLET,
});

export const clearEditBankInfo = (): ClearEditBankInfo => ({
  type: CLEAR_EDIT_BANK_INFO,
});

export const fetchBankInfo = (): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestBankInfo());
  try {
    const response: any = await getBankInfo();

    dispatch(requestBankInfoSuccess(response.data));
  } catch (error) {
    dispatch(requestBankInfoFail());
    throw error;
  }
};

export const fetchBanks =
  (term: string): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestBanks());
    try {
      const response: any = await getBanks(term);

      dispatch(requestBanksSuccess(response.data));

      return response.data;
    } catch (error) {
      dispatch(requestBanksFail());
      throw error;
    }
  };

export const saveBankInfo =
  (payload: SaveBankInfoPayload): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestSaveBankInfo());
    try {
      const response: any = await saveUserBankInfo(payload);

      dispatch(requestSaveBankInfoSuccess(response.data));

      return response.data;
    } catch (error) {
      dispatch(requestSaveBankInfoFail());
      throw error;
    }
  };

export const deleteBankInfo =
  (id: number): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestDeleteBankInfo());
    try {
      await deleteUserBankInfo(id);

      dispatch(requestDeleteBankInfoSuccess());

      return true;
    } catch (error) {
      dispatch(requestDeleteBankInfoFail());
      throw error;
    }
  };

export const setPrimaryBankAccount =
  (id: number): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestSetPrimaryBankAccount());
    try {
      await setPrimaryUserBankAccount(id);

      dispatch(requestSetPrimaryBankAccountSuccess());

      return true;
    } catch (error) {
      dispatch(requestSetPrimaryBankAccountFail());
      throw error;
    }
  };

export const fetchEditBankInfo =
  (payload: number): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestEditBankInfo());
    try {
      const response: any = await getEditBankInfo(payload);

      dispatch(requestEditBankInfoSuccess(response.data));
    } catch (error) {
      dispatch(requestEditBankInfoFail());
      throw error;
    }
  };

export const doRequestWithdraw =
  (payload: WithdrawPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestWithdraw());
    try {
      const response = await doWithdraw(payload);

      dispatch(requestWithdrawSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(requestWithdrawFail(error.message));
    }
  };

export const fetchAnbMasterardConfigAction = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(fetchAnbMastercardConfig());
  try {
    const response = await getAnbMasterardConfig();

    dispatch(fetchAnbMastercardConfigSuccess());

    return response.data;
  } catch (error: any) {
    dispatch(fetchAnbMastercardConfigFail(error.message));
  }
};

export const createMastercardSessionAction =
  (payload: CreateMastercardSessionPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(createMastercardSession());
    try {
      const response = await doCreateMastercardSession(payload);

      dispatch(createMastercardSessionSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(createMastercardSessionFail(error.message));
    }
  };

export const createHyperPayCheckoutAction =
  (payload: CreateHyperPayCheckoutPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(createHyperPayCheckout());
    try {
      const response = await doCreateHyperPayCheckout(payload);

      dispatch(createHyperPayCheckoutSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(createHyperPayCheckoutFail(error.message));
    }
  };

export const queryHyperPayPaymentStatusAction =
  (payload: QueryHyperPayPaymentStatusPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(queryHyperPayPaymentStatus());
    try {
      const response = await doQueryHyperPayPaymentStatus(payload);

      dispatch(queryHyperPayPaymentStatusSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(queryHyperPayPaymentStatusFail(error.message));
    }
  };

export const queryHyperPayRegistrationStatusAction =
  (payload: QueryHyperPayRegistrationStatusPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(queryHyperPayRegistrationStatus());
    try {
      const response = await doQueryHyperPayRegistrationStatus(payload);

      dispatch(queryHyperPayRegistrationStatusSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(queryHyperPayRegistrationStatusFail(error.message));
    }
  };

export const fetchSubscriptions = (): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestSubscriptions());
  try {
    const response: any = await getSubscriptions();

    dispatch(requestSubscriptionsSuccess(response.data.items));
    return response.data.items;
  } catch (error) {
    dispatch(requestSubscriptionsFail());
    throw error;
  }
};

export const saveSubscription =
  (payload: SaveSubscriptionPayload): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestSaveSubscription());
    try {
      const response: any = await saveUserSubscription(payload);

      dispatch(requestSaveSubscriptionSuccess());

      return response.data;
    } catch (error) {
      dispatch(requestSaveSubscriptionFail());
      throw error;
    }
  };

export const setPrimarySubscriptionCard =
  (id: number): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestSetPrimarySubscriptionCard());
    try {
      await setPrimaryUserSubscriptionCard(id);

      dispatch(requestSetPrimarySubscriptionCardSuccess());

      return true;
    } catch (error) {
      dispatch(requestSetPrimarySubscriptionCardFail());
      throw error;
    }
  };

export const deleteSubscription =
  (id: number): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestDeleteSubscription());
    try {
      await deleteUserSubscription(id);

      dispatch(requestDeleteSubscriptionSuccess());

      return true;
    } catch (error) {
      dispatch(requestDeleteSubscriptionFail());
      throw error;
    }
  };

export const fetchCreatedSubscription = (): ThunkAction<void, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestCreatedSubscription());
  try {
    const response: any = await getCreatedSubscription();

    dispatch(requestCreatedSubscriptionSuccess(response.data));
    return response.data;
  } catch (error) {
    dispatch(requestCreatedSubscriptionFail());
    throw error;
  }
};

export const createApplePayPaymentAction =
  (payload: CreateApplePayPaymentPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(createApplePayPayment());
    try {
      const response = await doCreateApplePayPayment(payload);

      dispatch(createApplePayPaymentSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(createApplePayPaymentFail(error.message));
    }
  };

export const createApplePaySessionAction =
  (payload: CreateApplePaySessionPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(createApplePaySession());
    try {
      const response = await doCreateApplePaySession(payload);

      dispatch(createApplePaySessionSuccess());

      return response.data;
    } catch (error: any) {
      dispatch(createApplePaySessionFail(error.message));
    }
  };

export const fetchNetWithdraw = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestGetNetWithdraw());
  try {
    const response = await getNetWithdraw();

    dispatch(requestGetNetWithdrawSuccess(response.data?.amount));

    return response.data;
  } catch (error: any) {
    dispatch(requestGetNetWithdrawFail(error.message));
  }
};

export const fetchStatementDelta = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestGetStatementDelta());
  try {
    const response = await getStatementDelta();

    dispatch(requestGetStatementDeltaSuccess(response.data?.amount));

    return response.data;
  } catch (error: any) {
    dispatch(requestGetStatementDeltaFail(error.message));
  }
};
