import { Tutorial } from '../types/tutorial';

export const TUTORIALS: Record<string, Tutorial[]> = {
  en: [
    {
      id: 1,
      title: 'Get to know the automated advisor',
      description:
        'Madkhol is a financial technology company licensed by the Capital Market Authority and compliant with the provisions of Islamic Sharia, specializing in financial planning and wealth management',
      url: 'https://www.youtube.com/embed/3JHiS5Inke0?controls=1&rel=0&mute=1&autoplay=1',
      urlToGetThumbnail: 'https://www.youtube.com/watch?v=lZxPRxUUyRU',
      uploadDate: '23/05/2022',
    },
  ],
  ar: [
    {
      id: 1,
      title: 'تعرف على مستشار مدخول الآلي',
      description:
        'مدخول هي شركة تقنية مالية مرخصة من هيئة سوق المال ومتوافقة مع أحكام الشريعة الإسلامية، تتخصص في التخطيط المالي وإدارة الثروات',
      url: 'https://www.youtube.com/embed/lZxPRxUUyRU?controls=1&rel=0&mute=1&autoplay=1',
      urlToGetThumbnail: 'https://www.youtube.com/watch?v=lZxPRxUUyRU',
      uploadDate: '27/05/2022',
    },
  ],
};
