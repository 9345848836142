import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import HotTubIcon from '@mui/icons-material/HotTub';
import ReplayIcon from '@mui/icons-material/Replay';
import { locations } from '../../routes/locations';
import MButton from '../../shared/MButton';

export const Error = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate(locations.home());
    window.location.href = locations.home();
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ height: '100vh' }}>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        rowSpacing="16px"
        justifyContent="center"
        height="100%"
      >
        <Grid item>
          <HotTubIcon sx={{ fontSize: 80 }} />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h3" color="text.primary">
            Not Found
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h6" variant="body2" color="text.secondary">
            Either you typed a wrong URL, or you followed a bad link.
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <MButton id="ErrorGoHomeButton" variant="outlined" buttonProps={{ color: 'primary' }} onClick={goHome}>
            <ReplayIcon sx={{ mr: '12px' }} />
            Go Home
          </MButton>
        </Grid>
      </Grid>
    </Container>
  );
};
