import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Carousel from 'react-material-ui-carousel';
import StarIcon from '@mui/icons-material/Star';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import COLORS from '../constants/colors';
import { authSelector } from '../redux/features/auth/auth.selectors';
import { locations } from '../routes/locations';
import { ReduxState, useTypedDispatch } from '../redux/store';
import PortfolioTargetAllocationTable, { TargetAllocationTableRef } from './PortfolioTargetAllocationTable';
import PortfolioDoughnutChart from './PortfolioDoughnutChart';
import {
  PortfolioAssociatedIndexFund,
  RecommendedPortfolio,
  SubscribedPortfolio,
  SaveUserPrefrancePortfolio,
} from '../store_deprecated/types/portfolioTypes';
import SubscribePortfolioDialog from '../components/User/Invest/SubscribePortfolioDialog';
import { subscribedToPortfolio, resetSubscribedToPortfolio } from '../store_deprecated/actions/portfolioActions';
import {
  getAvailablePortfoliosSelector,
  getSubscribeToPortfolioSelector,
} from '../store_deprecated/selectors/portfolioSelectors';
import Loader from './Loader';
import MButton from './MButton';
import { formatNumber } from '../utils/number';
import AddMoreFundDialog from './AddMoreFundDialog';
import * as LocalStorage from '../helpers/LocalStorage';
import { UserAgreementName } from '../store_deprecated/types/userAgreementTypes';
import { agreementSignedSelector } from '../store_deprecated/selectors/agreementSelectors';
import ChangeAllocationAgreementDialog from './ChangeAllocationAgreementDialog';
import { DepositProps } from '../components/User/MyWallet/Deposit';
import { clearWallet, fetchAnbMasterardConfigAction } from '../store_deprecated/actions/walletActions';
import { fetchUserPreferenceByType, saveUserPreference } from '../redux/modules/userPreference/userPreference.actions';
import { UserPreferenceTypeEnum } from '../types/userPreference';

interface RecommendedPortfoliosViewProps {
  cashAllocationPercentage: number;
  recommendedPortfolio?: RecommendedPortfolio;
  subscribedPortfolio?: SubscribedPortfolio;
  loading?: boolean;
  savedRecommendedPortfolioData?: SaveUserPrefrancePortfolio;
}

const RecommendedPortfoliosView: React.FC<RecommendedPortfoliosViewProps> = (props) => {
  const {
    recommendedPortfolio,
    subscribedPortfolio,
    cashAllocationPercentage: configCashAllocationPercentage,
    loading,
    savedRecommendedPortfolioData,
  } = props;
  // const stocksPercentage = 90;
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const isEnglish = i18n.language === 'en';
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(authSelector);
  const matchTabletAndAbove = useMediaQuery('(min-width:900px)');
  const [currentIndex, setCurrentIndex] = useState(0);
  const targetAllocationTableRef = useRef<TargetAllocationTableRef>(null);
  const [showSuccessfulScreen, setShowSuccessfulScreen] = useState(true);
  const [openSubscribeDialog, setOpenSubscribeDialog] = React.useState(false);
  const [openAddMoreFundDialog, setOpenAddMoreFundDialog] = React.useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [openAllocationAgreementDialog, setOpenAllocationAgreementDialog] = useState(false);
  const [successWeightSnackbar, setSuccessWeightSnackbar] = useState(false);
  const [isPortfolioChanged, setIsPortfolioChanged] = useState(false);
  const [associatedIndexFunds, setAssociatedIndexFunds] = React.useState<
    Pick<PortfolioAssociatedIndexFund, 'id' | 'weight'>[]
  >([]);
  const signAllocationAgreementDate = useSelector((state: ReduxState) =>
    agreementSignedSelector(state, UserAgreementName.CHANGE_ALLOCATION_AGREEMENT),
  );
  const availablePortfolios = useSelector(getAvailablePortfoliosSelector);

  const userSelectedPerferencePortFolio = availablePortfolios.data.items.find(
    (ap) => ap.id === savedRecommendedPortfolioData?.portfolioId,
  );
  const [currentPortfolio, setCurrentPortfolio] = useState(userSelectedPerferencePortFolio || recommendedPortfolio);

  const cashAllocationPercentage = subscribedPortfolio?.id
    ? subscribedPortfolio?.userPortfolios?.[0]?.cacheAllocationPercentage
    : configCashAllocationPercentage;

  const subscribeToPortfolio = useSelector(getSubscribeToPortfolioSelector);

  const risk = isEnglish
    ? (currentPortfolio as any)?.userCategory?.nameEn || currentPortfolio?.nameEn?.replace('Portfolio', '').trim()
    : (currentPortfolio as any)?.userCategory?.nameAr || currentPortfolio?.nameAr;

  const [depositConfig, setDepositConfig] = useState<DepositProps | null>(null);

  useEffect(() => {
    const index = availablePortfolios.data.items.findIndex(
      (ap) => ap.id === savedRecommendedPortfolioData?.portfolioId,
    );

    setCurrentIndex(index);
    setIsRecommended(currentPortfolio?.nameEn === recommendedPortfolio?.nameEn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePortfolios.data.items]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const anbMastercardConfig = await dispatch(fetchAnbMasterardConfigAction());

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
            // openCongratulationModal,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();

    return () => {
      dispatch(clearWallet());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveUserPreferenceCall = useCallback(
    debounce(async (item: any) => {
      await dispatch(
        saveUserPreference({
          type: UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO,
          value: JSON.stringify({
            portfolioId: item?.id,
            associatedIndexFunds: item?.associatedIndexFunds,
          }),
        }),
      );
      dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO));
      setSuccessWeightSnackbar(true);
    }, 1000),
    [],
  );

  const portfolioIterator = (() => {
    const getLabelText = (portfolio: RecommendedPortfolio) => {
      if (isEnglish) {
        return portfolio?.nameEn?.replace('Portfolio', '').trim();
      }

      return portfolio?.nameAr;
    };

    const { data } = availablePortfolios;

    return {
      hasPrevious() {
        return currentIndex !== 0;
      },
      hasNext() {
        return currentIndex + 1 !== availablePortfolios.data.totalItems;
      },
      setPortfolio(index: any) {
        setCurrentIndex(index);
        setCurrentPortfolio(data.items[index]);
        saveUserPreferenceCall(data.items[index]);
      },
      setNextPortfolio() {
        const index = currentIndex + 1 !== data.totalItems ? currentIndex + 1 : 0;

        setCurrentIndex(index);
        setCurrentPortfolio(data.items[index]);
      },
      setPreviousPortfolio() {
        const index = currentIndex !== 0 ? currentIndex - 1 : data.totalItems - 1;

        setCurrentIndex(index);
        setCurrentPortfolio(data.items[index]);
      },
      nextPortfolio() {
        const nextPortfolio = availablePortfolios.data.items[currentIndex + 1];

        return getLabelText(nextPortfolio);
      },
      previousPortfolio() {
        const previousPortfolio = availablePortfolios.data.items[currentIndex - 1];

        return getLabelText(previousPortfolio);
      },
    };
  })();

  const closeAllocationAgreementDialog = () => {
    setOpenSubscribeDialog(false);
    setOpenAllocationAgreementDialog(false);
  };

  const onCloseSubscribeDialog = async (fundAmount?: number) => {
    if (!isRecommended && !signAllocationAgreementDate) {
      return setOpenAllocationAgreementDialog(true);
    }

    if (fundAmount && !subscribedPortfolio && currentPortfolio?.id) {
      const result = (await dispatch(
        subscribedToPortfolio({
          associatedIndexFunds,
          portfolioId: currentPortfolio.id,
          fundAmount,
        }),
      )) as any;

      if (result && user?.id) {
        setShowSuccessfulScreen(true);
        dispatch(resetSubscribedToPortfolio());

        /**
         * Clear the allocation changes
         */
        LocalStorage.removeRecommendedPortfolioAllocations(user.id, currentPortfolio.id);
        LocalStorage.removeSelectedPortfolio(user.id);
      }
    } else {
      setOpenSubscribeDialog(false);
      dispatch(resetSubscribedToPortfolio());
    }
  };

  const closeDialog = (success?: boolean) => {
    setOpenSubscribeDialog(false);
    setOpenAddMoreFundDialog(false);
    if (success) {
      setShowSuccessfulScreen(true);
      dispatch(resetSubscribedToPortfolio());
    }
  };

  const onSuccessButtonClick = () => {
    closeDialog();
    navigate(locations.dashboard());
  };

  const subscribeProfile = () => {
    setShowSuccessfulScreen(false);
    if (subscribedPortfolio?.id) {
      setOpenAddMoreFundDialog(true);
    } else {
      setOpenSubscribeDialog(true);
    }
  };

  const onChangeWeights = (items: Pick<PortfolioAssociatedIndexFund, 'id' | 'weight'>[]) => {
    setAssociatedIndexFunds(items);
  };

  const onCloseUpdatedPortfolioNotification = () => {
    const portfolio = availablePortfolios.data.items.find((p) => p.id === currentPortfolio?.id);
    LocalStorage.setRecommendedPortfolioAllocations(
      user!.id,
      portfolio!.id,
      portfolio!.associatedIndexFunds.map((aif) => ({
        id: aif.id,
        weight: aif.weight,
      })),
    );
    setIsPortfolioChanged(false);
    setCurrentPortfolio(portfolio!);
    setAssociatedIndexFunds(
      portfolio!.associatedIndexFunds.map((aif) => ({
        id: aif.id,
        weight: aif.weight,
      })),
    );
  };

  useEffect(() => {
    if (!currentPortfolio) {
      setCurrentPortfolio((subscribedPortfolio || recommendedPortfolio) as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedPortfolio, subscribedPortfolio]);

  useEffect(() => {
    setIsRecommended(currentPortfolio?.nameEn === recommendedPortfolio?.nameEn);
    if (user?.id) {
      LocalStorage.setSelectedPortfolio(user.id, currentPortfolio as any);
    }

    if (currentPortfolio?.id && availablePortfolios?.data?.items.length && !subscribedPortfolio) {
      const portfolio = availablePortfolios.data.items.find((p) => p.id === currentPortfolio.id);

      const currentPortfolioIndexFundIds = currentPortfolio.associatedIndexFunds.map((aif) => aif.id).sort();
      const recommendedPortfolioIndexFundIds = portfolio
        ? portfolio.associatedIndexFunds.map((aif) => aif.id).sort()
        : null;

      setIsPortfolioChanged(
        JSON.stringify(currentPortfolioIndexFundIds) !== JSON.stringify(recommendedPortfolioIndexFundIds),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPortfolio, recommendedPortfolio, subscribedPortfolio]);

  useEffect(() => {
    if (targetAllocationTableRef.current) {
      setIsValid(targetAllocationTableRef.current.getTotalAllocations() === 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedIndexFunds, targetAllocationTableRef.current]);

  if (!availablePortfolios.data.totalItems) return <Loader />;

  return (
    <>
      <Grid
        item
        container
        flexDirection={{ xs: 'row', md: 'column' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: { xs: '24px', md: '80px 37px' },
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
          borderRadius: '24px',
          backgroundColor: COLORS.WHITE,
        }}
        component={Paper}
        elevation={0}
        maxWidth="100%"
      >
        <Grid item sx={{ pb: { xs: '0px', md: '48px' } }} maxWidth="100%">
          <Badge
            badgeContent={
              <>
                {t('recommended')}
                <StarIcon sx={{ color: COLORS.WHITE, width: '16px' }} />
              </>
            }
            invisible={(!isRecommended && !loading) || !availablePortfolios.data.items.length}
            color="secondary"
            sx={{
              '& .MuiBadge-badge': {
                top: { xs: '-2px', md: '-5px !important' },
                fontSize: { xs: '10px', md: '12px' },
                fontWeight: 700,
                color: COLORS.WHITE,
              },
            }}
          >
            <Typography variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'} gutterBottom id="PortfoliosTitle">
              {isEnglish ? currentPortfolio?.nameEn : currentPortfolio?.nameAr}
            </Typography>
          </Badge>
        </Grid>
        <Grid
          item
          sx={{
            '@media screen and (orientation: portrait)': {
              pb: { xs: '0px', md: '48px' },
            },
            '@media screen and (orientation: landscape)': {
              pb: { xs: '90px', md: '48px' },
            },
          }}
          maxWidth="100%"
        >
          <Typography
            sx={{
              color: COLORS.DARK_GREY,
              fontWeight: 400,
              fontSize: { xs: '0.875rem', md: '1.125rem' },
              lineHeight: '28px',
              marginTop: 6,
              marginBottom: { xs: 0, md: 6 },
              textAlign: { xs: 'left', md: 'center' },
            }}
            id="PortfoliosDescription"
          >
            {isEnglish ? currentPortfolio?.descriptionEn : currentPortfolio?.descriptionAr}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            mb: { xs: '30px', md: 0 },
            maxWidth: {
              xs: '100% !important',
              sm: '100% !important',
              md: '100% !important',
              lg: '70% !important',
            },
            minHeight: {
              xs: '50vh !important',
              sm: '60vh !important',
              md: '50vh !important',
              lg: '50vh !important',
            },
            maxHeight: {
              xs: '50vh !important',
              sm: '60vh !important',
              md: '50vh !important',
              lg: '50vh !important',
            },
            marginTop: { xs: '-60px', sm: '-100px' },
            marginBottom: { xs: '-50px', sm: '-60px' },
          }}
          maxWidth="100%"
        >
          {loading && <Loader />}
          {!loading && availablePortfolios.data.items.length ? (
            <Carousel
              indicators={!subscribedPortfolio?.id}
              index={currentIndex}
              autoPlay={false}
              navButtonsAlwaysVisible={!subscribedPortfolio?.id && matchTabletAndAbove}
              next={portfolioIterator.setNextPortfolio}
              prev={portfolioIterator.setPreviousPortfolio}
              onChange={portfolioIterator.setPortfolio}
              sx={{ width: '100%' }}
              navButtonsProps={{
                style: {
                  transform: isEnglish ? 'rotate(0deg)' : 'rotate(180deg)',
                },
              }}
            >
              {availablePortfolios.data.items.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    m: 'auto',
                    maxWidth: {
                      xs: '100% !important',
                      sm: '80% !important',
                      md: '100% !important',
                      lg: '80% !important',
                    },
                    minHeight: {
                      xs: '30vh !important',
                      sm: '30vh !important',
                      md: '30vh !important',
                      lg: '30vh !important',
                    },
                    maxHeight: {
                      xs: '30vh !important',
                      sm: '30vh !important',
                      md: '30vh !important',
                      lg: '30vh !important',
                    },
                  }}
                >
                  <PortfolioDoughnutChart
                    portfolio={currentPortfolio}
                    cashAllocationPercentage={cashAllocationPercentage}
                    shownInSlider
                  />
                </Box>
              ))}
            </Carousel>
          ) : null}
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth={350}
          sx={{
            pb: '48px',
            mt: { xs: '0px', lg: '40px' },
          }}
        >
          <Grid item xs={6} textAlign="left">
            <Typography
              sx={{
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
                fontSize: { xs: '0.875rem', md: '1rem' },
                lineHeight: '24px',
              }}
            >
              {t('risk')}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography
              sx={{
                color: COLORS.MAIN_DARK,
                fontWeight: 500,
                fontSize: 'clamp(0.875rem,0.5vw,1.125rem)',
                lineHeight: '28px',
              }}
            >
              {risk}
            </Typography>
          </Grid>
          {/* <Grid item xs={6} textAlign="left"> */}
          {/*  <Typography */}
          {/*    sx={{ */}
          {/*      color: COLORS.LIGHT_GRAY, */}
          {/*      fontWeight: 400, */}
          {/*      fontSize: { xs: "0.875rem", md: "1rem" }, */}
          {/*      lineHeight: "24px", */}
          {/*    }} */}
          {/*  > */}
          {/*    {t("targetAllocation")} */}
          {/*  </Typography> */}
          {/* </Grid> */}
          {/* <Grid item xs={6} textAlign="right"> */}
          {/*  <Typography */}
          {/*    sx={{ */}
          {/*      color: COLORS.MAIN_DARK, */}
          {/*      fontWeight: 500, */}
          {/*      fontSize: "clamp(0.875rem,0.5vw,1.125rem)", */}
          {/*      lineHeight: "28px", */}
          {/*    }} */}
          {/*  > */}
          {/*    {`${formatNumber(stocksPercentage)}% ${t("stocks")}`} */}
          {/*  </Typography> */}
          {/* </Grid> */}
          <Grid item xs={6} textAlign="left">
            <Typography
              sx={{
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
                fontSize: { xs: '0.875rem', md: '1rem' },
                lineHeight: '24px',
              }}
            >
              {t('expectedReturn')}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography
              sx={{
                color: COLORS.MAIN_DARK,
                fontWeight: 500,
                fontSize: 'clamp(0.875rem,0.5vw,1.125rem)',
                lineHeight: '28px',
              }}
            >
              {formatNumber(currentPortfolio?.targetExpectedReturn || 0, i18n.language, 2)}%
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography
              sx={{
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
                fontSize: { xs: '0.875rem', md: '1rem' },
                lineHeight: '24px',
              }}
            >
              {t('standardDeviation')}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography
              sx={{
                color: COLORS.MAIN_DARK,
                fontWeight: 500,
                fontSize: 'clamp(0.875rem,0.5vw,1.125rem)',
                lineHeight: '28px',
              }}
            >
              {formatNumber(currentPortfolio?.targetExpectedRisk || 0, i18n.language, 2)}%
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <Typography
              sx={{
                color: COLORS.LIGHT_GRAY,
                fontWeight: 400,
                fontSize: { xs: '0.875rem', md: '1rem' },
                lineHeight: '24px',
              }}
            >
              {t('sharpRatio')}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Typography
              sx={{
                color: COLORS.MAIN_DARK,
                fontWeight: 500,
                fontSize: 'clamp(0.875rem,0.5vw,1.125rem)',
                lineHeight: '28px',
              }}
            >
              {formatNumber(currentPortfolio?.sharpRatio || 0, i18n.language, 2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: '100%',
            pt: { xs: '0px !important', md: '37px !important' },
            mb: '48px',
          }}
        >
          <PortfolioTargetAllocationTable
            ref={targetAllocationTableRef}
            portfolio={currentPortfolio}
            onChangeWeights={onChangeWeights}
            allowEdit={Boolean(!subscribedPortfolio?.id)}
            cashAllocationPercentage={cashAllocationPercentage}
            savedRecommendedPortfolioData={savedRecommendedPortfolioData}
          />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <MButton
            variant="contained"
            fullWidth
            onClick={subscribeProfile}
            disabled={!isValid}
            id={subscribedPortfolio?.id ? 'AddMoreFundButton' : 'SubscribeProfileButton'}
            buttonProps={{
              sx: {
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: COLORS?.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              },
            }}
          >
            {t(subscribedPortfolio?.id ? 'portfolioDetails.addMoreFund' : 'investButton')}
          </MButton>
        </Grid>
        <Grid item sx={{ mt: 4, width: '100%' }} position="sticky">
          <MButton
            variant="outlined"
            fullWidth
            onClick={() => targetAllocationTableRef.current?.resetWeights()}
            id="ResetAllocationWeights"
            buttonProps={{
              sx: {
                display: subscribedPortfolio?.id ? 'none' : 'unset',
                height: 60,
                borderRadius: '80px',
                boxShadow: 'none',
              },
            }}
            typographyProps={{
              sx: {
                fontSize: { xs: '14px', md: '20px' },
                padding: { xs: '8px', md: '16px 48px' },
              },
            }}
          >
            {t('resetAllocationWeights')}
          </MButton>
        </Grid>
      </Grid>

      <SubscribePortfolioDialog
        open={openSubscribeDialog}
        showSuccessfulScreen={showSuccessfulScreen}
        closeDialog={closeDialog}
        onClose={onCloseSubscribeDialog}
        onSuccessButtonClick={onSuccessButtonClick}
        error={tApiError(subscribeToPortfolio.error)}
        isLoading={subscribeToPortfolio.isLoading}
      />
      <AddMoreFundDialog
        open={openAddMoreFundDialog}
        onSuccessButtonClick={onSuccessButtonClick}
        showSuccessfulScreen={showSuccessfulScreen}
        closeDialog={closeDialog}
        depositConfig={depositConfig}
        subscribedPortfolio={subscribedPortfolio}
      />
      <ChangeAllocationAgreementDialog open={openAllocationAgreementDialog} onClose={closeAllocationAgreementDialog} />
      <Snackbar
        sx={{
          maxWidth: 'unset',
          minWidth: 'unset',
          width: 'fit-content',
          m: 'auto',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
          },
        }}
        open={isPortfolioChanged}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onCloseUpdatedPortfolioNotification}
      >
        <Alert severity="warning" variant="filled" sx={{ width: '100%' }} onClose={onCloseUpdatedPortfolioNotification}>
          {t('updatedPortfolio')}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={successWeightSnackbar}
        autoHideDuration={2000}
        sx={{
          maxWidth: 'unset',
          minWidth: 'unset',
          width: 'fit-content',
          m: 'auto',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
          },
        }}
        onClose={() => setSuccessWeightSnackbar(false)}
      >
        <Alert
          severity="success"
          variant="outlined"
          sx={{ bgcolor: '#7EA3561F', width: '100%', position: 'relative', zIndex: '9999' }}
          onClose={() => setSuccessWeightSnackbar(false)}
        >
          {t('weightChangeSuccessFullMessage')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RecommendedPortfoliosView;
