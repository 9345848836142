import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { resetPasswordNewFormFields } from '../../../forms/fields/formFields';
import { resetPasswordNewFormSchema } from '../../../forms/validationSchema/formSchema';
import PasswordField from '../Signup/PasswordField';
import { PasswordValidationRulesWithConfirmPassword } from '../../../constants/PasswordValidationRules';
import PasswordFieldRoles from '../Signup/PasswordFieldRoles';
import { validate } from '../../../utils/validate';
import MButton from '../../../shared/MButton';
import PasswordValidationStatus from '../../../constants/PasswordValidationStatus';

interface ResetPasswordNewFormProps {
  errorMessage?: string;
  onSubmit: (newPassword: string) => void;
  onCancel: () => void;
}

const ResetPasswordNewForm = ({ onSubmit, errorMessage, onCancel }: ResetPasswordNewFormProps) => {
  const { t, i18n } = useTranslation('auth', { useSuspense: true });
  const isEnglish = i18n.language === 'en';
  const [passwordValidation, setPasswordValidation] = useState({
    ...PasswordValidationRulesWithConfirmPassword,
  });

  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState({
    [PasswordValidationStatus.PASSWORD_AND_CONFIRM_PASSWORD_DOES_MATCH]:
      PasswordValidationRulesWithConfirmPassword[PasswordValidationStatus.PASSWORD_AND_CONFIRM_PASSWORD_DOES_MATCH],
  });

  const { handleSubmit, values, isValid, handleChange } = useFormik({
    initialValues: {
      [resetPasswordNewFormFields.newPassword]: '',
      [resetPasswordNewFormFields.confirmNewPassword]: '',
    },
    validationSchema: resetPasswordNewFormSchema,
    onSubmit: (formValues) => {
      onSubmit(formValues.newPassword);
    },
  });

  useEffect(() => {
    const errors: any = validate({
      type: 'password',
      value: values[resetPasswordNewFormFields.newPassword],
    });

    // @ts-ignore
    Object.keys(passwordValidation).forEach((condition: string) => {
      const rules = passwordValidation;
      // @ts-ignore
      rules[condition].isValid = !errors.includes(condition);
      setPasswordValidation(rules);
    });

    const confirmErrors: any =
      values[resetPasswordNewFormFields.newPassword] === values[resetPasswordNewFormFields.confirmNewPassword] &&
      values[resetPasswordNewFormFields.confirmNewPassword] !== ''
        ? PasswordValidationStatus.PASSWORD_AND_CONFIRM_PASSWORD_DOES_MATCH
        : '';

    // @ts-ignore
    Object.keys(confirmPasswordValidation).forEach((condition: string) => {
      const rules = confirmPasswordValidation;
      // @ts-ignore
      rules[condition].isValid = confirmErrors.includes(condition);
      setConfirmPasswordValidation(rules);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[resetPasswordNewFormFields.newPassword],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values[resetPasswordNewFormFields.confirmNewPassword],
  ]);

  const handleOnChange = (e: any) => {
    e.target.value = e.target.value.replace(/\s/g, '');
    handleChange(e);
  };

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" sx={{ pt: '9%', pb: '32px' }}>
      <Grid item sx={{ mb: '24px' }}>
        <Typography
          component="h1"
          variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'}
          sx={{ textTransform: 'capitalize', mb: '24px' }}
        >
          {t('restorePassword')}
        </Typography>
        {/* <Typography */}
        {/*  variant={isEnglish ? "bodyLarge" : "cairoBody"} */}
        {/*  color={COLORS.LIGHT_GRAY} */}
        {/* > */}
        {/*  Lorem ipsum dolor sit amet adipiscing elit. */}
        {/* </Typography> */}
      </Grid>
      <Grid
        item
        xs={10}
        md={6}
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '32px 48px',
          width: '100%',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05);',
          borderRadius: '12px',
        }}
      >
        <Grid item sx={{ mb: '24px' }}>
          <Typography component="h1" variant="h6" sx={{ textTransform: 'capitalize', mb: '4px' }}>
            {t('pleaseMakeSureToFollowThesePoints')}
          </Typography>
        </Grid>
        <Grid container direction="row" sx={{ mt: 1 }}>
          <PasswordFieldRoles
            isArabic={!isEnglish}
            password={values[resetPasswordNewFormFields.newPassword]}
            passwordValidation={passwordValidation}
          />
        </Grid>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: '16px', width: '100%' }}>
          <PasswordField
            isArabic={!isEnglish}
            label="newPassword"
            id={resetPasswordNewFormFields.newPassword}
            name={resetPasswordNewFormFields.newPassword}
            password={values[resetPasswordNewFormFields.newPassword]}
            handleChangePassword={handleOnChange}
          />
          <Box sx={{ mb: '16px' }} />
          <PasswordField
            isArabic={!isEnglish}
            label="reEnterNewPassword"
            id={resetPasswordNewFormFields.confirmNewPassword}
            name={resetPasswordNewFormFields.confirmNewPassword}
            password={values[resetPasswordNewFormFields.confirmNewPassword]}
            handleChangePassword={handleOnChange}
          />
          <Typography
            component="h5"
            variant="body2"
            className={errorMessage ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
            color="error"
            sx={{ mb: '24px' }}
          >
            {errorMessage}
          </Typography>
          <MButton
            fullWidth
            variant="contained"
            disabled={!isValid}
            buttonProps={{
              type: 'submit',
              sx: {
                height: { xs: '50px', md: 60 },
              },
            }}
          >
            {t('saveNewPassword')}
          </MButton>
          <MButton
            variant="text"
            onClick={onCancel}
            buttonProps={{
              sx: {
                mt: '24px',
                boxShadow: 'none',
                height: { xs: '50px', md: 60 },
              },
            }}
            fullWidth
          >
            {t('translation:cancel')}
          </MButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordNewForm;
