import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PhoneInput from 'react-phone-number-input';
import { CustomTextField } from '../../../shared/CustomTextField';
import COLORS from '../../../constants/colors';
import 'react-phone-number-input/style.css';
import './mobile-field.css';

interface MobileFieldProps {
  isArabic: boolean;
  mobileNumber: string;
  mobileNumberInvalidMessage: string;
  handleChangeMobile: (mobile: string) => void;
  handleMobileValidation: () => void;
}

const MobileField: React.FC<MobileFieldProps> = (props) => {
  const { isArabic, mobileNumber, mobileNumberInvalidMessage, handleChangeMobile, handleMobileValidation } = props;
  const { t } = useTranslation('auth', { useSuspense: true });

  return (
    <>
      <Typography
        variant={isArabic ? 'cairoR16' : 'bodyMedium'}
        color={COLORS.MAIN_DARK}
        textAlign="justify"
        paragraph
        sx={{ marginBottom: 1 }}
        fontWeight={500}
      >
        {t('signUpFormMobileLabel')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '62px',
            height: '53px',
            border: '1px solid #D1D5DB',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: '4px',
            mb: 'auto',
            p: 4,
          }}
        >
          <img src="/images/auth/saudi-arabia.svg" alt="Saudi Arabia Flag" />
        </Box>
        <PhoneInput
          value={mobileNumber}
          onChange={handleChangeMobile}
          onBlur={handleMobileValidation}
          international
          withCountryCallingCode
          countryCallingCodeEditable={false}
          country="SA"
          id="phone"
          name="phone"
          defaultCountry="SA"
          countries={['SA']}
          inputComponent={CustomTextField}
          style={{ width: '100%' }}
          numberInputProps={{
            id: 'mobileNumber',
            variant: 'outlined',
            fullWidth: true,
            placeholder: t('signUpFormMobilePlaceholder'),
            autoComplete: 'tel',
            sx: {
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                '& fieldset': {
                  borderColor: COLORS.XLIGHT_GRAY,
                },
              },
              '& .MuiFormHelperText-root': {
                fontFamily: isArabic ? 'Noto Kufi Arabic' : 'Montserrat',
                position: 'absolute',
                bottom: '-23px',
              },
            },
            InputProps: {
              style: {
                fontFamily: isArabic ? 'Noto Kufi Arabic' : 'Montserrat',
                fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
              },
            },
            error: mobileNumberInvalidMessage !== '',
            helperText: t(mobileNumberInvalidMessage),
          }}
        />
      </Box>
    </>
  );
};

export default MobileField;
