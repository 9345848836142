import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import COLORS from '../../../constants/colors';

interface FundAmountFieldProps {
  isArabic: boolean;
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const FundAmountField: React.FC<FundAmountFieldProps> = ({ isArabic, value, handleChange }) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={{ width: '100%' }} variant="outlined">
      <Typography
        variant={isArabic ? 'cairoM' : 'bodyMedium'}
        color={COLORS.MAIN_DARK}
        textAlign="justify"
        paragraph
        sx={{ marginBottom: 1 }}
      >
        {t('invest.subscribePortfolioDialog.foundAmountFieldLabel')}
      </Typography>
      <InputBase
        required
        fullWidth
        id="fundAmount"
        name="fundAmount"
        type="text"
        value={value}
        placeholder={t('invest.subscribePortfolioDialog.foundAmountFieldPlaceholder')}
        autoFocus
        onChange={handleChange}
        sx={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: COLORS.XLIGHT_GRAY,
          borderRadius: '8px',
          height: '100%',
          '&:hover': {
            borderColor: 'black',
          },
        }}
        inputProps={{ style: { padding: 16 } }}
        endAdornment={
          <Typography
            variant={isArabic ? 'cairoM' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="center"
            paragraph
            sx={{ m: 0, mr: 2 }}
          >
            {t('currency.SAR')}
          </Typography>
        }
      />
    </FormControl>
  );
};

export default FundAmountField;
