import { Logout } from './authTypes';

export const START_SURVEY_REQUEST = 'START_SURVEY_REQUEST';
export const START_SURVEY_SUCCESS = 'START_SURVEY_SUCCESS';
export const START_SURVEY_FAIL = 'START_SURVEY_FAIL';
export const CURRENT_QUESTION_REQUEST = 'CURRENT_QUESTION_REQUEST';
export const CURRENT_QUESTION_SUCCESS = 'CURRENT_QUESTION_SUCCESS';
export const CURRENT_QUESTION_FAIL = 'CURRENT_QUESTION_FAIL';
export const ANSWER_QUESTION_SUCCESS = 'ANSWER_QUESTION_SUCCESS';
export const ANSWER_QUESTION_REQUEST = 'ANSWER_QUESTION_REQUEST';
export const ANSWER_QUESTION_FAIL = 'ANSWER_QUESTION_FAIL';
export const CONFIG_SUCCESS = 'CONFIG_SUCCESS';
export const CONFIG_REQUEST = 'CONFIG_REQUEST';
export const CONFIG_FAIL = 'CONFIG_FAIL';
export const ANSWERED_QUESTION_SUCCESS = 'ANSWERED_QUESTION_SUCCESS';
export const ANSWERED_QUESTION_REQUEST = 'ANSWERED_QUESTION_REQUEST';
export const ANSWERED_QUESTION_FAIL = 'ANSWERED_QUESTION_FAIL';
export const FINISH_SURVEY_REQUEST = 'FINISH_SURVEY_REQUEST';
export const FINISH_SURVEY_SUCCESS = 'FINISH_SURVEY_SUCCESS';
export const FINISH_SURVEY_FAIL = 'FINISH_SURVEY_FAIL';
export const FINISH_SURVEY_CONTINUE = 'FINISH_SURVEY_CONTINUE';
export const GET_LAST_SURVEY_RESULT_REQUEST = 'GET_LAST_SURVEY_RESULT_REQUEST';
export const GET_LAST_SURVEY_RESULT_SUCCESS = 'GET_LAST_SURVEY_RESULT_SUCCESS';
export const GET_LAST_SURVEY_RESULT_FAIL = 'GET_LAST_SURVEY_RESULT_FAIL';
export const CLEAR_SURVEY = 'CLEAR_SURVEY';

export type AnsweredQuestionPayload = {
  surveyResultId: number;
  questionId: number;
  silence?: boolean;
};

export type ConfigData = {
  surveyStatus: string;
  currentSurvey?: { id: number; nextQuestionOrder: number; surveyResultId: number; status: string };
};

export type Config = {
  data: ConfigData;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type QuestionOption = {
  id: number;
  textAr: string;
  textEn: string;
};

export type AnswerOption = QuestionOption;

export type AnswerQuestionPayload = {
  surveyResultId?: number;
  questionId: number;
  answer: string | number | number[];
  silence?: boolean;
};

export type QuestionPayload = {
  surveyResultId?: number;
  surveyId: number;
  order: number;
  silence?: boolean;
};

export type AnsweredQuestion = {
  surveyResultId?: number;
  questionId: number;
  answer: number | string;
};

export type BaseQuestionData = {
  id: number;
  textAr: string;
  textEn: string;
  answerPatternAr: string;
  answerPatternEn: string;
  order: number;
  options: QuestionOption[];
};

export type QuestionNumericData = BaseQuestionData & {
  answerPatternAr: string;
  answerPatternEn: string;
  type: 'numeric';
};

export type QuestionSingleData = BaseQuestionData & {
  type: 'single';
};

export type QuestionMultipleData = BaseQuestionData & {
  type: 'multiple';
};

export type QuestionData = QuestionNumericData | QuestionSingleData | QuestionMultipleData;

export type AnswerData = AnswerQuestionPayload;

export type SurveyData = {
  surveyId: number;
  totalQuestions: number;
  totalSections: number;
};

export type FinishSurveyPayload = {
  surveyResultId: number;
  surveyId: number;
};

export type UserCategory = {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type SurveyResultData = {
  nextQuestionOrder?: number;
  surveyId: number;
  category: UserCategory;
  score: string;
  createdAt?: Date;
};

export type Answers = {
  data: { [prop: number]: AnswerData };
  isLoading: boolean;
  isErrorExist: boolean;
};

export type Questions = {
  data: QuestionData[] | [];
  isLoading: boolean;
  isErrorExist: boolean;
};

export type Survey = {
  data: SurveyData;
  isLoading: boolean;
  isErrorExist: boolean;
  result: SurveyResultData;
};

export interface RequestConfig {
  type: typeof CONFIG_REQUEST;
}

export interface RequestConfigSuccess {
  type: typeof CONFIG_SUCCESS;
  payload: ConfigData;
}

export interface RequestConfigFail {
  type: typeof CONFIG_FAIL;
}

export interface RequestAnsweredQuestion {
  type: typeof ANSWERED_QUESTION_REQUEST;
  payload: boolean;
}

export interface RequestAnsweredQuestionSuccess {
  type: typeof ANSWERED_QUESTION_SUCCESS;
  payload: AnsweredQuestion;
}

export interface RequestAnsweredQuestionFail {
  type: typeof ANSWERED_QUESTION_FAIL;
}

export interface RequestStartSurvey {
  type: typeof START_SURVEY_REQUEST;
}

export interface RequestStartSurveySuccess {
  type: typeof START_SURVEY_SUCCESS;
  payload: SurveyData;
}

export interface RequestStartSurveyFail {
  type: typeof START_SURVEY_FAIL;
}

export interface RequestCurrentQuestion {
  type: typeof CURRENT_QUESTION_REQUEST;
  payload: boolean;
}

export interface RequestCurrentQuestionSuccess {
  type: typeof CURRENT_QUESTION_SUCCESS;
  payload: QuestionData;
}

export interface RequestCurrentQuestionFail {
  type: typeof CURRENT_QUESTION_FAIL;
}

export interface RequestAnswerQuestionSuccess {
  type: typeof ANSWER_QUESTION_SUCCESS;
  payload: AnswerQuestionPayload;
}

export interface RequestAnswerQuestion {
  type: typeof ANSWER_QUESTION_REQUEST;
  payload: AnswerQuestionPayload;
}

export interface RequestAnswerQuestionFail {
  type: typeof ANSWER_QUESTION_FAIL;
}

export interface RequestFinishSurvey {
  type: typeof FINISH_SURVEY_REQUEST;
}

export interface RequestFinishSurveySuccess {
  type: typeof FINISH_SURVEY_SUCCESS;
  payload: SurveyResultData;
}

export interface RequestFinishSurveyFail {
  type: typeof FINISH_SURVEY_FAIL;
}

export interface RequestFinishSurveyContinue {
  type: typeof FINISH_SURVEY_CONTINUE;
}

export interface RequestGetLastSurveyResult {
  type: typeof GET_LAST_SURVEY_RESULT_REQUEST;
}

export interface RequestGetLastSurveyResultSuccess {
  type: typeof GET_LAST_SURVEY_RESULT_SUCCESS;
  payload: SurveyResultData;
}

export interface RequestGetLastSurveyResultFail {
  type: typeof GET_LAST_SURVEY_RESULT_FAIL;
}

export interface ClearSurvey {
  type: typeof CLEAR_SURVEY;
  payload: boolean;
}

export type SurveyAction =
  | RequestStartSurvey
  | RequestStartSurveySuccess
  | RequestStartSurveyFail
  | RequestCurrentQuestion
  | RequestCurrentQuestionSuccess
  | RequestCurrentQuestionFail
  | RequestAnswerQuestion
  | RequestAnswerQuestionSuccess
  | RequestAnswerQuestionFail
  | RequestConfig
  | RequestConfigSuccess
  | RequestConfigFail
  | RequestAnsweredQuestion
  | RequestAnsweredQuestionSuccess
  | RequestAnsweredQuestionFail
  | RequestFinishSurvey
  | RequestFinishSurveyFail
  | RequestFinishSurveySuccess
  | RequestFinishSurveyContinue
  | RequestGetLastSurveyResult
  | RequestGetLastSurveyResultSuccess
  | RequestGetLastSurveyResultFail
  | Logout
  | ClearSurvey;

export type SurveyState = Readonly<{
  questions: Questions;
  answers: Answers;
  survey: Survey;
  config: Config;
}>;
