import React from 'react';
import DashboardView from '../../components/User/Dashboard/DashboardView';
import BetaDashboardView from '../../components/User/BetaDashboard/BetaDashboardView';

interface DashboardProps {
  beta?: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ beta }) => (beta ? <BetaDashboardView /> : <DashboardView />);

export default Dashboard;
