import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserPreferenceResponse } from '../../../types/userPreference';
import { cleanUserPreferences, fetchUserPreferenceByType, saveUserPreference } from './userPreference.actions';
import { UserPreferenceState } from './userPreference.types';

const initialState: UserPreferenceState = {
  userPreferences: {},
  fetchStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  error: null,
};

const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserPreferenceByType.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserPreferenceByType.fulfilled,
      (state, action: PayloadAction<FetchUserPreferenceResponse>) => {
        if (action.payload?.data?.id) {
          state.userPreferences[action.payload.data.preferenceType.type] = action.payload.data.preferenceValue;
        }
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserPreferenceByType.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(saveUserPreference.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(saveUserPreference.fulfilled, (state) => {
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(saveUserPreference.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanUserPreferences.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userPreferenceReducer = userPreferencesSlice.reducer;
