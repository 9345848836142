import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import COLORS from '../../../constants/colors';
import FundAmountField from './FundAmountField';
import RequestInvestSuccessfulScreen from './RequestInvestSuccessfulScreen';
import MButton from '../../../shared/MButton';
import { madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import { formatValue } from '../../../helpers/string';
import { ar2En, formatNumber, round2Format } from '../../../utils/number';
import Deposit, { DepositProps } from '../MyWallet/Deposit';
import { useTypedDispatch } from '../../../redux/store';
import { clearWallet, fetchAnbMasterardConfigAction } from '../../../store_deprecated/actions/walletActions';

interface SubscribePortfolioDialogProps {
  open: boolean;
  isLoading: boolean;
  showSuccessfulScreen?: boolean;
  error: string;
  closeDialog?: () => void;
  onSuccessButtonClick?: () => void;
  onClose: (fundAmount?: number) => void;
}

const minSubscription = 1000;

export default function SubscribePortfolioDialog(props: SubscribePortfolioDialogProps) {
  const {
    open,
    isLoading,
    error,
    onClose,
    showSuccessfulScreen = false,
    closeDialog = () => {},
    onSuccessButtonClick = () => {},
  } = props;
  const dispatch = useTypedDispatch();
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const [fundAmount, setFundAmount] = useState('');
  const isFundAmountError = Boolean(fundAmount) && +fundAmount < minSubscription;
  const isArabic = i18n.language === 'ar';

  const bankItem = useSelector(madkhoulBankInfoSelector);
  const [depositConfig, setDepositConfig] = useState<DepositProps | null>(null);

  useEffect(() => () => setFundAmount(''), [open]);

  const handleClose = (selectedFundAmount?: number) => {
    if (isLoading) {
      return;
    }
    setFundAmount('');
    return !showSuccessfulScreen ? onClose(selectedFundAmount) : closeDialog();
  };
  const parseValue = (v: string | number) =>
    // eslint-disable-next-line no-useless-escape
    `${v}`
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const initialValue = event.target.value;

    const newValue: string = parseValue(ar2En(initialValue));
    const newValueNumber = +newValue;

    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;

      setFundAmount(event.target.value);

      return;
    }

    event.target.value = `${newValueNumber}`;

    setFundAmount(event.target.value);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const anbMastercardConfig = await dispatch(fetchAnbMasterardConfigAction());

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
            // openCongratulationModal,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();

    return () => {
      dispatch(clearWallet());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px">
      <Grid item textAlign="right" alignSelf="end">
        <IconButton onClick={() => handleClose()}>
          <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          paddingTop: { xs: '24px !important', md: '48px !important' },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '24px', md: '40px' },
            lineHeight: { xs: '32px', md: '48px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('invest.subscribePortfolioDialog.title')}
        </Typography>
      </Grid>
      {/* <Grid
            item
            textAlign="center"
            sx={{
              paddingTop: { xs: "24px !important", md: "48px !important" },
            }}
          >
            <Typography
              variant="bodyLarge"
              sx={{
                fontWeight: 400,
                fontSize: { xs: "16px", md: "18px" },
                lineHeight: "28px",
                color: COLORS.DARK_GREY,
              }}
            >
              {t("invest.subscribePortfolioDialog.description")}
            </Typography>
          </Grid> */}
      <Grid item container flexDirection="row" justifyContent="space-around" alignItems="center">
        <Typography
          variant="bodyMedium"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('invest.subscribePortfolioDialog.currentFund')}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '28px',
            color: COLORS.PRIMARY_BLUE,
          }}
        >
          {t('invest.subscribePortfolioDialog.nPerMonth', {
            val: round2Format(Number(bankItem?.userBankDetails?.amount) || 0, i18n.language),
          })}
        </Typography>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <FundAmountField
          isArabic={isArabic}
          value={formatValue(isArabic, (fundAmount || '').toString()).replace(/٬/g, ',')}
          handleChange={handleFieldChange}
        />
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('invest.subscribePortfolioDialog.note', { val: '1,000' })}
        </Typography>
      </Grid>
      {(isLoading || !!error || isFundAmountError) && (
        <Grid item>
          {isLoading && <CircularProgress />}
          {(!!error || isFundAmountError) && (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.RED,
              }}
            >
              {error ||
                tApiError('FUND_AMOUNT_LESS_THAN_MINIMUM_SUBSCRIPTION_AMOUNT', {
                  val: formatNumber(minSubscription, i18n.language, 2),
                })}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item container rowSpacing="24px">
        <Grid item width="100%">
          <MButton
            id="ConfirmInvestButton"
            variant="contained"
            onClick={() => handleClose(+fundAmount.replace(/\D/g, ''))}
            disabled={!fundAmount || isFundAmountError || isLoading}
            fullWidth
            buttonProps={{
              sx: {
                backgroundColor: COLORS.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                padding: { xs: '16px', md: '16px 48px' },
              },
            }}
          >
            {t('invest.subscribePortfolioDialog.confirmButtonTitle')}
          </MButton>
        </Grid>
        {depositConfig ? (
          <Grid item width="100%" sx={{ mb: { xs: '0', md: 'unset' } }}>
            <Deposit {...depositConfig} page="dashboard" paddingTop={0} />
          </Grid>
        ) : null}
        <Grid item width="100%">
          <MButton
            variant="outlined"
            onClick={() => handleClose()}
            disabled={isLoading}
            fullWidth
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
              },
            }}
          >
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Box sx={{ padding: '32px 24px !important' }}>
        {!showSuccessfulScreen ? (
          renderContent()
        ) : (
          <RequestInvestSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />
        )}
      </Box>
    </Dialog>
  );
}
