import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import COLORS from '../constants/colors';

type SelectValue = {
  label: string;
  value: string;
};

interface SelectInputProps {
  label: string;
  selectPlaceholder: string;
  value: string;
  values: SelectValue[];
  name: string;
  error?: boolean;
  helperText?: string | false;
  id?: string;
  handleChange: (param: any) => void;
  required?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    label,
    selectPlaceholder,
    value,
    values,
    error = false,
    helperText,
    name = 'demo-simple-select',
    id = 'demo-simple-select',
    required = false,
    handleChange,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="bodyLargeM"
        color={COLORS.MAIN_DARK}
        textAlign="justify"
        paragraph
        sx={{ marginBottom: 1, fontSize: { xs: '0.875rem', md: '1.125rem' } }}
      >
        {t(label)}
      </Typography>
      <FormControl fullWidth required={required}>
        {value === '' && (
          <InputLabel shrink={false} id="demo-simple-select-label">
            {t(selectPlaceholder)}
          </InputLabel>
        )}
        <Select labelId="demo-simple-select-label" id={id} name={name} value={value} onChange={handleChange}>
          {values.map((val) => (
            <MenuItem value={val.value} key={val.label}>
              {val.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText error={error} sx={{ margin: '3px 14px 0 14px' }}>
        {helperText}
      </FormHelperText>
    </>
  );
};

export default SelectInput;
