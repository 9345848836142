import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import COLORS from '../constants/colors';
import MButton from './MButton';

export interface ResetModalProps {
  open: boolean;
  type: string;
  handleClose: (e: any) => void;
  onDoneClick: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    backgroundColor: 'transparent !important',
  },
  '.MuiDialog-paper': {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    backgroundColor: 'transparent !important',
  },
}));

const ResetModal: React.FC<ResetModalProps> = ({ open, type, handleClose, onDoneClick }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  return (
    <BootstrapDialog
      onClose={handleClose}
      open={open}
      maxWidth="xl"
      sx={{
        backgroundColor: 'transparent',
        padding: '0 !important',
      }}
    >
      <DialogContent sx={{ padding: 0, marginTop: 8 }}>
        <Box p={25} sx={{ background: COLORS.WHITE, borderRadius: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
            }}
          >
            {type === 'success' ? (
              <img src="/images/icons/mail-sent.svg" alt="mail sent" />
            ) : (
              <img src="/images/icons/failed.svg" alt="mail sent" />
            )}
          </Box>
          <Typography
            variant={isEnglish ? 'h6' : 'cairoButtonR'}
            color={type === 'success' ? COLORS.DARK_GREY : COLORS.RED}
            paragraph
            textAlign="center"
            sx={{ marginTop: 8, marginBottom: 8 }}
          >
            {type === 'success' ? t('userProfile.passwordSuccess') : t('userProfile.passwordFailed')};
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            maxWidth: { xs: '100%', md: '650px' },
            margin: 'auto',
            my: 4,
            mx: { xs: '24px', md: 'auto' },
            bgcolor: COLORS.X_DARK_BLUE,
            borderRadius: 10,
          }}
        >
          <MButton
            id="loginbutton"
            variant="text"
            onClick={onDoneClick}
            fullWidth
            buttonProps={{
              sx: {
                height: { xs: '50px', md: 60 },
                color: COLORS.WHITE,
              },
            }}
          >
            {type === 'success' ? t('userProfile.okay') : t('userProfile.tryAgain')}
          </MButton>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ResetModal;
