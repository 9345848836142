import { Selector } from 'react-redux';
import { GlobalState } from '../types';
import {
  AddMoreFundToPortfolio,
  AvailablePortfolios,
  CancelSubscription,
  PortfolioStatistics,
  RecommendedPortfolio,
  Redemption,
  SubscribedPortfolio,
  SubscribeToPortfolio,
  SubscriptionsHistory,
} from '../types/portfolioTypes';

export const getRecommendedPortfolioSelector: Selector<GlobalState, RecommendedPortfolio> = (state) =>
  state.portfolioState.recommendedPortfolio;

export const getRecommendedPortfolioIsLoadingSelector: Selector<GlobalState, boolean> = (state) =>
  state.portfolioState.recommendedPortfolio.isLoading;

export const getSubscribedPortfolioSelector: Selector<GlobalState, SubscribedPortfolio> = (state) =>
  state.portfolioState.subscribedPortfolio;

export const getPortfolioStatisticsSelector: Selector<GlobalState, PortfolioStatistics> = (state) =>
  state.portfolioState.statistics;

export const getSubscribedPortfolioIsLoadingSelector: Selector<GlobalState, boolean> = (state) =>
  state.portfolioState.subscribedPortfolio.isLoading;

export const getSubscribeToPortfolioSelector: Selector<GlobalState, SubscribeToPortfolio> = (state) =>
  state.portfolioState.subscribeToPortfolio;

export const getAddMoreFundToPortfolioSelector: Selector<GlobalState, AddMoreFundToPortfolio> = (state) =>
  state.portfolioState.addMoreFundToPortfolio;

export const getSubscriptionsHistorySelector: Selector<GlobalState, SubscriptionsHistory> = (state) =>
  state.portfolioState.subscriptionsHistory;

export const getRedemptionSelector: Selector<GlobalState, Redemption> = (state) => state.portfolioState.redemption;

export const getCancelSubscriptionSelector: Selector<GlobalState, CancelSubscription> = (state) =>
  state.portfolioState.cancelSubscription;

export const getAvailablePortfoliosSelector: Selector<GlobalState, AvailablePortfolios> = (state) =>
  state.portfolioState.availablePortfolios;
