import { Logout } from './authTypes';

export const BANK_INFO_SUCCESS = 'BANK_INFO_SUCCESS';
export const BANK_INFO_REQUEST = 'BANK_INFO_REQUEST';
export const BANK_INFO_FAIL = 'BANK_INFO_FAIL';
export const BANKS_REQUEST = 'BANKS_REQUEST';
export const BANKS_SUCCESS = 'BANKS_SUCCESS';
export const BANKS_FAIL = 'BANKS_FAIL';
export const SAVE_BANK_INFO_FAIL = 'SAVE_BANK_INFO_FAIL';
export const SAVE_BANK_INFO_SUCCESS = 'SAVE_BANK_INFO_SUCCESS';
export const SAVE_BANK_INFO_REQUEST = 'SAVE_BANK_INFO_REQUEST';
export const DELETE_BANK_INFO_FAIL = 'DELETE_BANK_INFO_FAIL';
export const DELETE_BANK_INFO_SUCCESS = 'DELETE_BANK_INFO_SUCCESS';
export const DELETE_BANK_INFO_REQUEST = 'DELETE_BANK_INFO_REQUEST';
export const EDIT_BANK_INFO_REQUEST = 'EDIT_BANK_INFO_REQUEST';
export const EDIT_BANK_INFO_SUCCESS = 'EDIT_BANK_INFO_SUCCESS';
export const EDIT_BANK_INFO_FAIL = 'EDIT_BANK_INFO_FAIL';
export const DO_WITHDRAW_REQUEST = 'DO_WITHDRAW_REQUEST';
export const DO_WITHDRAW_SUCCESS = 'DO_WITHDRAW_SUCCESS';
export const DO_WITHDRAW_FAIL = 'DO_WITHDRAW_FAIL';
export const CREATE_MASTERCARD_SESSION_REQUEST = 'CREATE_MASTERCARD_SESSION_REQUEST';
export const CREATE_MASTERCARD_SESSION_SUCCESS = 'CREATE_MASTERCARD_SESSION_SUCCESS';
export const CREATE_MASTERCARD_SESSION_FAIL = 'CREATE_MASTERCARD_SESSION_FAIL';
export const CREATE_HYPER_PAY_CHECKOUT_REQUEST = 'CREATE_HYPER_PAY_CHECKOUT_REQUEST';
export const CREATE_HYPER_PAY_CHECKOUT_SUCCESS = 'CREATE_HYPER_PAY_CHECKOUT_SUCCESS';
export const CREATE_HYPER_PAY_CHECKOUT_FAIL = 'CREATE_HYPER_PAY_CHECKOUT_FAIL';
export const QUERY_HYPER_PAY_PAYMENT_STATUS_REQUEST = 'QUERY_HYPER_PAY_PAYMENT_STATUS_REQUEST';
export const QUERY_HYPER_PAY_PAYMENT_STATUS_SUCCESS = 'QUERY_HYPER_PAY_PAYMENT_STATUS_SUCCESS';
export const QUERY_HYPER_PAY_PAYMENT_STATUS_FAIL = 'QUERY_HYPER_PAY_PAYMENT_STATUS_FAIL';
export const QUERY_HYPER_PAY_REGISTRATION_STATUS_REQUEST = 'QUERY_HYPER_PAY_REGISTRATION_STATUS_REQUEST';
export const QUERY_HYPER_PAY_REGISTRATION_STATUS_SUCCESS = 'QUERY_HYPER_PAY_REGISTRATION_STATUS_SUCCESS';
export const QUERY_HYPER_PAY_REGISTRATION_STATUS_FAIL = 'QUERY_HYPER_PAY_REGISTRATION_STATUS_FAIL';
export const SAVE_SUBSCRIPTION_FAIL = 'SAVE_SUBSCRIPTION_FAIL';
export const SAVE_SUBSCRIPTION_SUCCESS = 'SAVE_SUBSCRIPTION_SUCCESS';
export const SAVE_SUBSCRIPTION_REQUEST = 'SAVE_SUBSCRIPTION_REQUEST';
export const SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_SUCCESS';
export const SUBSCRIPTIONS_REQUEST = 'SUBSCRIPTIONS_REQUEST';
export const SUBSCRIPTIONS_FAIL = 'SUBSCRIPTIONS_FAIL';
export const SET_PRIMARY_SUBSCRIPTION_CARD_REQUEST = 'SET_PRIMARY_SUBSCRIPTION_CARD_REQUEST';
export const SET_PRIMARY_SUBSCRIPTION_CARD_SUCCESS = 'SET_PRIMARY_SUBSCRIPTION_CARD_SUCCESS';
export const SET_PRIMARY_SUBSCRIPTION_CARD_FAIL = 'SET_PRIMARY_SUBSCRIPTION_CARD_FAIL';
export const DELETE_SUBSCRIPTION_REQUEST = 'DELETE_SUBSCRIPTION_REQUEST';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_FAIL = 'DELETE_SUBSCRIPTION_FAIL';
export const CREATED_SUBSCRIPTION_SUCCESS = 'CREATED_SUBSCRIPTION_SUCCESS';
export const CREATED_SUBSCRIPTION_REQUEST = 'CREATED_SUBSCRIPTION_REQUEST';
export const CREATED_SUBSCRIPTION_FAIL = 'CREATED_SUBSCRIPTION_FAIL';
export const CREATE_APPLE_PAY_PAYMENT_REQUEST = 'CREATE_APPLE_PAY_PAYMENT_REQUEST';
export const CREATE_APPLE_PAY_PAYMENT_SUCCESS = 'CREATE_APPLE_PAY_PAYMENT_SUCCESS';
export const CREATE_APPLE_PAY_PAYMENT_FAIL = 'CREATE_APPLE_PAY_PAYMENT_FAIL';
export const CREATE_APPLE_PAY_SESSION_REQUEST = 'CREATE_APPLE_PAY_SESSION_REQUEST';
export const CREATE_APPLE_PAY_SESSION_SUCCESS = 'CREATE_APPLE_PAY_SESSION_SUCCESS';
export const CREATE_APPLE_PAY_SESSION_FAIL = 'CREATE_APPLE_PAY_SESSION_FAIL';
export const FETCH_ANB_MASTERCARD_CONFIG_REQUEST = 'FETCH_ANB_MASTERCARD_CONFIG_REQUEST';
export const FETCH_ANB_MASTERCARD_CONFIG_SUCCESS = 'FETCH_ANB_MASTERCARD_CONFIG_SUCCESS';
export const FETCH_ANB_MASTERCARD_CONFIG_FAIL = 'FETCH_ANB_MASTERCARD_CONFIG_FAIL';
export const GET_NET_WITHDRAW_REQUEST = 'GET_NET_WITHDRAW_REQUEST';
export const GET_NET_WITHDRAW_SUCCESS = 'GET_NET_WITHDRAW_SUCCESS';
export const GET_NET_WITHDRAW_FAIL = 'GET_NET_WITHDRAW_FAIL';
export const SET_PRIMARY_BANK_ACCOUNT_REQUEST = 'SET_PRIMARY_BANK_ACCOUNT_REQUEST';
export const SET_PRIMARY_BANK_ACCOUNT_SUCCESS = 'SET_PRIMARY_BANK_ACCOUNT_SUCCESS';
export const SET_PRIMARY_BANK_ACCOUNT_FAIL = 'SET_PRIMARY_BANK_ACCOUNT_FAIL';
export const GET_STATEMENT_DELTA_REQUEST = 'GET_STATEMENT_DELTA_REQUEST';
export const GET_STATEMENT_DELTA_SUCCESS = 'GET_STATEMENT_DELTA_SUCCESS';
export const GET_STATEMENT_DELTA_FAIL = 'GET_STATEMENT_DELTA_FAIL';
export const CLEAR_EDIT_BANK_INFO = 'CLEAR_EDIT_BANK_INFO';
export const CLEAR_WALLET = 'CLEAR_WALLET';

export enum BankType {
  VIRTUAL = 'VIRTUAL',
  OTHER = 'OTHER',
}

export type BankItemBankType = {
  id: number;
  description: string;
  type: BankType;
};

export type BankItemUserBankDetails = {
  id: number;
  isLocal: boolean;
  accountNumber: string;
  amount: number;
  hold: number;
  cashAllocationValue: number;
  beneficiaryAddress1: string;
  beneficiaryAddress2: string;
  beneficiaryName: string;
  beneficiaryBankName: string;
  iban: string;
  swiftCode: string;
};

export type BankItem = {
  id: number;
  bankType: BankItemBankType;
  iban: string;
  isPrimaryAccount: boolean;
  swiftCode: string;
  userBankDetails: BankItemUserBankDetails;
  user: any;
};

export type BankInfo = {
  data: BankItem[];
  isLoading: boolean;
  isErrorExist: boolean;
};

export type EditBankInfo = {
  data: BankItem | null;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type WithdrawPayload = {
  amount: number;
};

export type WithdrawInfo = {
  netWithdraw: number;
  isLoading: boolean;
  isErrorExist: boolean;
  error: string;
};

export type StatementInfo = {
  statementDelta: number;
  isLoading: boolean;
  isErrorExist: boolean;
  error: string;
};

export type DepositInfo = {
  isLoading: boolean;
  isErrorExist: boolean;
  error: string;
};

export type AnbMastercardConfigInfo = {
  isLoading: boolean;
  isErrorExist: boolean;
  error: string;
};

export type CreateMastercardSessionPayload = {
  currency: 'USD' | 'SAR';
  amount: number;
  returnUrl?: string;
  orderId?: string;
  paymentToken?: any;
};

export type CreateApplePaySessionPayload = {
  merchantIdentifier: string;
  displayName: string;
  initiative: string;
  initiativeContext: string;
  validationURL: string;
};

export type CreateHyperPayCheckoutPayload = {
  currency: 'SAR';
  amount: number;
  paymentType: 'DB' | 'PA' | 'RG';
  brand: string;
};

export type QueryHyperPayPaymentStatusPayload = {
  resourcePath: string;
  paymentType: 'DB' | 'PA' | 'RG';
  brand: string;
  subscriptionId: number;
};

export type QueryHyperPayRegistrationStatusPayload = {
  resourcePath: string;
  paymentType: 'DB' | 'PA' | 'RG';
  brand: string;
};

export type SaveSubscriptionPayload = {
  id?: number;
  amount: number;
  startDate: Date | string;
  endDate: Date | string | null;
  frequencyUnit: string;
  frequencyCount?: number;
  endDateType: string;
  canceledAt?: Date | string;
  dueDate?: Date | string;
  registrationToken?: string;
  paymentBrand?: string;
  registrationPayload?: any;
  last4Digits?: string;
  isPrimaryCard?: boolean;
  merchantTransactionId?: string;
  status?: string;
};

export type SaveSubscriptionSuccessPayload = {
  description: string;
  id: number;
  statusCode: string;
};

export type SubscriptionItem = {
  id: number;
  amount: number;
  startDate: Date | string;
  endDate: Date | string | null;
  endDateType: string;
  frequencyUnit: string;
  frequencyCount: number;
  status: string;
  registrationToken: string;
  merchantTransactionId: string;
  paymentBrand: string;
  last4Digits: string;
  registrationPayload: any;
  isPrimaryCard: boolean;
  isDeleted: boolean;
  deletedAt: Date | string | null;
  canceledAt: Date | string | null;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type SubscriptionsState = {
  subscriptions: SubscriptionItem[];
  isLoading: boolean;
  isErrorExist: boolean;
};

export type CreatedSubscriptionState = {
  subscription: SubscriptionItem | null;
  isLoading: boolean;
  isErrorExist: boolean;
};

export enum WalletProvider {
  APPLE_PAY = 'APPLE_PAY',
}

export type CreateApplePayPaymentPayload = {
  currency: 'USD' | 'SAR';
  amount: number;
  paymentToken: any;
  walletProvider: WalletProvider;
};

export type Bank = {
  id: number;
  bankName: string;
  countryName: string;
  bankNameAr: string;
  countryNameAr: string;
  bic: string;
};

export type SaveBankInfoPayload = {
  id?: number;
  iban: string;
  swiftCode: string;
  isPrimaryAccount: boolean;
  bankType: {
    type: BankType;
  };
  userBankDetails: Omit<BankItemUserBankDetails, 'id'> & {
    id?: number;
  };
};

export type SaveBankInfoSuccessPayload = {
  description: string;
  id: number;
  statusCode: string;
};

export type BanksState = {
  banks: Bank[];
  isLoading: boolean;
  isErrorExist: boolean;
};

export interface RequestBankInfoFail {
  type: typeof BANK_INFO_FAIL;
}

export interface RequestBankInfoSuccess {
  type: typeof BANK_INFO_SUCCESS;
  payload: BankItem[];
}

export interface RequestBankInfo {
  type: typeof BANK_INFO_REQUEST;
}

export interface RequestBanksFail {
  type: typeof BANKS_FAIL;
}

export interface RequestBanksSuccess {
  type: typeof BANKS_SUCCESS;
  payload: Bank[];
}

export interface RequestBanks {
  type: typeof BANKS_REQUEST;
}

export interface RequestSaveBankInfoFail {
  type: typeof SAVE_BANK_INFO_FAIL;
}

export interface RequestSaveBankInfoSuccess {
  type: typeof SAVE_BANK_INFO_SUCCESS;
  payload: any;
}

export interface RequestSaveBankInfo {
  type: typeof SAVE_BANK_INFO_REQUEST;
}

export interface RequestDeleteBankInfoFail {
  type: typeof DELETE_BANK_INFO_FAIL;
}

export interface RequestDeleteBankInfoSuccess {
  type: typeof DELETE_BANK_INFO_SUCCESS;
}

export interface RequestDeleteBankInfo {
  type: typeof DELETE_BANK_INFO_REQUEST;
}

export interface RequestEditBankInfoFail {
  type: typeof EDIT_BANK_INFO_FAIL;
}

export interface RequestEditBankInfoSuccess {
  type: typeof EDIT_BANK_INFO_SUCCESS;
  payload: BankItem;
}

export interface RequestEditBankInfo {
  type: typeof EDIT_BANK_INFO_REQUEST;
}

export interface RequestWithdraw {
  type: typeof DO_WITHDRAW_REQUEST;
}

export interface RequestWithdrawSuccess {
  type: typeof DO_WITHDRAW_SUCCESS;
}

export interface RequestWithdrawFail {
  type: typeof DO_WITHDRAW_FAIL;
  payload: string;
}

export interface CreateMastercardSession {
  type: typeof CREATE_MASTERCARD_SESSION_REQUEST;
}

export interface CreateMastercardSessionSuccess {
  type: typeof CREATE_MASTERCARD_SESSION_SUCCESS;
}

export interface CreateMastercardSessionFail {
  type: typeof CREATE_MASTERCARD_SESSION_FAIL;
  payload: string;
}

export interface CreateHyperPayCheckout {
  type: typeof CREATE_HYPER_PAY_CHECKOUT_REQUEST;
}

export interface CreateHyperPayCheckoutSuccess {
  type: typeof CREATE_HYPER_PAY_CHECKOUT_SUCCESS;
}

export interface CreateHyperPayCheckoutFail {
  type: typeof CREATE_HYPER_PAY_CHECKOUT_FAIL;
  payload: string;
}

export interface QueryHyperPayPaymentStatus {
  type: typeof QUERY_HYPER_PAY_PAYMENT_STATUS_REQUEST;
}

export interface QueryHyperPayPaymentStatusSuccess {
  type: typeof QUERY_HYPER_PAY_PAYMENT_STATUS_SUCCESS;
}

export interface QueryHyperPayPaymentStatusFail {
  type: typeof QUERY_HYPER_PAY_PAYMENT_STATUS_FAIL;
  payload: string;
}

export interface QueryHyperPayRegistrationStatus {
  type: typeof QUERY_HYPER_PAY_REGISTRATION_STATUS_REQUEST;
}

export interface QueryHyperPayRegistrationStatusSuccess {
  type: typeof QUERY_HYPER_PAY_REGISTRATION_STATUS_SUCCESS;
}

export interface QueryHyperPayRegistrationStatusFail {
  type: typeof QUERY_HYPER_PAY_REGISTRATION_STATUS_FAIL;
  payload: string;
}

export interface RequestSaveSubscriptionFail {
  type: typeof SAVE_SUBSCRIPTION_FAIL;
}

export interface RequestSaveSubscriptionSuccess {
  type: typeof SAVE_SUBSCRIPTION_SUCCESS;
}

export interface RequestSaveSubscription {
  type: typeof SAVE_SUBSCRIPTION_REQUEST;
}

export interface RequestSubscriptionsFail {
  type: typeof SUBSCRIPTIONS_FAIL;
}

export interface RequestSubscriptionsSuccess {
  type: typeof SUBSCRIPTIONS_SUCCESS;
  payload: any;
}

export interface RequestSubscriptions {
  type: typeof SUBSCRIPTIONS_REQUEST;
}

export interface RequestSetPrimarySubscriptionCard {
  type: typeof SET_PRIMARY_SUBSCRIPTION_CARD_REQUEST;
}

export interface RequestSetPrimarySubscriptionCardFail {
  type: typeof SET_PRIMARY_SUBSCRIPTION_CARD_FAIL;
}

export interface RequestSetPrimarySubscriptionCardSuccess {
  type: typeof SET_PRIMARY_SUBSCRIPTION_CARD_SUCCESS;
}

export interface RequestDeleteSubscription {
  type: typeof DELETE_SUBSCRIPTION_REQUEST;
}

export interface RequestDeleteSubscriptionFail {
  type: typeof DELETE_SUBSCRIPTION_FAIL;
}

export interface RequestDeleteSubscriptionSuccess {
  type: typeof DELETE_SUBSCRIPTION_SUCCESS;
}

export interface RequestCreatedSubscription {
  type: typeof CREATED_SUBSCRIPTION_REQUEST;
}

export interface RequestCreatedSubscriptionFail {
  type: typeof CREATED_SUBSCRIPTION_FAIL;
}

export interface RequestCreatedSubscriptionSuccess {
  type: typeof CREATED_SUBSCRIPTION_SUCCESS;
  payload: any;
}

export interface CreateApplePayPayment {
  type: typeof CREATE_APPLE_PAY_PAYMENT_REQUEST;
}

export interface CreateApplePayPaymentSuccess {
  type: typeof CREATE_APPLE_PAY_PAYMENT_SUCCESS;
}

export interface CreateApplePayPaymentFail {
  type: typeof CREATE_APPLE_PAY_PAYMENT_FAIL;
  payload: string;
}

export interface CreateApplePaySession {
  type: typeof CREATE_APPLE_PAY_SESSION_REQUEST;
}

export interface CreateApplePaySessionSuccess {
  type: typeof CREATE_APPLE_PAY_SESSION_SUCCESS;
}

export interface CreateApplePaySessionFail {
  type: typeof CREATE_APPLE_PAY_SESSION_FAIL;
  payload: string;
}

export interface FetchAnbMastercardConfig {
  type: typeof FETCH_ANB_MASTERCARD_CONFIG_REQUEST;
}

export interface FetchAnbMastercardConfigSuccess {
  type: typeof FETCH_ANB_MASTERCARD_CONFIG_SUCCESS;
}

export interface FetchAnbMastercardConfigFail {
  type: typeof FETCH_ANB_MASTERCARD_CONFIG_FAIL;
  payload: string;
}

export interface RequestGetNetWithdraw {
  type: typeof GET_NET_WITHDRAW_REQUEST;
}

export interface RequestGetNetWithdrawSuccess {
  type: typeof GET_NET_WITHDRAW_SUCCESS;
  payload: number;
}

export interface RequestGetNetWithdrawFail {
  type: typeof GET_NET_WITHDRAW_FAIL;
  payload: string;
}

export interface RequestSetPrimaryBankAccount {
  type: typeof SET_PRIMARY_BANK_ACCOUNT_REQUEST;
}

export interface RequestSetPrimaryBankAccountFail {
  type: typeof SET_PRIMARY_BANK_ACCOUNT_FAIL;
}

export interface RequestSetPrimaryBankAccountSuccess {
  type: typeof SET_PRIMARY_BANK_ACCOUNT_SUCCESS;
}

export interface RequestGetStatementDelta {
  type: typeof GET_STATEMENT_DELTA_REQUEST;
}

export interface RequestGetStatementDeltaSuccess {
  type: typeof GET_STATEMENT_DELTA_SUCCESS;
  payload: number;
}

export interface RequestGetStatementDeltaFail {
  type: typeof GET_STATEMENT_DELTA_FAIL;
  payload: string;
}

export interface ClearWallet {
  type: typeof CLEAR_WALLET;
}

export interface ClearEditBankInfo {
  type: typeof CLEAR_EDIT_BANK_INFO;
}

export type WalletAction =
  | RequestBankInfoFail
  | RequestBankInfoSuccess
  | RequestBankInfo
  | RequestBanksFail
  | RequestBanksSuccess
  | RequestBanks
  | RequestSaveBankInfoFail
  | RequestSaveBankInfoSuccess
  | RequestSaveBankInfo
  | RequestDeleteBankInfoFail
  | RequestDeleteBankInfoSuccess
  | RequestDeleteBankInfo
  | RequestSetPrimaryBankAccountFail
  | RequestSetPrimaryBankAccountSuccess
  | RequestSetPrimaryBankAccount
  | RequestEditBankInfoFail
  | RequestEditBankInfoSuccess
  | RequestEditBankInfo
  | RequestWithdraw
  | RequestWithdrawSuccess
  | RequestWithdrawFail
  | FetchAnbMastercardConfig
  | FetchAnbMastercardConfigSuccess
  | FetchAnbMastercardConfigFail
  | CreateMastercardSession
  | CreateMastercardSessionSuccess
  | CreateMastercardSessionFail
  | CreateHyperPayCheckout
  | CreateHyperPayCheckoutSuccess
  | CreateHyperPayCheckoutFail
  | QueryHyperPayPaymentStatus
  | QueryHyperPayPaymentStatusSuccess
  | QueryHyperPayPaymentStatusFail
  | QueryHyperPayRegistrationStatus
  | QueryHyperPayRegistrationStatusSuccess
  | QueryHyperPayRegistrationStatusFail
  | RequestSubscriptions
  | RequestSubscriptionsSuccess
  | RequestSubscriptionsFail
  | RequestSetPrimarySubscriptionCardFail
  | RequestSetPrimarySubscriptionCardSuccess
  | RequestSetPrimarySubscriptionCard
  | RequestDeleteSubscriptionFail
  | RequestDeleteSubscriptionSuccess
  | RequestDeleteSubscription
  | RequestCreatedSubscriptionFail
  | RequestCreatedSubscriptionSuccess
  | RequestCreatedSubscription
  | CreateApplePaySession
  | CreateApplePaySessionSuccess
  | CreateApplePaySessionFail
  | RequestGetNetWithdraw
  | RequestGetNetWithdrawSuccess
  | RequestGetNetWithdrawFail
  | RequestGetStatementDelta
  | RequestGetStatementDeltaSuccess
  | RequestGetStatementDeltaFail
  | ClearEditBankInfo
  | Logout
  | ClearWallet;

export type WalletState = Readonly<{
  bankInfo: BankInfo;
  editBankInfo: EditBankInfo;
  withdrawInfo: WithdrawInfo;
  statementInfo: StatementInfo;
  depositInfo: DepositInfo;
  anbMastercardConfigInfo: AnbMastercardConfigInfo;
  banks: BanksState;
  subscriptions: SubscriptionsState;
  createdSubscription: CreatedSubscriptionState;
}>;
