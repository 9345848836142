import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useCheckUserAuthQuery } from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useTypedDispatch } from '../../../redux/store';
import SurveyResultsSection from './SurveyResultsSection';
import ChangePasswordSection from './ChangePasswordSection';
import AttachmentsSection from './AttachmentsSection';
import ProfileDataSection, { ProfileDataSectionRef } from './ProfileDataSection';
import Breadcrumb from '../../../shared/Breadcrumb';
import { locations } from '../../../routes/locations';
import COLORS from '../../../constants/colors';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import MButton from '../../../shared/MButton';
import { fetchAttachedDocuments } from '../../../store_deprecated/actions/docsActions';

const UserProfileView = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const profileDataRef = useRef<ProfileDataSectionRef>(null);
  const { user } = useSelector(authSelector);
  const [editMode, setEditMode] = useState(false);

  const { refetch: refetchCheckAuth } = useCheckUserAuthQuery();

  useEffect(() => {
    if (user?.id) {
      dispatch(findProfileData());
      dispatch(fetchAttachedDocuments());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const onSaveProfile = async () => {
    if (profileDataRef.current && profileDataRef.current?.isValid()) {
      const res = await profileDataRef.current.submit();
      if (typeof res === 'object') {
        setEditMode(false);
        await refetchCheckAuth().unwrap();
        await dispatch(findProfileData());
      }
    }
  };

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      onClick={() => navigate(locations.dashboard())}
      sx={{ color: COLORS.LIGHT_GRAY, cursor: 'pointer' }}
    >
      {t('breadcrumb.dashboard')}
    </Link>,
    <Typography key="2" color="text.primary">
      {t('breadcrumb.myProfile')}
    </Typography>,
  ];

  return (
    <Container component="main" sx={{ padding: '32px!important' }}>
      <Grid item sx={{ mb: '32px' }}>
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </Grid>
      <Grid container display="flex" flexDirection="column">
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: '20px' }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '24px',
              lineHeight: '32px',
              color: COLORS.MAIN_DARK,
            }}
          >
            {t('userProfile.title')}
          </Typography>
          <Grid item>
            {editMode ? (
              <MButton
                variant="contained"
                onClick={onSaveProfile}
                id="SaveProfileButton"
                buttonProps={{ sx: { p: '10px 24px', boxShadow: 'none' } }}
              >
                {t('userProfile.saveButton')}
              </MButton>
            ) : (
              <MButton
                variant="outlined"
                onClick={() => setEditMode(true)}
                id="EditProfileButton"
                typographyProps={{ sx: { p: { xs: '8px', md: '8px 24px' } } }}
                buttonProps={{
                  sx: { mt: { xs: '8px', md: 'unset' }, boxShadow: 'none' },
                }}
              >
                <EditOutlinedIcon sx={{ mr: '8px' }} />
                {t('userProfile.editButton')}
              </MButton>
            )}
            {editMode && (
              <MButton
                variant="outlined"
                onClick={() => setEditMode(false)}
                id="CancelEditProfileButton"
                buttonProps={{
                  sx: {
                    ml: 6,
                    mt: { xs: '8px', md: 'unset' },
                    boxShadow: 'none',
                  },
                }}
                typographyProps={{ sx: { p: { xs: '8px', md: '8px 24px' } } }}
              >
                {t('userProfile.cancelButton')}
              </MButton>
            )}
          </Grid>
        </Grid>

        {/* <AvatarSection /> */}

        <ProfileDataSection ref={profileDataRef} editMode={editMode} />

        <SurveyResultsSection />

        <ChangePasswordSection />

        <AttachmentsSection />
      </Grid>
    </Container>
  );
};

export default UserProfileView;
